import { PoDialogService, PoNotificationService, PoI18nPipe } from '@po-ui/ng-components';
import { LiteralsService } from './../../../../../shared/services/literals-service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { AttachmentDocumentsService } from '../../services/attach-documents.service';
import { MingleService } from '@totvs/mingle';
import { Utils } from '../../../../../shared/helper/utils';
import { MenuService } from '../../services/menu.service';
import { LoginService } from '../../../../../shared/services/login.service';
import { Subscription, interval } from 'rxjs';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';
import { ExecutionHashService } from 'src/app/shared/services/execution-hash.service';

declare var totvstec: any;
@Component({
  selector: 'attach-documents',
  templateUrl: './attach-documents.component.html',
  styleUrls: ['./attach-documents.component.css'],
  providers:[PoI18nPipe]
})
export class AttachDocumentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input('c-attachments') contractAttachments: Array<any>;
  @Input('c-info') contractInfo;
  @Input('c-table') table: string;
  @Output('c-sucess') uploadSucess = new EventEmitter();

  literals;
  public filesToUpload: Array<any> = [];
  public hasFiles:  number;
  public labelsToUpload: Array<any> = [];
  public disableUpload: boolean = true;
  public disableDelete: boolean = false;
  public isHovering: boolean = false;
  public intervalDownload: Subscription

  constructor(
    public literalsService: LiteralsService,
    private attachDocumentsService: AttachmentDocumentsService,
    private poNotification: PoNotificationService,
    private poI18n: PoI18nPipe,
    private thfDialogService: PoDialogService,
    private mingleService: MingleService,
    private utils: Utils,
    private menuService: MenuService,
    private loginService: LoginService,
    private temporaryStorageService: TemporaryStorageService,
    private executionHashService: ExecutionHashService) {
    this.literals = this.literalsService.getLiterals();
  }

  ngOnInit() {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);
  }

  ngOnChanges() {
    if (this.contractAttachments) {
      this.contractAttachments.forEach(a => {
        this.labelsToUpload.push({
          protheusId: a.id,
          name: a.name,
          description: a.description,
          documentKey: a.documentkey,
          type: 'file',
          status: 'exist'
        });
      });
    }
  }


  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length > 0) {
      const input = event.target as HTMLInputElement;
      const files = Array.from(input?.files || []);
      const uniqueFiles: File[] = [];

      const fileIds: Set<string> = new Set();

      for (const file of files) {
        const fileId = `${file.name}_${file.size}`; // Cria um ID único para o arquivo

        if (!fileIds.has(fileId)) { // Verifica se o ID já existe
          uniqueFiles.push(file);
          fileIds.add(fileId); // Adiciona o ID do arquivo ao conjunto para evitar duplicatas
        }
      }

      // Limpa o valor do input de arquivo para que o evento onchange seja acionado na próxima seleção
      input.value = '';
  
      if (this.addFilesToLists(uniqueFiles)) {
        this.uploadFiles();
      }
    }
  }

  uploadFiles() {
    this.menuService.loading(true);
    this.disableUpload = true;
    const fileToUpload = this.filesToUpload[0];
    this.filesToUpload = this.filesToUpload.slice(1, this.filesToUpload.length);
    const onGoingId = this.labelsToUpload.findIndex( lblToUp => JSON.stringify(lblToUp) === JSON.stringify(fileToUpload));
    this.labelsToUpload[onGoingId].status = 'loading';
    this.labelsToUpload[onGoingId].labelStatus = this.literals?.str0190;

    if(this.table == undefined || this.table == ""){
      this.table = "DHU"
    }

    const reader = new FileReader();
    reader.onload = () => {
      const fileB64 = {
        name: fileToUpload.name,
        description: fileToUpload.name,
        content: reader.result.toString().split(',')[1],
        key: this.temporaryStorageService.getValue('quotationCode'),
        table: this.table
      };

      fileB64.content = fileB64.content ? fileB64.content : '';

      this.attachDocumentsService.postAttachment(fileB64)
      .subscribe(response => {
        this.labelsToUpload[onGoingId].code = response['code'];
        this.labelsToUpload[onGoingId].protheusId = response['id'];
        this.labelsToUpload[onGoingId].documentKey = response['key'];
        this.temporaryStorageService.setValue('hasAttachment', true);
        this.uploadSucess.emit()
        this.poNotification.success(response.message);
        this.menuService.loading(false);
      }, error => {
        this.labelsToUpload[onGoingId].status = 'fail';
        this.labelsToUpload[onGoingId].labelStatus = this.literals?.str0191;
        this.labelsToUpload[onGoingId].code = 400;
        this.poNotification.error(this.utils.getErrorMessage(error));
        if (this.filesToUpload.length) {
          this.uploadFiles();
        } else {
          this.disableUpload = false;
        }
        this.menuService.loading(false);
      }, () => {
        this.labelsToUpload[onGoingId].status = 'success';
        this.labelsToUpload[onGoingId].labelStatus = this.literals?.str0192;
        if (this.filesToUpload.length) {
          this.uploadFiles();
        } else {
          this.disableUpload = false;
        }
        this.menuService.loading(false);
      });
    };
    reader.readAsDataURL(fileToUpload);
  }

  getIconStatus(status: string) {
    switch (status) {
      case 'waiting':
        return 'po-icon-clock';
      case 'loading':
        return 'po-icon-refresh spin-uploading';
      case 'success':
        return 'po-icon-ok';
      case 'fail':
        return 'po-icon-close';
    }
  }

  getIconFromStatus(fileType: string) {
    const type = fileType.split('/')[0];
    return type === 'image' ? 'po-icon-picture' : 'po-icon-document';
  }

  cancelUpload(index: number) {
    const currentFile = this.labelsToUpload[index];
    const indexToUp = this.filesToUpload.findIndex(f => f.id === currentFile.id);
    if (currentFile.status === 'waiting' || currentFile.status === 'fail') {
      this.labelsToUpload.splice(index, 1);
      this.filesToUpload.splice(indexToUp, 1);
    } else if (currentFile.status === 'success' || currentFile.status === 'exist') {
      this.thfDialogService.confirm({
        title: this.literals?.str0193,
        message: this.poI18n.transform(this.literals?.str0194, currentFile.name),
        confirm: () => this.deleteDocument(currentFile.name, currentFile.protheusId, index)
      });
    }
  }

  deleteDocument(nameToDelete: string, idToDelete: string, index: number) {
    const body = {
      "document": idToDelete,
      "documentKey": this.labelsToUpload[index].documentKey,
      "table": this.table
    }
    this.menuService.loading(true);
    this.labelsToUpload[index].status = 'loading';
    this.labelsToUpload[index].labelStatus = this.literals?.str0195;
    this.disableDelete = true;
    this.attachDocumentsService.deleteAttachment(body)
      .subscribe(response => {
        this.labelsToUpload.splice(index, 1);
        this.temporaryStorageService.setValue('hasAttachment', !!this.labelsToUpload.length);
        this.disableDelete = false;
        this.menuService.loading(false);
        this.uploadSucess.emit();
        this.poNotification.success(this.poI18n.transform(this.literals?.str0196, nameToDelete));
      }, (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error));
        this.menuService.loading(false);
      });
  }

  downloadDocument(attachment) {
    this.menuService.loading(true);

    if (this.loginService.isProtheusLogged()) {
      sessionStorage.setItem('attachmentSaved', undefined);
      const data: Object = {
        'name': attachment.name.trim(),
        'executionHash': this.executionHashService.getExecutionHash()
      };

      totvstec.jsToAdvpl('download', JSON.stringify(data));
      this.attachmentDownloadNotification();
    } else {
      this.attachDocumentsService.downloadAttachment(attachment.protheusId)
      .subscribe(response => {
        this.menuService.loading(false);
        const byteString = atob(response['file']);

        const aBuffer = new ArrayBuffer(byteString.length);
        const iArray = new Uint8Array(aBuffer);

        for (let i = 0; i < byteString.length; i++) {
          iArray[i] = byteString.charCodeAt(i);
        }

        const file = new Blob([iArray]);

        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', window.URL.createObjectURL(file));
        element.setAttribute('download', attachment.name.trim());
        element.click();
      }, error => {
        this.poNotification.error(this.utils.getErrorMessage(error));
        this.menuService.loading(false);
      });
    }
  }

  addFilesToLists(files): boolean {
    this.disableUpload = false;
    let ok = true;
    let id;
    _.remove(files, f => {
      if (f.type === '') {
        this.poNotification.warning(this.poI18n.transform(this.literals?.str0197, f.name));
        ok = false;
        return true;
      } else if (f.size > 10000000) {
        this.poNotification.warning(this.poI18n.transform(this.literals?.str0198, f.name));
        ok = false;
        return true;
      }
    });

    if (this.labelsToUpload.length) {
      id = this.labelsToUpload[this.labelsToUpload.length - 1].id + 1;

      files.forEach(f => {
        f.id = id++;
      });

      this.filesToUpload.push(...files);
      this.labelsToUpload.push(...files);

      this.filesToUpload.forEach(f => {
        f.status = 'waiting';
        f.labelStatus = this.literals?.str0199;
      });
    } else {
      this.filesToUpload = Array.from(files);
      this.labelsToUpload = Array.from(files);

      id = 0;
      this.labelsToUpload.forEach(f => {
        f.id = id++;
        f.status = 'waiting';
        f.labelStatus = this.literals?.str0199;
      });

      id = 0;
      this.filesToUpload.forEach(f => {
        f.id = id++;
      });
    }

    return ok;
  }

  drop(e) {
    e.preventDefault();
    this.isHovering = false;
    const files =  Array.from(e.dataTransfer.files);
    if (files && files.length > 0) {
      this.addFilesToLists(files);
    }
  }

  allowDrop(e) {
    e.preventDefault();
    this.isHovering = true;
  }

  dragEnter(e) {
    this.isHovering = true;
  }

  dragLeave(e) {
    this.isHovering = false;
  }

  openFileDialog() {
    const fileButtonHandler: HTMLElement = document.getElementById('fileButton') as HTMLElement;
    fileButtonHandler.click();
  }

  //-- Aguarda a execução do download do anexo via Protheus para exibir uma notificação de sucesso ou erro.
  attachmentDownloadNotification(): void {
    this.intervalDownload = interval(5000).subscribe(() => {
      let attachmentSaved: string | null = sessionStorage.getItem('attachmentSaved');
  
      if (attachmentSaved !== 'undefined') {
        attachmentSaved = JSON.parse(attachmentSaved);

        if (attachmentSaved['executionHash'] === this.executionHashService.getExecutionHash()) {
          if (attachmentSaved['status']) {
            this.poNotification.success(attachmentSaved['message']); //-- Anexo salvo com sucesso.
          } else if (attachmentSaved['message']) {
            this.poNotification.error(attachmentSaved['message']); //-- Não foi possível realizar o download do arquivo pois ele pode estar corrompido.
          }
          this.intervalDownload.unsubscribe();
          this.menuService.loading(false);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.intervalDownload) {
      this.intervalDownload.unsubscribe();
    }
  }
}
