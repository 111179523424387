// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: #4a5c60;
}

.container {
  width: 100% !important;
}

po-table {
  width: 100%;
}

::ng-deep.po-checkbox {
  font-weight: bold;
}

.button-load {
  margin: 0 auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 40%;
}
 
.button{
  margin-left: 10px;
}
.buttonsSubmit{
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/supplier-selection/supplier-selection.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":["* {\n  color: #4a5c60;\n}\n\n.container {\n  width: 100% !important;\n}\n\npo-table {\n  width: 100%;\n}\n\n::ng-deep.po-checkbox {\n  font-weight: bold;\n}\n\n.button-load {\n  margin: 0 auto;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 40%;\n}\n \n.button{\n  margin-left: 10px;\n}\n.buttonsSubmit{\n  display: flex;\n  justify-content: flex-end;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
