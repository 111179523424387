import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderOption, MingleService } from '@totvs/mingle';
import { Observable, Subject } from 'rxjs';
import { LoginService } from 'src/app/shared/services/login.service';
import { ProAppConfigService } from '@totvs/protheus-lib-core';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
import { PoLookupColumn } from '@po-ui/ng-components';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from '../../../../shared/services/literals-service';

const branch = sessionStorage.getItem('ProBranch') ? JSON.parse(sessionStorage.getItem('ProBranch')).Code : sessionStorage.getItem('branch');
const group = sessionStorage.getItem('ProBranch') ? JSON.parse(sessionStorage.getItem('ProBranch')).CompanyCode : sessionStorage.getItem('group');
let observable: any;
let options;


const defaultHeaders: Array<HeaderOption> = [
  {name: 'tenantId', value: group + ',' + branch},
];

@Injectable({ providedIn: 'root' })
export class PurchaseListService {

  private disable = new Subject<any>();

  changeBrach = new Subject<any>();
  branchesColumns: Array<PoLookupColumn> = [];
  literals: LiteralsWords;

  constructor(
    private http: HttpClient,
    private mingleService: MingleService,
    private loginService: LoginService,
    private configService: ProAppConfigService,
    private literalsService: LiteralsService
    ) {
      this.literals = this?.literalsService?.getLiterals();
      this.configService.loadAppConfig();
      this.mingleService.setDefaultHeaders(defaultHeaders);
      
      this.branchesColumns = [
        { property: 'Code', label: this.literals?.str0135 },
        { property: 'Description', label: this.literals?.str0048 },
        { property: 'Cgc', label: this.literals?.str0072 }
      ];
      
    }

  pendente$ = this.disable.asObservable();

  getOpenItems(page: number, pageSize: number, search: string = '', advancedFilterQuery: string = '', startDate: string = '', endDate: string = '', sort: string = '', centralizedPurchase: boolean = false): Observable<any> {
    let urlParam = ``;
    const params = new HttpParams()

      .append('page', page.toString())
      .append('pageSize', pageSize.toString())
      .append('FILTER', advancedFilterQuery);

      if (centralizedPurchase) {
        urlParam = `/pgcSyncPurchaseRequest/centralized`
      } 
      else {
        urlParam = `/pgcSyncPurchaseRequest/open`
      }

      // FILTRO SEM NENHUM PARÂMETRO
      if ((search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString()
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}` + urlParam + `?${params}`);
      }
    }

    // FILTRO APENAS POR DATA
    if (startDate && (search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        let params = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'&page=${page}&pageSize=${pageSize}`;
        return this.http.get(`${environmentProtheus.url}` + urlParam + `?${params}`);
      }
    }

    // FILTRO APENAS POR SEARCH
    if ((search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}')`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };
      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}` + urlParam + `?`,{params:options});
      }
    }

    // FILTRO APENAS AVANÇADO
    if ((advancedFilterQuery !== '' || advancedFilterQuery !== null) && (search === '' || search === null) && (startDate === '' || startDate === null)) {
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": 'contains'+ advancedFilterQuery,
      };
      
      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}` + urlParam + `?page=${page}&pageSize=${pageSize}&FILTER=contains${advancedFilterQuery}`);
      }
    }

    // FILTRO POR DATA E SEARCH
    if (startDate !== '' && startDate !== null && (search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
        return this.http.get(`${environmentProtheus.url}`+ urlParam + `?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA E AVANÇADO
    if (startDate !== '' && startDate !== null && (search === '' || search === null) && advancedFilterQuery !== '' && advancedFilterQuery !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}` + urlParam + `?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA, SEARCH E AVANÇADO
    if (search !== '' && search !== null && advancedFilterQuery !== '' && advancedFilterQuery !== null && startDate !== '' && startDate !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}`+ urlParam + `?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO APENAS POR SEARCH E AVANÇADO
    if ((search !== '' || search !== null) && (advancedFilterQuery !== '' || advancedFilterQuery !== null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}` + urlParam, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}` + urlParam,{params:options});
      }
    }
  }

  getPartiallyAttended(page: number, pageSize: number, search: string = '', advancedFilterQuery: string = '', startDate: string = '', endDate: string = '', sort: string = ''): Observable<any> {
    const options ={
      "page":  page.toString(),
      "pageSize": pageSize.toString()
    };
    const params = new HttpParams()
      .append('page', page.toString())
      .append('pageSize', pageSize.toString())
      .append('FILTER', advancedFilterQuery);

    // FILTRO SEM NENHUM PARÂMETRO
    if ((search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      let observable: any;

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?${params}`);
      }
    }

    // FILTRO APENAS POR DATA
    if (startDate && (search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        let params = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'&page=${page}&pageSize=${pageSize}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?${params}`);
      }
    }

    // FILTRO APENAS POR SEARCH
    if ((search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}')`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?&FILTER=${query}${params}`);
      }
    }

    // FILTRO APENAS AVANÇADO
    if ((advancedFilterQuery !== '' || advancedFilterQuery !== null) && (search === '' || search === null) && (startDate === '' || startDate === null)) {
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": 'contains'+ advancedFilterQuery,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?page=${page}&pageSize=${pageSize}&FILTER=contains${advancedFilterQuery}`);
      }
    }

    // FILTRO POR DATA E SEARCH
    if (startDate !== '' && startDate !== null && (search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA E AVANÇADO
    if (startDate !== '' && startDate !== null && (search === '' || search === null) && advancedFilterQuery !== '' && advancedFilterQuery !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA, SEARCH E AVANÇADO
    if (search !== '' && search !== null && advancedFilterQuery !== '' && advancedFilterQuery !== null && startDate !== '' && startDate !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO APENAS POR SEARCH E AVANÇADO
    if ((search !== '' || search !== null) && (advancedFilterQuery !== '' || advancedFilterQuery !== null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/partial`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/partial`,{params: options});
      }
    }
  }

  getAttended(page: number, pageSize : number, search: string = '', advancedFilterQuery: string = '', startDate: string = '', endDate: string = '', sort: string = ''): Observable<any> {

    const options ={
      "page":  page.toString(),
      "pageSize": pageSize.toString()
    };

    const params = new HttpParams()
      .append('page', page.toString())
      .append('pageSize', pageSize.toString())
      .append('FILTER', advancedFilterQuery);


    // FILTRO SEM NENHUM PARÂMETRO
    if ((search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      let observable: any;

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?${params}`);
      }
    }

    // FILTRO APENAS POR DATA
    if (startDate && (search === '' || search === null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        let params = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}'&page=${page}&pageSize=${pageSize}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?${params}`);
      }
    }

    // FILTRO APENAS POR SEARCH
    if ((search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}')`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?FILTER=${query}${params}`);
      }
    }

    // FILTRO APENAS AVANÇADO
    if ((advancedFilterQuery !== '' || advancedFilterQuery !== null) && (search === '' || search === null) && (startDate === '' || startDate === null)) {
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": 'contains'+ advancedFilterQuery,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?page=${page}&pageSize=${pageSize}&FILTER=contains${advancedFilterQuery}`);
      }
    }

    // FILTRO POR DATA E SEARCH
    if (startDate !== '' && startDate !== null && (search !== '' || search !== null) && (advancedFilterQuery === '' || advancedFilterQuery === null)) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}')`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA E AVANÇADO
    if (startDate !== '' && startDate !== null && (search === '' || search === null) && advancedFilterQuery !== '' && advancedFilterQuery !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO POR DATA, SEARCH E AVANÇADO
    if (search !== '' && search !== null && advancedFilterQuery !== '' && advancedFilterQuery !== null && startDate !== '' && startDate !== null) {
      let query = `c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        let query = `FILTER=c1_datprf ge '${startDate}' and c1_datprf le '${endDate}' and contains (c1_produto,'${search}') and contains ${advancedFilterQuery}`;
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended?page=${page}&pageSize=${pageSize}&${query}`);
      }
    }

    // FILTRO APENAS POR SEARCH E AVANÇADO
    if ((search !== '' || search !== null) && (advancedFilterQuery !== '' || advancedFilterQuery !== null) && (startDate === '' || startDate === null)) {
      let query = `contains(c1_produto,'${search}') and contains ${advancedFilterQuery}`;
      const options ={
        "page":  page.toString(),
        "pageSize": pageSize.toString(),
        "FILTER": query,
      };

      if (!this.loginService.isProtheusLogged()) {
        observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/attended`, null , options);
        return observable;
      } else {
        return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/attended`,{params:options});
      }
    }
  }

  getAppendItems(page: number, pageSize: number, codigo: string, item: string) {
    let observable: any;
    const options = {
      "page":  page.toString(),
      "pageSize": pageSize.toString(),
    };
    if (!this.loginService.isProtheusLogged()) {
      observable  =this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/documentsList/${codigo}/${item}`, null, options);
      return observable;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/documentsList/${codigo}/${item}`, {params:options});
    }
  }

  getPDF(idDocument: string): Observable<any> {
    let observable: any;

    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.get(`${environment.url}/pgcSyncPurchaseRequest/document/${idDocument}`)
      return observable;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcSyncPurchaseRequest/document/${idDocument}`);
    }
  }

  getDisableForm() {
    this.disable.next(this.pendente$);
    this.disable;
  }

  getSearchColumns(){
    return this.branchesColumns;
  }

}
