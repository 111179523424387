export const environment = {
  production: true,
  url: 'api/com/v1',
  // Mingle Início
  appIdentifier: '6171c79a0de6252b310e5c4d',
  environment: 'DEV',
  server: 'https://dev-mingle.totvs.com.br',
  web: true,
  usageMetrics: true,
  gateway: true,
  urlFrame: 'api/framework/v1'
  // Mingle Fim
};
