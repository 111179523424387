import { Observable } from 'rxjs';
import { LiteralsWords } from './../../i18n/literals.model';
import { Injectable } from '@angular/core';
import { PoI18nService } from '@po-ui/ng-components';

@Injectable({ providedIn: 'root' })
export class LiteralsService {

  language: string = navigator.language;

  literals: LiteralsWords = this.getLiterals();

  constructor(private i18nService: PoI18nService) {

    i18nService.getLiterals({language: this.language}).subscribe((literals: LiteralsWords) => this.literals = literals);

  }

  getLiterals(): LiteralsWords {
    return this.literals;
  }

  initializeLiterals(): void {
    let systemlanguage = localStorage.getItem('PO_USER_LOCALE');
    switch (systemlanguage) {

      case ('pt'):
        this.i18nService.getLiterals({language: 'pt-BR'}).subscribe((literals: LiteralsWords) => this.literals = literals);
        this.i18nService.setLanguage('pt-BR');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'pt-BR');
        break;

      case ('en'):
        this.i18nService.getLiterals({language: 'en-US'}).subscribe((literals: LiteralsWords) => this.literals = literals);
        this.i18nService.setLanguage('en-US');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'en');
        break;

      case ('en-us'):
        this.i18nService.getLiterals({language: 'en-US'}).subscribe((literals: LiteralsWords) => this.literals = literals);
        this.i18nService.setLanguage('en-US');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'en');
        break;

      case ('es'):
        this.i18nService.getLiterals({language: 'es-es'}).subscribe((literals: LiteralsWords) => { this.literals = literals});
        this.i18nService.setLanguage('es-es');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'es');
        break;

      case ('es-es'):
        this.i18nService.getLiterals({language: 'es-es'}).subscribe((literals: LiteralsWords) => { this.literals = literals});
        this.i18nService.setLanguage('es-es');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'es');
        break;

      case 'ru':
        this.i18nService.getLiterals({language: 'ru'}).subscribe((literals: LiteralsWords) => this.literals = literals);
        this.i18nService.setLanguage('ru');
        localStorage.setItem('PO_DEFAULT_LANGUAGE', 'ru');
        break;
    }

  }

}
