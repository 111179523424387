export class DateHandler {

  convertToProtheusDate(date) {
    const day = (date.getDate().toString()).padStart(2, '0');
    const month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}${month}${day}`;
  }

  convertStringToProtheusDate(date: string): string {
    const year = (date.substring(0, 4));
    const month = (date.substring(5, 7));
    const day = date.substring(8, 10);
    return `${year}${month}${day}`;
  }

  fromProtheusToPOUI(date: string) {
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6)) - 1;
    const day = Number(date.substring(6, 8));
    return new Date(year, month, day);
  }

  fromProtheusToPOUIString(date: string): string {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}/${month}/${day}`;
  }

  fromProtheusDateToStringPoUi(date: string): string {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${day}/${month}/${year}`;
  }

  // static fromGridToProtheus(date) {
  //   const day = (date.getDate().toString()).padStart(2, '0');
  //   const month = ((date.getMonth() + 1).toString()).padStart(2, '0');
  //   const year = date.getFullYear().toString();

  //   return `${year}${month}${day}`;
  // }

  fromPOUIDatepickerToDate(date: string) {
    if (date !== undefined) {
      const year = Number(date.slice(0, 4));
      const month = Number(date.slice(5, 7)) - 1;
      const day = Number(date.slice(8, 10));

      return new Date(year, month, day);
    }
  }

  fromPOUIDatepickerToDateFormated(date: string) {
    if (date !== undefined) {
      const year = Number(date.slice(0, 4));
      const month = Number(date.slice(5, 7)) - 1;
      const day = Number(date.slice(8, 10));
      return new Date(day, month, year);
    }
  }

  fromStringToDateFormated(date: string): string {
    const year = (date.slice(0, 4));
    const month = (date.slice(5, 7));
    const day = (date.slice(8, 10));
    return `${day}/${month}/${year}`;
  }

  fromStringToDateFormatedAlternative(date: string): string { // Mês com 1 dígito
    const arrayDate: Array<any> = date.split('-');
    const year: string = arrayDate[0];
    let month: string = arrayDate[1];
    let day: string = arrayDate[2];

    if (month.length == 1) {
      month = month.padStart(2, '0');
    }

    if (day.length == 1) {
      day = day.padStart(2, '0');
    }

    return `${day}/${month}/${year}`;
  }

  getCurrentDate() {
    return this.convertToProtheusDate(new Date(Date.now()));
  }

  convertCnpj(data) {
    const dataStr = data.toString().padStart(14, '0');
    const cnpjPart1 = dataStr.substring(0, 2);
    const cnpjPart2 = dataStr.substring(2, 5);
    const cnpjPart3 = dataStr.substring(5, 8);
    const cnpjPart4 = dataStr.substring(8, 12);
    const cnpjPart5 = dataStr.substring(12);

    let formattedCnpj = `${cnpjPart1}.${cnpjPart2}.${cnpjPart3}/${cnpjPart4}-${cnpjPart5}`;
  
    return formattedCnpj;
  }

  convertCpf(data) {
    const dataStr = data.toString().padStart(11, '0');
  
    let cpfPart1 = dataStr.substring(0, 3);
    let cpfPart2 = dataStr.substring(3, 6);
    let cpfPart3 = dataStr.substring(6, 9);
    let cpfPart4 = dataStr.substring(9);
  
    let formattedCpf = `${cpfPart1}.${cpfPart2}.${cpfPart3}-${cpfPart4}`;
  
    return formattedCpf;
  }  
    
}
