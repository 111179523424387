import { MenuService } from 'src/app/features/purchase-requests/shared/services/menu.service';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from './i18n/literals.model';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ProAppConfigService, ProSessionInfoService } from '@totvs/protheus-lib-core';
import { LoginService } from './shared/services/login.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  loading: boolean = false;

  literals: LiteralsWords = this.literalsService.getLiterals();

  constructor(
    private literalsService: LiteralsService,
    private configService: ProAppConfigService,
    private menuService: MenuService,
    private loginService: LoginService,
    private proSessionInfoService: ProSessionInfoService
  ) {}

  ngOnInit(): void{
    this.configService.loadAppConfig();
    this.menuService.behaviorLoading.subscribe(value => this.loading = value);
    this.handleChangeLanguage();
  }

  ngOnDestroy(): void {
    localStorage.clear();
  }

  handleChangeLanguage() {
    if (this.loginService.isProtheusLogged()){
      localStorage.setItem('PO_USER_LOCALE', this.proSessionInfoService.getIdiom());
      this.literalsService.initializeLiterals();
    }
  }

}