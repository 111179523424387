import { PurchaseRequestsService } from './../../services/purchase-requests.service';
import { Component, OnInit, OnDestroy, ViewChild, Output, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { PoDisclaimer, PoDisclaimerGroupRemoveAction, PoModalComponent, PoNotificationService } from '@po-ui/ng-components';
import { LiteralsService } from './../../../../../shared/services/literals-service';
import { LiteralsWords } from './../../../../../i18n/literals.model';
import { OnDestroyClass } from 'src/app/shared/utils/on-destroy-class';
import { EventEmitter } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Utils } from 'src/app/shared/helper/utils';
import { Subscription, interval } from 'rxjs';
import { MenuService } from '../../services/menu.service';
import { PgcProfileItem } from 'src/app/shared/models/pgc-profile.model';

declare var totvstec: any; //-- Variável disponível apenas quando a aplicação é executada dentro do Protheus

@Component({
  selector: 'tgc-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent extends OnDestroyClass implements OnInit, OnDestroy {

  @Output() inputDateFilter = new EventEmitter();
  @Output() inputSearchFilter = new EventEmitter();
  @Output() inputAdvancedFilter = new EventEmitter();
  @Output() clearInputAdvancedFilter = new EventEmitter();
  @Output() changeInputAdvancedFilter = new EventEmitter();
  @Output() changeSwitchValidateProductGroup = new EventEmitter();
  @Output() removeAllFilters = new EventEmitter();
  @Input () showSwitchValidadeProductGroup: boolean = false;
  @Input () urlParam: string = ''
  @Input () tabNumber: number = 0;
  @Input () currentTab: number = 0;

  @ViewChild('pageSlide', { static: true }) pageSlide: PoModalComponent;

  searchForm: FormGroup;
  advancedFilterForm: FormGroup;

  disableForm: boolean = true;
  validateDiffProductGroups: boolean = false;
  productDescription = [];
  productGroupColumns = [];
  requestCodeColumns = [];
  requesterColumns = [];
  branchColumns = [];
  coastCenterColumns = [];
  ledgerAccountColumns = [];
  accountingItemColumns = [];
  valueClassColumns = [];
  // disclaimer
  filteredItems: Array<any> = [];
  filters: Array<PoDisclaimer> = [];
  items: Array<any>;
  disclaimer: PoDisclaimer;
  disclaimers: Array<PoDisclaimer>;
  event: string;
  modelLookupChange: boolean = false;
  cleanLookupModel: boolean = false;
  cleanLookupField: string = '';
  purchaseRequestURL: string = '';
  branchesURLParams: string = '';
  currentSearchedProductCode: string = '';
  useProductGroup: string = sessionStorage.getItem('useProductGroup');
  literals: LiteralsWords = this.literalsService.getLiterals();
  loading: boolean = false;
  profile: PgcProfileItem;

  public intervalClosed: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private literalsService: LiteralsService,
    private purchaseRequeststService: PurchaseRequestsService,
    private searchService: SearchService,
    private utils: Utils,
    private poNotification: PoNotificationService,
    private menuService: MenuService

  ) {
    super();
    this.searchForm = this.formBuilder.group({
      date: [null],
      search: [null],
    });
    this.advancedFilterForm = this.formBuilder.group({
      c1_num: [null],
      c1_solicit: [null],
      c1_produto: [null],
      c1_descri: [null],
      b1_grupo: [null],
      bm_desc: [null],
      c1_filent: [null],
      c1_filial: [null],
      c1_cc: [null],
      c1_conta: [null],
      c1_itemcta: [null],
      c1_clvl: [null],
    });

    this.productDescription = this.searchService.getLookupColumns('c1_descri');
    this.productGroupColumns = this.searchService.getLookupColumns('b1_grupo');
    this.requestCodeColumns = this.searchService.getLookupColumns('c1_num');
    this.requesterColumns = this.searchService.getLookupColumns('c1_solicit');
    this.branchColumns = this.searchService.getLookupColumns('c1_filial');
    this.coastCenterColumns = this.searchService.getLookupColumns('c1_cc');
    this.ledgerAccountColumns = this.searchService.getLookupColumns('c1_conta');
    this.accountingItemColumns = this.searchService.getLookupColumns('c1_itemcta');
    this.valueClassColumns = this.searchService.getLookupColumns('c1_clvl');
    this.branchesURLParams = `EnterpriseGroup=${this.utils.getEnterpriseGroup()}`
  }

  ngOnInit(): void {
    this.purchaseRequeststService.behaviorDisable.subscribe((value) => this.disableForm = value);
    this.purchaseRequestURL = `pgcSyncPurchaseRequest/${this.urlParam}`;
    this.profile = this.utils.getProfile();

    //valida no profile as preferencias de grupo de produto
    this.validateDiffProductGroups = this.profile.productGroup;
  }

  handleDisableForm(event): void {
    this.disableForm = event;
  }

  changeInputSearch($event): void {
    const value: string = $event?.value;
    this.inputSearchFilter.emit(value ? value : '');
  }

  changeInputDateFilter(): void {
    this.inputDateFilter.emit(this.searchForm.value);
  }

  applyFilter(isClean: boolean): void {
    this.disclaimers = [];

    if (isClean) {
      this.modelLookupChange = false;
      this.cleanLookupModel = true;
      this.clearAdvancedFilterForm();
    } else {
      
      this.modelLookupChange = true;
      this.cleanLookupModel = false;
      const array: Array<any> = Object.keys(this.advancedFilterForm.value).map((key) => [key, this.advancedFilterForm.value[key]]);
      const newArray = array.filter(element => element[1] !== null);
      newArray.forEach((element, index) => {
        this.disclaimers.push(
          {
            hideClose: false,
            label: `${this.getControlNameTranslated(element[0])}: ${element[1]}`,
            property: `${this.getControlName(this.advancedFilterForm.controls[element[0]])}`,
            value: element[1].toLowerCase()
          });
      });
      if (!this.pageSlide?.['hidden']) {
        this.pageSlide?.close();
      }
    }
    this.inputAdvancedFilter.emit(this.advancedFilterForm);
  }

  clearSearchForm(): void {
    this.cleanLookupModel = true;
    this.searchForm.reset();
  }

  clearAdvancedFilterForm(): void {
    this.cleanLookupModel = true;
    this.advancedFilterForm.reset();
  }

  changeFilters(filters: Array<PoDisclaimer>): void {
    this.modelLookupChange = true;
    this.cleanLookupModel = false;

    if (filters.length > 0) {
      this.filterItem(filters);
    } else {
      this.resetFilters();
      this.clearInputAdvancedFilter.emit(this.advancedFilterForm.value);
    }
  }

  private filterItem(filters: Array<PoDisclaimer>): void {
    const filterCondition = (filter, item) => item[filter.property]
      .toLocaleLowerCase()
      .includes(filter.value.toLocaleLowerCase());
    const filterItems = item => filters.every(filter => filterCondition(filter, item));
    this.filteredItems = this.filteredItems.filter(filterItems);
  }

  private resetFilters(): void {
    this.modelLookupChange = false;
    this.cleanLookupModel = true;

    this.filteredItems = [...(this.items || [])];
  }

  restore(): void {
    this.disclaimer = { value: undefined };
    this.disclaimers = [];
    this.event = '';
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

  getControlNameTranslated(name: string): string {
    switch (name) {
      case 'c1_num':
        return this.literals.str0015;
      case 'c1_solicit':
        return this.literals.str0024;
      case 'c1_descri':
        return this.literals.str0018;
      case 'b1_grupo':
        return this.literals.str0439;
      case 'bm_desc':
        return this.literals.str0440;
      case 'c1_filent':
        return this.literals.str0025;
      case 'c1_filial':
        return this.literals.str0026;
      case 'c1_cc':
        return this.literals.str0027;
      case 'c1_conta':
        return this.literals.str0028;
      case 'c1_itemcta':
        return this.literals.str0029;
      case 'c1_clvl':
        return this.literals.str0030;
    }
  }

  removeDisclaimer(event: PoDisclaimerGroupRemoveAction) {
    this.modelLookupChange = false;
    this.cleanLookupModel = false;
    this.cleanLookupField = event?.removedDisclaimer?.property;
    this.changeInputAdvancedFilter.emit(event);
  }

  handleRemoveAllFilters() {
    this.modelLookupChange = false;
    this.cleanLookupModel = true;

    this.clearAdvancedFilterForm();
    this.removeAllFilters.emit();
    this.inputAdvancedFilter.emit(this.advancedFilterForm);
  }

  handleSwitchValidateProductGroup() {
    this.changeSwitchValidateProductGroup.emit(this.validateDiffProductGroups);
  }

}
