import { PurchaseRequestsService } from './../../../features/purchase-requests/shared/services/purchase-requests.service';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PoComboOption, PoLanguage, PoNotificationService } from '@po-ui/ng-components';
import { PoPageLoginCustomField, PoPageLoginLiterals } from '@po-ui/ng-templates';
import { MingleService } from '@totvs/mingle';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from './../../../i18n/literals.model';
import { LoginService } from 'src/app/shared/services/login.service';
import { Utils } from '../../../shared/helper/utils';
import { MenuService } from '../../../features/purchase-requests/shared/services/menu.service';

@Component({
  templateUrl: './login.component.html'

})
export class LoginComponent implements OnInit {
  customLiterals: PoPageLoginLiterals;
  customField: PoPageLoginCustomField;
  isLoading = false;
  loginErrors: string[] = [];

  literals: LiteralsWords;

  themeOptions: Array<PoComboOption> = [
    {label: 'Classic', value: '0'},
    {label: 'Dark', value: '1'},
    {label: 'Sunset', value: '2'},
  ];

  constructor(
    private router: Router,
    private mingleService: MingleService,
    private literalsService: LiteralsService,
    private purchaseRequestsService: PurchaseRequestsService,
    private loginService: LoginService,
    private poNotification: PoNotificationService,
    private utils: Utils,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);
    this.setCustomFields();
    this.setCustomLiterals();
  }

  handleChangeLanguage(event: PoLanguage) {
    localStorage.setItem('PO_USER_LOCALE', event.language);
    this.literalsService.initializeLiterals();
    this.setCustomFields();
    this.setCustomLiterals();
  }

  setCustomLiterals() {
    this.literals = this.literalsService.getLiterals();
    this.customLiterals = {
      loginPlaceholder: this.literals.str0051,
      passwordPlaceholder: this.literals.str0052,
    };
    return {
      property: 'alias',
      placeholder: this.literals.str0053

    };
  }

  login(event: any): any {
    this.menuService.loading(true); //-- Ativa loading para carregar resolvers, é desativado no home.component
    this.isLoading = true;
    this.loginErrors = [];
    this.mingleService.auth
      .login(event.login, event.password, event.alias)
      .subscribe((dataLogin) => {
        //this.utils.cleanLocalStorage();
        sessionStorage.setItem('group', dataLogin.params_values[0]?.value);
        sessionStorage.setItem('branch', dataLogin.params_values[1]?.value);
        const branch = dataLogin.params_values[1]?.value;
        this.purchaseRequestsService.changeBranch(branch);
        this.mingleService.registerMetric('total-de-acessos-ao-pgc', {value: 1})
        this.loginService.getUserInfos(event.login)
          .subscribe((user) => {
            this.isLoading = false;
            sessionStorage.setItem('user', user.userName);
            sessionStorage.setItem('userId', user.userId);
            sessionStorage.setItem('userAdmin', user.userAdmin);
            sessionStorage.setItem('userEmail', user.userEmail);
            sessionStorage.setItem('paramWfBuyer', user.paramWfBuyer);
            sessionStorage.setItem('postalBoxName', user.postalBoxName);
            this.router.navigate(['home']);
          }, error => {

            sessionStorage.removeItem('group');
            sessionStorage.removeItem('branch');

            this.isLoading = false
            this.menuService.loading(false);
            this.poNotification.error(this.utils.getErrorMessage(error));
          });
      }, (authError) => {
        this.isLoading = false;
        this.menuService.loading(false);
        this.loginErrors = [this.literals?.str0189];
      });
  }

  passwordChange(){
    if (this.loginErrors.length) {
      this.loginErrors = [];
    }
  }

  setCustomFields(): void {
    this.customField = this.setCustomLiterals();
  }

}
