import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { DateHandler } from 'src/app/shared/helper/date-handler';
import { PurchaseRequestsService } from './../shared/services/purchase-requests.service';
import { QuotationModel } from './../shared/models/quotation.model';
import { Append } from './../shared/models/append.model';
import { PoBreadcrumb, PoSelectOption, PoTableColumn, PoNotificationService, PoButtonGroupItem, PoModalComponent, PoDisclaimer, PoModalAction, PoDialogService, PoNotification, PoMultiselectOption  } from '@po-ui/ng-components';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { SupplierModel } from './../shared/models/supplier.model';
import { SupplierService } from '../shared/services/suppliers.service';
import { HandleStringWithRegex } from './../../../shared/utils/handleStringWithRegex';
import { LiteralsWords } from './../../../i18n/literals.model';
import { MingleService } from '@totvs/mingle';
import { AttachDocumentsComponent } from '../shared/components/attach-documents/attach-documents.component';
import { Utils } from '../../../shared/helper/utils';
import { Subscription, interval } from 'rxjs';
import { SupplierEmailComponent } from './supplier-email/supplier-email.component';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';
import { ExecutionHashService } from 'src/app/shared/services/execution-hash.service';

declare var totvstec: any; //-- Variável disponível apenas quando a aplicação é executada dentro do Protheus

@Component({
  selector: 'tgc-supplier-selection',
  templateUrl: './supplier-selection.component.html',
  styleUrls: ['./supplier-selection.component.css']
})
export class SupplierSelectionComponent implements OnInit, AfterViewInit{

  // -- Nova interface de atualização de contato
  isNewUpdateContact: boolean = false;
  public intervalClosed: Subscription;
  //
  titlePage: string
  supplierForm: FormGroup;
  searchForm: FormGroup;
  newSuppliersForm: FormGroup;
  loading: boolean = false;
  loadingGenerateQuotationModal: boolean = false;
  loadingModalUpdateSupplier: boolean = false;
  literals: LiteralsWords = this.literalsService.getLiterals();
  columns: Array<PoTableColumn>;
  columsInvalidItemsTable: Array<PoTableColumn>;
  columsPurchaseRequestsItemsTable: Array<PoTableColumn>;
  order: Array<PoSelectOption>;
  suppliersShown: number;
  totalSuppliers: number;
  totalLastSuppliers: number;
  attachDocumentsComponent: AttachDocumentsComponent;
  suppliersPageViewer: boolean;
  divArea: boolean = false;

  lastSuppliersList: Array<any>;
  lastSuppliersListInitial: Array<any> = [];
  lastSuppliersListFiltered: Array<any> = [];

  allSuppliersList: Array<any> = [];
  allSuppliersListInitial: Array<any> = [];
  allSuppliersListFiltered: Array<any> = [];

  paymentConditions: string= '';

  groupSuppliers: Array<any>;
  lastSuppliersPage: number = 1;
  allSuppliersPage: number = 1;
  pageSize: number = 10;
  numberOfSuppliers: number = 10;

  disableButtonAllSuppliers: boolean = false;
  disableButtonLastSuppliers: boolean = false;

  buttons: Array<PoButtonGroupItem> = [
    { label: this.literals?.str0123, action: this.showDetails.bind(this) },
    { label: this.literals?.str0124, action: this.openModalUpdateContacts.bind(this) }
  ];

  disclaimer: PoDisclaimer;
  disclaimers: Array<PoDisclaimer> = [];

  itemsReceived: any;

  checkbox: boolean | null;

  canRequestQuote: boolean = false;
  supplierDetailsToBeShown: SupplierModel;

  public readonly breadcrumb: PoBreadcrumb = {
    items: [
      { label: this.literals?.str0002, link: '/necessidade-de-compra' },
      { label: this.literals?.str0065 , action: this.navigateBack.bind(this) }, { label: this.literals?.str0597 }
    ]
  };

  action: string;
  actionOptions: Array<string>;

  quotationNumber: string = '0001';

  columnsProvidersGrid: Array<any>;
  appendColumns: Array<PoTableColumn>;
  appendItems: Array<Append> = [];
  idAppendToBeRemoved: string = '';
  selectedSuppliers: Array<SupplierModel> = [];
  newSuppliers:Array<SupplierModel> = [];
  disclaimersEmail: Array<PoDisclaimer> = [];
  sendEmail: boolean = false;
  sendConditions: boolean = false;
  sendAttach: boolean = false;
  isValidSuppliers: boolean = false;
  isLocked: boolean = false;
  phoneMask: string = '';
  phonePattern: string = '';
  documentPattern: string = '';
  isOpen: Array<any> = []
  disclaimerEmail: string = '';
  filters: Array<PoDisclaimer> = [];
  filteredItems: Array<any> = [];
  items: Array<any> = [];
  quotationItems: any;
  generateQuotationForm: QuotationModel;
  formQuoteData: FormGroup;
  invalidItems: Array<any> = [];
  purchaseRequestsInvalidItems: Array<any> = [];
  requestedProducts: any;
  requestedProductsItems: Array<any> = [];
  supplierStatus: number = 0;
  searchValue: string = '';
  bodyGenerateQuotation: any;
  quotationWorkflow: any;
  tdnLink: string = 'https://tdn.totvs.com/pages/releaseview.action?pageId=680260500';
  structSC8;
  email:string = '';
  name:string = '';
  profileBody;
  user;
  supplierProposal: SupplierModel[];
  areaCodeFieldSize : number = 0;
  telephoneFieldSize : number = 0;
  corporateNameFieldSize : number = 0;
  emailParticipantFieldSize : number = 0;
  emailFieldSize: number = 0;
  optionsConditions: Array<PoMultiselectOption> = [];
  selectedConditions: Array<any> = [];
  obsEmail: string = ""

  @Input() hasNext: boolean = false;
  @ViewChild('supplierDetailsModal', { static: false }) supplierDetailsModal: PoModalComponent;
  @ViewChild('supplierUpdateModal', { static: false }) supplierUpdateModal: PoModalComponent;
  @ViewChild('removeAppendModal', { static: false }) removeAppendModal: PoModalComponent;
  @ViewChild('invalidItemsModal', { static: false }) invalidItemsModal: PoModalComponent;
  @ViewChild('OnlyInvalidItemsModal', { static: false }) OnlyInvalidItemsModal: PoModalComponent;
  @ViewChild('newSupplierPage', { static: true }) newSupplierPage: PoModalComponent;
  @ViewChild('supplierEmailModal', { static: false }) supplierEmailModal: SupplierEmailComponent;

  constructor(
    public poDialog: PoDialogService,
    private supplierService: SupplierService,
    private literalsService: LiteralsService,
    private poNotification: PoNotificationService,
    private purchaseRequestService: PurchaseRequestsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private formatString: HandleStringWithRegex,
    private dateHandler: DateHandler,
    private mingleService: MingleService,
    private utils: Utils,
    private temporaryStorageService: TemporaryStorageService,
    private executionHashService: ExecutionHashService
    ) {
      this.viewPage();

      this.order = [
        { label: this.literals.str0628, value: '0' },
        { label: this.literals.str0629, value: '1' }
      ];


      this.appendColumns = [
        {
          property: 'id',
          type: 'string',
          label: 'ID',
          visible: false,
        },
        {
          property: 'name',
          type: 'string',
          label: this.literals?.str0047,
          visible: true,
        },
        {
          property: 'description',
          type: 'string',
          label: this.literals?.str0048,
          visible: true,
        },
        {
          property: 'append',
          width: '80px',
          type: 'icon',
          label: this.literals?.str0173,
          visible: true,
          icons: [
            {
              action: (value: Append) => {
                this.openRemoveAppendModal(value);
              },
              color: 'color-01',
              icon: 'po-icon-delete',
              tooltip: this.literals?.str0172,
              value: 'true',
            },
          ],
        },
      ];
    }
  ngAfterViewInit(): void {
    this.divArea = true;
  }

  ngOnInit(): void {
    this.isNewUpdateContact = sessionStorage['paramNewUpdateContact'] == 'true';
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);
    this.titlePage = this.suppliersPageViewer ? this.literals?.str0094 : this.literals?.str0596;

    this.quotationItems = this.temporaryStorageService.getValue('quotationItems');
    this.structSC8 = this.temporaryStorageService.getValue('structSC8')?.SC8;
    this.user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '{}';
    
    this.areaCodeFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_DDD');
    this.telephoneFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_TEL');
    this.emailFieldSize = this.utils.getFieldSize('structSA2', 'SA2', 'A2_EMAIL');
    this.emailParticipantFieldSize = this.utils.getFieldSize('structSC8', 'SC8', 'C8_FORMAIL');
    this.corporateNameFieldSize = this.utils.getFieldSize('structSC8', 'SC8', 'C8_FORNOME');

    this.buildForm();
    this.getColums();
    this.getSuppliers();
    this.getLastSuppliers();

    this.searchForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        const valueWithoutEspecialCharacteres = value.searchValue.replace(/,/g, "").replace(/\./g, "").replace(/\//g, "").replace(/\-/g, "");
        this.searchValue = valueWithoutEspecialCharacteres;
        if (this.supplierStatus === 0) {
          if (valueWithoutEspecialCharacteres === "" || valueWithoutEspecialCharacteres === undefined) {
            this.getSuppliers();
          } else {
            this.getSuppliers(valueWithoutEspecialCharacteres);
          }
        } else {
          if (valueWithoutEspecialCharacteres === "" || valueWithoutEspecialCharacteres === undefined) {
            this.getLastSuppliers();
          } else {
            this.getLastSuppliers(valueWithoutEspecialCharacteres);
          }
        }
      });
  }

  // Define o tipo de visualização da seleção de fornecedores de acordo com o parametro mv_selfor
  viewPage(){
    const quotationItemsData = this.temporaryStorageService.getValue('quotationItems');
    this.suppliersPageViewer = quotationItemsData ? quotationItemsData.mv_selfor : null;
  }

  buildForm(): void {
    this.searchForm = this.formBuilder.group({
      searchValue: [''],
    });
    this.supplierForm = this.formBuilder.group({
      telephone: [''],
      store: [''],
      email: [''],
      areacode: [''],
      suppliercode: [''],
      sendEmail: [''],
      sendAttach: [''],
      emails: [],

    });

    this.newSuppliersForm = this.formBuilder.group({
      address: [''],
      areacode: [''],
      branch: [''],
      cnpj: [''],
      companyname: [''],
      countrycode: [''],
      county: [''],
      district: [''],
      email: [''],
      fantasyname: [''],
      state: [''],
      store: [''],
      suppliercode: [''],
      telephone: [''],
      type: [''],
      zipcode: [''],
      selected: true,
      action: [''],
      expirationdate: ['']        
    });
  }

  // Evento Botao novo fornecedor
  newSupplierBtt(){
    this.email= '';
    this.name = '';
    this.newSupplierPage.open();
  }

  // Evento para adicionar novo fornecedora cotação
  addSupplier(){
    const newSupplier = this.newSuppliersForm.get('companyname').value.toUpperCase();
    const exists = this.disclaimers.some(e => e.value === newSupplier.toUpperCase());
    const emailValidated: Object = this.utils.validateEmail(this.newSuppliersForm.get('email').value);
  
    if (exists) {
      this.poNotification.warning(this.literals.str0463);
      this.newSupplierPage.close();
      return;
    }

    if (emailValidated['invalid']) {
      this.poNotification.error(emailValidated['error']);
      return;
    }
  
    this.newSuppliers.push(this.newSuppliersForm.value);
    this.disclaimers.push({
      label: newSupplier.toUpperCase(),
      value: newSupplier,
      hideClose: true
    });
    this.newSupplierPage.close();
    this.isValidSuppliers = true;
  }

  getColums(): void {
    this.columns = this.supplierService.returnColumnsProductGroupsServedTable();
    this.columsInvalidItemsTable = this.supplierService.returnColumnsInvalidItemsTable();
    this.columsPurchaseRequestsItemsTable = this.supplierService.returnColumnsInvalidPurchaseRequestsTable();
  }

  getProductsCode(): void {
    this.requestedProducts = this.temporaryStorageService.getValue('quotationItems');
    this.requestedProducts?.items?.forEach(el => {
      this.requestedProductsItems.push(el.c1_produto);
    });
  }

  changeStatus(tab: number) {
    this.supplierStatus = tab;
    this.searchValue = '';
  }

  getLastSuppliers(searchValue: string = ''): void {
    this.getProductsCode();
    this.loading = true;
    this.lastSuppliersPage = 1;
    this.supplierService
      .getLastSuppliers(this.requestedProductsItems, this.lastSuppliersPage, this.pageSize, searchValue)
      .subscribe((lastSuppliers: any) => {
        this.loading = false;
        this.lastSuppliersList = lastSuppliers.items;
        this.lastSuppliersListFiltered = lastSuppliers.items;
        this.lastSuppliersList?.forEach(el => {
          el.cnpj?.length === 11 ? el.cnpj = this.formatString?.convertStringToCpf(el.cnpj) : el.cnpj = this.formatString?.convertStringToCnpj(el.cnpj);
          el.telephone = this.formatString?.convertStringToPhone(el.telephone);
          el.action = 'true';
        });
        this.lastSuppliersListInitial = lastSuppliers.items;
        this.totalLastSuppliers = this.lastSuppliersList?.length + lastSuppliers.remainingRecords;
      }, (error) =>{
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      });
  }

  getSuppliers(searchValue: string = ''): void {
    this.loading = true;
    this.allSuppliersPage = 1;
    this.supplierService
      .getSuppliers(this.allSuppliersPage, this.pageSize, searchValue)
      .subscribe((suppliers: any) => {
        this.loading = false;
        this.disableButtonAllSuppliers = !suppliers.hasNext;
        this.allSuppliersListFiltered = suppliers.items;
        this.allSuppliersList = suppliers.items;
        this.allSuppliersList?.forEach(el => {
          el.cnpj?.length === 11 ? el.cnpj = this.formatString?.convertStringToCpf(el.cnpj) : el.cnpj = this.formatString?.convertStringToCnpj(el.cnpj);
          el.telephone = this.formatString?.convertStringToPhone(el.telephone);
          el.action = 'true';
        });
        this.allSuppliersListInitial = suppliers.items;
        this.totalSuppliers = this.allSuppliersList?.length + suppliers.remainingRecords;
        }, error => {
          this.loading = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
    })
  }

  orderLastSuppliers(event: string): void {
    this.lastSuppliersList.sort((a, b): any => {
      if (event == '0') {
        if (a.companyname < b.companyname) {
          return -1;
        }
      } else if (event == '1') {
        if (a.companyname > b.companyname) {
          return -1;
        }
      }
    });
  }

  orderAllSuppliers(event: string): void {
    this.allSuppliersList.sort((a, b): any => {
      if (event == '0') {
        if (a.companyname < b.companyname) {
          return -1;
        }
      } else if (event == '1') {
        if (a.companyname > b.companyname) {
          return -1;
        }
      }
    });
  }

  selectSupplier(isSelected, supplier: SupplierModel): void {
    if (isSelected) {
      let isDuplicated = this.selectedSuppliers.some((element) => (supplier.suppliercode + supplier.store) === (element.suppliercode + element.store));
      if (!isDuplicated) {
        supplier.selected = true;
        this.disclaimers.push(
          {
            hideClose: true,
            label: supplier.companyname,
            property: isSelected,
            value: supplier.suppliercode + supplier.store,
          });
          this.selectedSuppliers.push(supplier);
        } else {
          this.poNotification.warning(this.literals?.str0229);
        }
    } else {
      supplier.selected = false;
      supplier.action = 'po-icon po-icon-edit';
      this.disclaimers = this.disclaimers.filter(el => el.value !== supplier.suppliercode + supplier.store);
      this.selectedSuppliers = this.selectedSuppliers.filter(el => el.suppliercode + el.store !== supplier.suppliercode + supplier.store);
    }

    this.isValidSuppliers = this.disclaimers.length > 0;
  }

  showDetails(supplier): void {
    this.supplierDetailsModal?.open();
    this.supplierDetailsToBeShown = supplier;
  }

  primaryActionSupplierDetailsModal: PoModalAction = {
    action: () => {
      this.supplierDetailsModal?.close();
    },
    label: this.literals?.str0092,
  };

  openModalUpdateContacts(supplier): void {

    let data = {
      supplierCode: supplier.suppliercode,
      supplierStore: supplier.store,
      executionHash: this.executionHashService.getExecutionHash()
    }

    if (this.isNewUpdateContact) {
      totvstec.jsToAdvpl("updateContact", JSON.stringify(data));
      this.updateContactClosed()
    }else{
      supplier.areacode = supplier.areacode.trim();
      supplier.telephone = supplier.telephone.trim();
      supplier.email = supplier.email.trim();
      this.supplierForm.patchValue(supplier);
      this.supplierUpdateModal?.open();
    }
    
  }

  //-- Aguarda o fechamento da nova interface.
  updateContactClosed(isNewParticipant: boolean = false): void {
    sessionStorage.setItem('updateContactClosed', undefined);

    this.intervalClosed = interval(1000).subscribe(() => {
      let updateContactClosed: string | null = sessionStorage.getItem('updateContactClosed');
  
      if (updateContactClosed !== 'undefined') {
        updateContactClosed = JSON.parse(updateContactClosed);
        
        if (updateContactClosed['executionHash'] === this.executionHashService.getExecutionHash()) {
          if (updateContactClosed['wasSaved']) {
            this.getSuppliers();
            this.getLastSuppliers();
          }
          
          this.intervalClosed.unsubscribe();
        }
      }
    });
  }

  primaryActionUpdateSupplierModal: PoModalAction = {
    action: () => {
      this.updateContacts();
    },
    label: this.literals?.str0161,
  };

  secondaryActionUpdateSupplierModal: PoModalAction = {
    action: () => {
      this.supplierUpdateModal?.close();
    },
    label: this.literals?.str0092,
  };

  updateContacts(): void {
    const contactToBeUpdated = this.allSuppliersList.find((el) => el.store === this.supplierForm.controls['store'].value && el.suppliercode === this.supplierForm.controls['suppliercode'].value);
    const emailValidated: Object = this.utils.validateEmail(this.supplierForm.get('email').value);

    if (!emailValidated['invalid']) {
      const index = this.allSuppliersList.indexOf(contactToBeUpdated);

      this.loadingModalUpdateSupplier = true;
      this.supplierService.updateContact(this.supplierForm.value)
      .subscribe(response => {
        this.loadingModalUpdateSupplier = false;

        contactToBeUpdated.telephone = response?.telephone;
        contactToBeUpdated.email = response?.email;
        contactToBeUpdated.areacode = response?.areacode;
        this.allSuppliersList.splice(index, 1, contactToBeUpdated);

        this.poNotification.success(response.message);
        this.supplierUpdateModal?.close();
        }, error => {
          this.loadingModalUpdateSupplier = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        });
    } else {
      this.poNotification.error(emailValidated['error']);
    }
  }

  loadButton() {
    this.loading = true;
    switch (this.supplierStatus) {
      case 0:
        this.supplierService
        .getSuppliers(++this.allSuppliersPage, this.pageSize, this.searchValue)
        .subscribe((suppliers: any) => {
          this.loading = false;
          this.allSuppliersList = this.allSuppliersList.concat(suppliers.items);
          this.allSuppliersList?.forEach(el => {
            el.cnpj?.length === 11 ? el.cnpj = this.formatString?.convertStringToCpf(el.cnpj) : el.cnpj = this.formatString?.convertStringToCnpj(el.cnpj);
            el.telephone = this.formatString?.convertStringToPhone(el.telephone);
          });
          if (suppliers.hasNext == false) {
            this.disableButtonAllSuppliers = true;
          }
        }, (error) =>{
          this.loading = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        });
        break;
      case 1:
        this.supplierService
        .getLastSuppliers(this.requestedProductsItems, ++this.lastSuppliersPage, this.pageSize, this.searchValue)
        .subscribe((lastSuppliers: any) => {
          this.loading = false;
          this.lastSuppliersList = this.lastSuppliersList.concat(lastSuppliers.items);
          this.lastSuppliersList?.forEach(el => {
            el.cnpj?.length === 11 ? el.cnpj = this.formatString?.convertStringToCpf(el.cnpj) : el.cnpj = this.formatString?.convertStringToCnpj(el.cnpj);
            el.telephone = this.formatString?.convertStringToPhone(el.telephone);
          });
          if (lastSuppliers.hasNext == false) {
            this.disableButtonLastSuppliers = true;
          }
        }, (error) =>{
          this.loading = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        });
        break;
    }
  }

  navigateBack(): void {
    this.router.navigate(['/necessidade-de-compra/solicitacao-de-cotacao']);
  }

  cancel(): void {
    this.poDialog.confirm({
      title: this.literals.str0228,
      message: this.literals.str0127,
      literals: { cancel: this.literals?.str0100, confirm: this.literals?.str0099 },
      cancel: () => {  },
      confirm: () => this.cancelQuotation()
    });
  }

  cancelQuotation(): void {
    this.purchaseRequestService.clearQuotationFromLocalStorage();
    this.poNotification.warning(this.literals?.str0227);
    this.router.navigate(['/necessidade-de-compra']);
  }

  generateQuotation(): void {
    this.selectedSuppliers.forEach(element => {
      element.expirationdate = '';
    });

    this.newSuppliers.forEach(element => {
      element.companyname = element.companyname.toUpperCase();
    });
    
    this.supplierProposal = [...this.selectedSuppliers, ...this.newSuppliers];
    this.supplierProposal.forEach((element)=>{
      element.email = element.email.trim();
      if(element.emaillist == undefined || element.emaillist == ""){
        element['emaillist'] = element.email
      }
    })

    this.temporaryStorageService.setValue('proposals', this.supplierProposal);
    this.buildGenerateQuotationForm();
    this.generateQuotationConfirmDialog();
  }

  buildGenerateQuotationForm(): void {
    const proposals = this.temporaryStorageService.getValue('proposals');
    this.quotationItems.items.forEach((element, index) => {
      
      if (!element?.proposals?.length) {
        element.proposals = proposals;
      }
      element.c1_datprf = this.dateHandler.convertStringToProtheusDate(element.c1_datprf);
      element.email = element?.email?.trim();
      
      this.quotationItems.items[index] = this.utils.removeInvalidCharactersFromObject(element);
    });
    this.bodyGenerateQuotation = this.temporaryStorageService.getValue('formQuoteData');
    this.bodyGenerateQuotation = this.utils.removeInvalidCharactersFromObject(this.bodyGenerateQuotation);

    this.bodyGenerateQuotation.deliveryAddress = '';
    this.bodyGenerateQuotation.items = this.quotationItems.items;
  }

  generateQuotationConfirmDialog() {
    this.poDialog.confirm({
      title: this.literals?.str0065,
      message: this.literals?.str0159,
      confirm: () => this.showInvalidItems()
    });
  }

  showInvalidItems(): void {
    let hasValidItem: boolean = false;
    this.loadingGenerateQuotationModal = true;
    this.purchaseRequestsInvalidItems = [];
    this.isLocked = false;
    this.purchaseRequestService.alreadyExistsQuotation(this.bodyGenerateQuotation)
      .subscribe((response) => {
        this.bodyGenerateQuotation = response;
        const hasInvalidItem = response.items.find(item => item.hasinvaliditem);

        response.items.forEach(element => {
            const purchaseItem = element.purchaserequests.find(el=> el.isopen === true);
            if (purchaseItem) {
              hasValidItem = true;
            }
          }
        );

        if (hasInvalidItem && hasValidItem) {
          response.items.forEach(el => {
            if (el.hasinvaliditem) {
              el.c1_datprf = this.dateHandler.fromProtheusDateToStringPoUi(el.c1_datprf);
              this.getPurchaseRequestsInvalidItems(el);
            }
          });
          this.invalidItemsModal?.open();
        } else if (hasInvalidItem && !hasValidItem){
          response.items.forEach(el => {
            if (el.hasinvaliditem) {
              el.c1_datprf = this.dateHandler.fromProtheusDateToStringPoUi(el.c1_datprf);
              this.getPurchaseRequestsInvalidItems(el);
            }
          });
          this.OnlyInvalidItemsModal?.open();
        } else {
          this.createQuotationRequest();
        }
      }, error => {
        this.loadingGenerateQuotationModal = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      });
  }

  createQuotationRequest(): void {
    this.loadingGenerateQuotationModal = true;
    
    if (!this.bodyGenerateQuotation) {
      this.bodyGenerateQuotation = {};
    }
    this.bodyGenerateQuotation.numberSuppliers = this.disclaimers.length ? this.disclaimers.length : this.numberOfSuppliers; //-- Adiciona quantidade de fornecedores da cotação

    this.purchaseRequestService.generateQuotation(this.bodyGenerateQuotation)
      .subscribe((response) => {
        this.loadingGenerateQuotationModal = false;
        this.poNotification.success(response.message);
        this.quotationNumber = response.quotationCode;
        this.temporaryStorageService.setValue('quotationCode', response.quotationCode);
        this.supplierEmailModal.open();
      }, error => {
        this.loadingGenerateQuotationModal = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      });
  }


  confirmInvalidItemsModalAction: PoModalAction = {
    action: () => {
      this.invalidItemsModal?.close();
      this.createQuotationRequest();
    },
    label: this.literals?.str0099,
  };

  closeInvalidItemsModalAction: PoModalAction = {
    action: () => {
      this.closeInvalidModal(false, false);
    },
    label: this.literals?.str0100,
  };

  closeOnlyInvalidItemsModalAction: PoModalAction = {
    action: () => {
      this.closeInvalidModal(true, false);
    },
    label: this.literals?.str0228,

  };

  //-- Ação de fechar o modal de itens inválidos
  closeInvalidModal(onlyInvalid: boolean, navigateBack: boolean) {
    this.invalidItems = [];
    this.purchaseRequestsInvalidItems = [];
    this.loadingGenerateQuotationModal = false;

    if (onlyInvalid) { //-- Apenas itens inválidos
      this.OnlyInvalidItemsModal.close();
    } else {
      this.invalidItemsModal.close();
    }

    if (navigateBack) { //-- Nevaga para necessidade de compra
      this.router.navigate(['/necessidade-de-compra']);
      this.poNotification.warning(this.literals?.str0227);
    }
  }

  //-- Obtém itens inválidos
  getPurchaseRequestsInvalidItems(item: any): void {
    item.purchaserequests.forEach(el => {
      if (!el.isopen) {
        if (el?.errormessage?.includes('ISLOCKED')) { //-- Verificar se possui itens bloqueados
          this.isLocked = true;
        }
        this.purchaseRequestsInvalidItems.push(el);
      }
    });

    if (this.isLocked) {
      this.OnlyInvalidItemsModal.secondaryAction = {
        action: () => this.closeInvalidModal(true, true), //-- Ação de fechar o modal e voltar para necessidade de compra
        label: this.literals?.str0228
      };
      this.closeOnlyInvalidItemsModalAction.label = this.literals?.str0647; //-- Tentar novamente
      this.closeOnlyInvalidItemsModalAction.action = () => this.tryAgainInvalidItem(); //-- Ação de tentar novamente
    } else {
      this.OnlyInvalidItemsModal.secondaryAction = undefined;
      this.closeOnlyInvalidItemsModalAction.label = this.literals?.str0228; //-- Ação para cancelar a geração da cotação
      this.closeOnlyInvalidItemsModalAction.action = () => this.closeInvalidModal(true, true);
    }
  }

  //-- Ação tentar novamente para itens inválidos
  tryAgainInvalidItem() {
    this.loadingGenerateQuotationModal = false;
    this.OnlyInvalidItemsModal?.close();
    this.generateQuotation();
  }

  convertToCNPJCPFPattern(value: string): void {

    if(value.length === 11) {
      this.supplierForm.controls['cnpj'].setValue(value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'));
    } else {
      this.supplierForm.controls['cnpj'].setValue(value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'));
    }

  }

  convertStringToPhone(value: string): void {
    value = value['telephone'].replace(/\D/g, '')
    switch (value.length) {
      case 8:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{4})(\d{4})/, '$1-$2'));
        break;
      case 9:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{5})(\d{4})/, '$1-$2'));
        break;
      case 10:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'));
        break;
      case 11:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{3})(\d{4})(\d{4})/, '($1) $2-$3'));
        break;
      case 12:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{3})(\d{5})(\d{4})/, '($1) $2-$3'));
        break;
      case 13:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{2})(\d{3})(\d{4})(\d{4})/, '+$1 ($2) $3-$4'));
        break;
      case 14:
        this.supplierForm.controls['telephone'].setValue(value.replace(/^(\d{2})(\d{3})(\d{5})(\d{4})/, '+$1 ($2) $3-$4'));
        break;
    }

  }

  openRemoveAppendModal(value: Append): void {
    this.idAppendToBeRemoved = value.id;
    this.removeAppendModal?.open();
  }

  getAppendList(): void {}

  closeRemoveAppendModalAction: PoModalAction = {
    action: () => {
      this.closeRemoveAppendModal();
    },
    label: this.literals?.str0092,
    danger: true
  };

  confirmRemoveAppend: PoModalAction = {
    action: () => {
      this.removeApend(this.idAppendToBeRemoved);
    },
    label: this.literals?.str0161
  };

  closeRemoveAppendModal(): void {
    this.removeAppendModal?.close();
  }

  removeApend(id: string): void {
    this.poNotification.warning(`ID a ser removido: ${id}` );
    this.closeRemoveAppendModal();
  }

  selectionStatus(event: Object): void {
    if (event.hasOwnProperty('allSelected')) {
      this.isValidSuppliers = event['allSelected'];
    }
    if (event.hasOwnProperty('numberOfSelected')) {
      this.numberOfSuppliers = event['numberOfSelected'];
    }
    if (event.hasOwnProperty('selectedSuppliers')) {
      this.selectedSuppliers = event['selectedSuppliers'];
    }
    if (event.hasOwnProperty('items')) { //-- Atualiza sessionStorage
      this.quotationItems.items = event['items'];
      this.temporaryStorageService.setValue('quotationItems', this.quotationItems);
    }
  }

}
