import { DateHandler } from 'src/app/shared/helper/date-handler';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PurchaseRequestsService } from './../../shared/services/purchase-requests.service';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { PoModalAction, PoModalComponent, PoTableColumn } from '@po-ui/ng-components';
import { CompanyInfoService } from 'src/app/shared/services/company-info.service';
import { HandleStringWithRegex } from 'src/app/shared/utils/handleStringWithRegex';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
  selector: 'tgc-quote-data',
  templateUrl: './quote-data.component.html',
  styleUrls: ['./quote-data.component.css']
})
export class QuoteDataComponent implements OnInit {

  items = [];
  invalidItems: Array<any>;
  form: FormGroup;
  lengthNickname: number = 0;
  minReceiptDate: string = '';
  deliveryaddress: string = '';

  literals: LiteralsWords = this.literalsService.getLiterals();

  columnsInvalidItemsGrid: Array<PoTableColumn>;

  @Input() formDataReceived: FormGroup;

  @Output() formDataSent = new EventEmitter();

  @Input() quotationItems: any;

  @ViewChild('invalidItemsModal', { static: false }) invalidItemsModal: PoModalComponent;

  constructor(
    private formBuilder: FormBuilder,
    private literalsService: LiteralsService,
    private purchaseRequestsService: PurchaseRequestsService,
    private dateHandler: DateHandler,
    private companyInfoService : CompanyInfoService,
    private formatString: HandleStringWithRegex,
    private temporaryStorageService: TemporaryStorageService) {
      this.form = this.formBuilder.group({
        deliveryplace:[''],
  
      });
    }

  ngOnInit(): void {

    this.getLengthNickname()

    this.buildForm();

    this.getFormQuoteDataFromLocalStorage();

    this.getQuotationsFromLocalStorage();

    this.columnsInvalidItemsGrid = this.purchaseRequestsService.returnColumsFromInvalidItemsTable();

    this.form?.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((value: string) => {
        if(value) {
          this.formDataSent.emit(this.form);
          this.purchaseRequestsService.isQuoteDataFormValid(this.form.valid);
        }
      });

    const currentDate = this.dateHandler.getCurrentDate();
    this.minReceiptDate = this.dateHandler.fromProtheusToPOUIString(currentDate);
    
    this.getCompany();

  }

  getFormQuoteDataFromLocalStorage(): void {
    let formQuoteData = this.temporaryStorageService.getValue('formQuoteData');
    if (formQuoteData) {
      formQuoteData.receiptQuote = this.dateHandler.fromProtheusToPOUI(formQuoteData.receiptQuote);
      this.form.patchValue(formQuoteData);
    }
  }

  getQuotationsFromLocalStorage(): void {
    let quotationItems = this.temporaryStorageService.getValue('quotationItems');
    if (quotationItems) this.invalidItems = quotationItems.invaliditems;
  }

  getLengthNickname(){
    let res = this.temporaryStorageService.getValue('fieldsPropertiesDHU');
    if (res && res.items[0].length) {
      this.lengthNickname = res.items[0].length;
    }
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      quoteGroupNickname: ['', [ Validators.required , Validators.maxLength(this.lengthNickname)]],
      receiptQuote: ['', Validators.required],
      fisicalplacedelivery: ['', Validators.required],
      status: ['1', Validators.required],
      emissionDate: '',
    });
  }

  sendStatusForm(): void {
    this.purchaseRequestsService.isQuoteDataFormValid(this.form.valid);
  }

  showInvalidItemsQuotation(): void {
    this.invalidItemsModal.open();
  }

  primaryActionInvalidItemsModal: PoModalAction = {
    action: () => {
      this.invalidItemsModal.close();
    },
    label: this.literals?.str0092,
  };

  getCompany(){

    this.companyInfoService.getCompanyInfo().subscribe({
      next: (value) => {
        const cnpj: string = value.items[0].m0_cgc ? value.items[0].m0_cgc : this.literals.str0122; //-- Sem informação
        const socialReason: string = value.items[0].m0_nomecom ? value.items[0].m0_nomecom : this.literals.str0122;
        const deliveryAddress: string = `${value.items[0].m0_endent} - ${value.items[0].m0_bairent} - ${value.items[0].m0_cident}/${value.items[0].m0_estent}`;
        const zipCode: string = value.items[0].m0_cepent;
        this.deliveryaddress =  this.literals?.str0073.toUpperCase() + ": " + socialReason + " - " + this.literals?.str0072 + ": " + this.formatString.convertStringToCnpj(cnpj) + " - " + 
                                this.literals?.str0074 + ": " + deliveryAddress + " - " + this.literals?.str0155.toUpperCase() +": " + zipCode.replace(/([0-9]{5})([\d]{3})/, '$1-$2' );
      }, error: (error) => {
        this.deliveryaddress = '';
      }
    });
  }


  changeAddress( event: any): void {
    let quotationItems = this.temporaryStorageService.getValue('quotationItems');
    quotationItems['deliveryplace'] = event;
    
  }

}
