import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';
import { MingleService } from '@totvs/mingle';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class AttachmentDocumentsService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private mingleService: MingleService,
    ) { }

  getAttachmentsList(page: number, pageSize: number,quotationCode: string): Observable<any> {
    let response;
    const options = {
      "page":  page.toString(),
      "pageSize": pageSize.toString(),
    };
    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.get(`${environment.url}/pgcGenerateQuotation/documentsList/${quotationCode}`, null, options);
      return response;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcGenerateQuotation/documentsList/${quotationCode}`, {params:options});
    }
  }

  getAttachmentsListBySupplier(quotationCode: string, supplierCode: string, supplierStore: string, corporatename?:string): Observable<any> {
    let response
    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.get(`${environment.url}/pgcGenerateQuotation/documentsListBySupplier/${quotationCode}/${supplierCode}/${supplierStore}/${encodeURIComponent(corporatename)}`);
      return response;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcGenerateQuotation/documentsListBySupplier/${quotationCode}/${supplierCode}/${supplierStore}/${encodeURIComponent(corporatename)}`);
    }
  }

  getAttachmentById(id: string): Observable<any> {
    let response
    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.get(`${environment.url}/pgcGenerateQuotation/document/${id}`)
      return response;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcGenerateQuotation/document/${id}`);
    }
  }

  postAttachment(body: any): Observable<any> {
    let response
    const options = { "body": body };

    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.post(`${environment.url}/pgcGenerateQuotation/document`, options)
      return response;
    } else {
      return this.http.post(`${environmentProtheus.url}/pgcGenerateQuotation/document`, body);
    }
  }

  deleteAttachment(body: any): Observable<any> {
    let response;
    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.delete(`${environment.url}/pgcGenerateQuotation/document`, {headers: {body: JSON.stringify(body)}})
      return response;
    } else {
      const options  = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        body
      };
      return this.http.delete(`${environmentProtheus.url}/pgcGenerateQuotation/document`, options);
    }
  }

  downloadAttachment(idDocument: string): Observable<any> {
    let response
    if (!this.loginService.isProtheusLogged()) {
      response  = this.mingleService.gateway.get(`${environment.url}/pgcGenerateQuotation/document/${idDocument}`)
      return response;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcGenerateQuotation/document/${idDocument}`);
    }
  }

}
