// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d-block {
  display: block;
}

.text {
  display: block;
}
.po-icon{
  display: block;
  align-items:center;
  margin-top: 46px;
}

.warning {
  color: #be3e37;
}
`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/quote-request/quote-data/quote-data.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".d-block {\n  display: block;\n}\n\n.text {\n  display: block;\n}\n.po-icon{\n  display: block;\n  align-items:center;\n  margin-top: 46px;\n}\n\n.warning {\n  color: #be3e37;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
