import { Injectable } from '@angular/core';
import { MingleService } from '@totvs/mingle';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { pluck } from 'rxjs/operators';
import { Utils } from '../../../../shared/helper/utils';
import { environment } from 'src/environments/environments';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environmentProtheus } from 'src/environments/environment.protheus';
import { LoginService } from 'src/app/shared/services/login.service';

@Injectable({
    providedIn: 'root'
})

export class MenuService {

    constructor(private mingleService: MingleService,
      private utils: Utils,
      private loginService: LoginService,
      private http: HttpClient,) { }

    behaviorLoading = new BehaviorSubject<boolean>(false);

    loading(value: boolean): void {
      this.behaviorLoading.next(value);
    }

    getGroups(): Observable<any> {
      let observable: any;

      const defaultHeaders = this.utils.getHeaders(true);
      this.mingleService.setDefaultHeaders(defaultHeaders);

      observable = this.mingleService.gateway.get('api/framework/environment/v1/companies');

      return observable.pipe(pluck('items'));
    }

    getBranches(group: string ) : Observable<any> {
      let observable: any;

      const options ={
        "EnterpriseGroup":  group,
        "pageSize": 10000
      };

      const defaultHeaders = this.utils.getHeaders(true);
      this.mingleService.setDefaultHeaders(defaultHeaders);

      observable = this.mingleService.gateway.get('api/framework/environment/v1/branches', null, options);
      return observable.pipe(pluck('items'));
    }

    postSendMetric(route: string): Observable<any> {
      let body: any;
      let idMetric: string;
      let response: any;
      let hasMetric: boolean = true;

      if (route === 'necessidade-de-compra') {
        idMetric = 'total-de-acessos-mingle-necessidade-de-compras'
      } else if (route === 'cotacoes') {
        idMetric = 'total-de-acessos-mingle-cotacoes'
      } else if (route === 'pedido-de-compra') {
        idMetric = 'total-de-acessos-mingle-pedido-de-compras'
      } else {
        hasMetric = false;
      }

      if (hasMetric) {
        body = {idMetric: idMetric, value: 1}
        response = this.mingleService.gateway.post(`${environment.url}/pgcUtils/sendMetric`, {'body': body});
        return response;
      } else {
        return of({}); //-- Retorna observable vazio
      }
    }


    postMultiProtocol(): Observable<any> {
      let response: any;
      let body: any;

      body = {};
      if (!this.loginService.isProtheusLogged()) {
        response = this.mingleService.gateway.post(`${environment.url}/pgcUtils/verifyMultiProt`, {'body': body});
        return response;
      } else {
        return this.http.post(`${environmentProtheus.url}/pgcUtils/verifyMultiProt`, body);
      }

    }


    postSaveData(publicIP: string, portIP: string, paramBrw: string, environmentSrv: string, paramWfDir: String, addressSMTP: string,
      portSMTP: number, addressPopImap: string, portPopImap: number, protocolType: string): Observable<any> {
      let response: any;
      let body: any;

      body = {publicIP: publicIP, portIP: portIP, paramBrw: paramBrw, environmentSrv: environmentSrv, paramWfDir: paramWfDir,
        addressSMTP: addressSMTP, portSMTP: portSMTP, addressPopImap: addressPopImap, portPopImap: portPopImap, protocolType: protocolType}
      
      if (!this.loginService.isProtheusLogged()) {  
        response = this.mingleService.gateway.post(`${environment.url}/pgcUtils/postWizardParam`, {'body': body});
        return response;
      } else {
        return this.http.post(`${environmentProtheus.url}/pgcUtils/postWizardParam`, body);
      }
      
    }


    postSaveEmail( authenticationEmail: string, authenticationPsw: string, receiveServer: string, receivePort: number, senderName: string, 
      senderEmail: string, senderServer: string, senderPort: number, postalname: string): Observable<any> {
      let response: any;
      let body: any;
   
      body = { authenticationEmail: authenticationEmail, authenticationPsw: authenticationPsw, 
        receiveServer: receiveServer, receivePort: receivePort, senderName: senderName, 
        senderEmail: senderEmail, senderServer: senderServer, senderPort: senderPort, postalname:postalname}
      
      if (!this.loginService.isProtheusLogged()) {
        response = this.mingleService.gateway.post(`${environment.url}/pgcUtils/postWizardEmail`, {'body': body});
        return response;
      } else {
        return this.http.post(`${environmentProtheus.url}/pgcUtils/postWizardEmail`, body);
      }
    }

    // -- API de migração de cotações legadas
    postMigrateLegacyQuote( initialDate: string = '', endDate: string = '', notificationEmail: string = ''): Observable<any> {
      let response: any;
      let body: any;
   
      body = { initialdate: initialDate, enddate: endDate, email: notificationEmail}
      
      if (!this.loginService.isProtheusLogged()) {
        response = this.mingleService.gateway.post(`${environment.url}/pgcUtils/migrateLegacyQuote`, {'body': body});
        return response;
      } else {
        return this.http.post(`${environmentProtheus.url}/pgcUtils/migrateLegacyQuote`, body);
      }
    }



    getEmailBox(emailBox): Observable<any> {
      let response: any;
      let params = new HttpParams()
      .append('page', '1')
      .append('pageSize', '1')
      .append('wf7_pasta', `'${emailBox}'`)
      .append('FIELDS', `wf7_pasta,wf7_endere,wf7_conta,wf7_autusu`)

      if (!this.loginService.isProtheusLogged()) {
        response = this.mingleService.gateway.get(`${environment.urlFrame}/genericList?alias=WF7&${params.toString()}`);
        return response;
      } else {
        return this.http.get(`${environmentProtheus.urlFrame}/genericList?alias=WF7&${params.toString()}`);
      }
    }
}
