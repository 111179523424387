import { Injectable, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MingleService } from '@totvs/mingle';
import { LoginService } from 'src/app/shared/services/login.service';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService{

  constructor(
    private http: HttpClient,
    private mingleService: MingleService,
    private loginService: LoginService,
    ) {}

  getCompanyInfo(): Observable<any> {
    let observable: any;
  
    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.get((`${environment.url}/pgcUtils/companyInfo`));
      return observable;
    } else {             
      return this.http.get(`${environmentProtheus.url}/pgcUtils/companyInfo`);
    }
  }

}
