import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { HomeComponent } from './core/home/home.component';
import { LoginComponent } from './core/home/login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
   },
  {
    path: '', loadChildren: () =>
      import('./core/menu/menu.module').then(m => m.MenuModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
