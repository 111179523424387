import { PoLookupColumn } from "@po-ui/ng-components";

export class PgcLookupHelper {

  //-- Converte colunas do tipo PoLookupColumn em uma string para realizar filtro de um valor
  convertColumnsToQueryString(columns: Array<PoLookupColumn>, value: string): string {
    let filter: string = ''

    if (value) {
      columns.forEach((element, index) => {
        filter += index === 0 ? '(' : ''
        filter += `contains(toupper(${element.property}),'${value.toUpperCase()}')`;

        if (index + 1 != columns.length) {
          filter += ' or ';
        }

        filter += index + 1 === columns.length ? ')' : ''
      });
    }

    return filter;
  }
}
