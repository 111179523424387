// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-date{
  width: 238px;
}

.search-lookups{
  display: flex;
}

.lookup-input{  
  width: 260px;

}

.buttons {
  justify-content: flex-end;
  margin-top: 50px;
}

.buttons > po-button {
  margin-right: 8px;
}

.exclamation {
  margin-top: 23px;
}

.switch {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/shared/components/search/search.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;;AAEd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".search-date{\n  width: 238px;\n}\n\n.search-lookups{\n  display: flex;\n}\n\n.lookup-input{  \n  width: 260px;\n\n}\n\n.buttons {\n  justify-content: flex-end;\n  margin-top: 50px;\n}\n\n.buttons > po-button {\n  margin-right: 8px;\n}\n\n.exclamation {\n  margin-top: 23px;\n}\n\n.switch {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
