/* tslint:disable */
// TOTVS TypeScript translaton resource
// Reference date : 20240910

export const literalsEsES = {
  str0001: "Módulo de compras",
  str0002: "Necesidad de compra",
  str0003: "Inicio",
  str0004: "Cotizaciones",
  str0005: "Pedido de compra",
  str0006: "Historial de compras",
  str0007: "Indicadores",
  str0008: "Pendiente",
  str0009: "Parcialmente atendidas",
  str0010: "Atendidas",
  str0011: "Seleccione el período",
  str0012: "Busque por código de producto",
  str0013: "Búsqueda avanzada",
  str0014: "Salir",
  str0015: "Solicitud",
  str0016: "Ítem solicitud",
  str0017: "Código del producto",
  str0018: "Descripción del producto",
  str0019: "Fecha Necesidad",
  str0020: "Unidad de medida",
  str0021: "Cantidad",
  str0022: "Observaciones",
  str0023: "Archivos adjuntos",
  str0024: "Solicitante",
  str0025: "Sucursal solicitante",
  str0026: "Sucursal Entrega",
  str0027: "Centro de costo",
  str0028: "Cuenta contable",
  str0029: "Ítem contable",
  str0030: "Clase valor",
  str0031: "Agrupar solicitudes",
  str0032: "Iniciar cotización",
  str0033: "Incluya el código de la solicitud",
  str0034: "Incluya el solicitante",
  str0035: "Incluya la descripción del producto",
  str0036: "Incluya la sucursal solicitante",
  str0037: "Incluya la sucursal de entrega",
  str0038: "Incluya el centro de costo",
  str0039: "Incluya la cuenta contable",
  str0040: "Incluya el ítem contable",
  str0041: "Incluya la clase valor",
  str0042: "Limpiar filtros",
  str0043: "Aplicar filtros",
  str0044: "Cotizaciones",
  str0045: "¿Realmente desea salir de la aplicación?",
  str0046: "Lista de archivos adjuntos",
  str0047: "Nombre",
  str0048: "Descripción",
  str0049: "Archivo adjunto",
  str0050: "Haga clic para mostrar lista de archivos adjuntos",
  str0051: "Incluya su usuario",
  str0052: "Incluya su contraseña",
  str0053: "Informe el alias deseado",
  str0054: "Mis rutinas",
  str0055: "Conozca la rutinas",
  str0056: "Pedido de compras",
  str0057: "Historial de compras",
  str0058: "Indicadores",
  str0059: "Solicitudes de compras.",
  str0060: "Rutina para generación de cotizaciones con base en las solicitudes de compra registradas. Obtenga información de solicitudes pendientes, parcialmente atendidas y atendidas.",
  str0061: "Permite la actualización, borrado y análisis de cotizaciones, considerando la mejor propuesta entre los proveedores, de acuerdo con el precio, condiciones de pago y plazo de entrega.",
  str0062: "Permite el mantenimiento de Pedido de compras generados a partir de las necesidades de la empresa, representando un documento de formalización de la compra entre la empresa y el proveedor.",
  str0063: "Gestión de compras",
  str0064: "Acceder",
  str0065: "Solicitud de cotización",
  str0066: "Datos de la cotización",
  str0067: "Grupo cotizaciones",
  str0068: "Alias del grupo de cotizaciones",
  str0069: "Recepción cotización",
  str0070: "Cantidad proveedores",
  str0071: "Información de entrega",
  str0072: "RCPJ",
  str0073: "Razón social",
  str0074: "Dirección de entrega",
  str0075: "Adjuntar archivos",
  str0076: "Solamente archivos XML, PDF, Otcx.",
  str0077: "Límite de tamaño por archivo: hasta 30 MB.",
  str0078: "Productos solicitados",
  str0079: "Orden",
  str0080: "Solicitudes agrupadas",
  str0081: "Esta información no se envía al proveedor.",
  str0082: "Código del producto",
  str0083: "Descripción del producto",
  str0084: "Unidad de medida",
  str0085: "Cant. Total",
  str0086: "Observación para el proveedor",
  str0087: "Registro seleccionado:",
  str0088: "Registros seleccionados:",
  str0089: "Registro(s)",
  str0090: "Registros",
  str0091: "No hay productos solicitados",
  str0092: "Cerrar",
  str0093: "Volver a la agrupación de solicitudes",
  str0094: "Selección de Proveedores / Participantes",
  str0095: "Próximo paso",
  str0096: "Nuevo proveedor",
  str0097: "Hola,",
  str0098: "¡Logout realizado con éxito!",
  str0099: "Sí",
  str0100: "No",
  str0101: "Anular",
  str0102: "Proveedores / Participantes seleccionados",
  str0103: "Aún no seleccionó proveedores.",
  str0104: "Los últimos proveedores se listan según las últimas facturas de compras encontradas para los productos de esta cotización.",
  str0105: "Sugerencia de proveedor",
  str0106: "Últimos proveedores",
  str0107: "Todos los proveedores",
  str0108: "Busque por RCPJ, nombre fantasía o razón social...",
  str0109: "Mostrando",
  str0110: "de",
  str0111: "sugerencia de proveedor",
  str0112: "sugerencia de proveedores",
  str0113: "Código proveedor",
  str0114: "Tienda",
  str0115: "E-mail",
  str0116: "Teléfono",
  str0117: "Grupos de productos atendidos",
  str0118: "grupo de productos",
  str0119: "Código del grupo",
  str0120: "Descripción del grupo",
  str0121: "Productos registrados",
  str0122: "Sin información",
  str0123: "Mostrar detalles",
  str0124: "Actualizar contactos",
  str0125: "Detalles del proveedor",
  str0126: "Filtro sin resultados",
  str0127: "¿Realmente desea anular esta operación?",
  str0128: "Proveedores seleccionados",
  str0129: "Cambiar de sucursal",
  str0130: "RCPF",
  str0131: "Cargar más resultados",
  str0132: "Digite el código del grupo",
  str0133: "Grupo",
  str0134: "Seleccione el grupo de sucursales deseado.",
  str0135: "Sucursal",
  str0136: "Seleccione la sucursal deseada.",
  str0137: "Cambio de sucursal",
  str0138: "Estos son todos los proveedores registrados en su base.",
  str0139: "Ninguna sucursal seleccionada.",
  str0140: "Número de proveedores seleccionados mayor que el máximo permitido.",
  str0141: "Generar cotización",
  str0142: "(Obligatorio)",
  str0143: "Número de caracteres no válido",
  str0144: "Eliminar",
  str0145: "Digite el alias del grupo de esta cotización",
  str0146: "Seleccione el plazo máximo para la recepción de cotizaciones de los proveedores.",
  str0147: "Valor no válido",
  str0148: "Cantidad de proveedores que participarán de la cotización.",
  str0149: "Bajar archivo adjunto",
  str0150: "Eliminar ítem de la lista",
  str0151: "Ítems seleccionados",
  str0152: "Proveedor",
  str0153: "Dirección",
  str0154: "Barrio",
  str0155: "CP",
  str0156: "Nombre fantasía",
  str0157: "Municipio",
  str0158: "Est/Prov/Reg",
  str0159: "¿Confirma la creación de la cotización de compra?",
  str0160: "Finalizando la solicitud de la cotización",
  str0161: "Confirmar",
  str0162: "Proveedores seleccionados para el proceso:",
  str0163: "País",
  str0164: "Formato no válido",
  str0165: "¿Enviar cotización por e-mail al(a los) proveedor(es)?",
  str0166: "¿Enviar archivos adjuntos al proveedor?",
  str0167: "Más detalles",
  str0168: "Gráficos",
  str0169: "SC vs. PC",
  str0170: "Visión",
  str0171: "Compras",
  str0172: "Borrar archivo adjunto",
  str0173: "Acciones",
  str0174: "¿Realmente desea borrar este archivo adjunto?",
  str0175: "DDN",
  str0176: "E-mail principal",
  str0177: "Tipo de documento",
  str0178: "¿Evalúa propuesta vencida?",
  str0179: "Seleccione el tipo de documento",
  str0180: "Pedido",
  str0181: "Contrato",
  str0182: "Seleccione si evalúa la propuesta vencida",
  str0183: "Envío de e-mails y documentos adjuntos",
  str0184: "¿Desea anular el envío de e-mails y documentos adjuntos?",
  str0185: "¡Proceso finalizado con éxito!",
  str0186: "¡Existe una cotización pendiente para esta solicitud de compra!",
  str0187: "Arrastre un archivo o haga clic aquí para seleccionar",
  str0188: "Máximo de 10MB por archivo",
  str0189: "Contraseña y/o usuario no válido, verifique e intente nuevamente.",
  str0190: "En ejecución",
  str0191: "Falló",
  str0192: "Finalizado",
  str0193: "Borrar documento adjunto",
  str0194: "¿Desea borrar el documento adjunto '{0}'? Esta acción es irreversible.",
  str0195: "Borrando documento adjunto...",
  str0196: "¡El documento adjunto '{0}' fue borrado con éxito!",
  str0197: "¡El formato del archivo '{0}' no fue aceptado!",
  str0198: "¡El tamaño del archivo '{0}' es superior a 10MB!",
  str0199: "En la cola",
  str0200: "Los filtros se aplican solamente en la visualización. Todos los ítems seleccionados se mantendrán.",
  str0201: "Filtros aplicados",
  str0202: "Ver filtros",
  str0203: "Cotizaciones solicitadas",
  str0204: "Cotización",
  str0205: "Alias",
  str0206: "Prazo médio de entrega",
  str0207: "Estatus",
  str0208: "Asunto pendiente",
  str0209: "Listo para analizar",
  str0210: "En análisis",
  str0211: "Finalizada",
  str0212: "Borrada",
  str0213: "Editar",
  str0214: "Analizar",
  str0215: "Continuar análisis",
  str0216: "Visualizar",
  str0217: "cotizaciones",
  str0218: "Borrar cotización",
  str0219: "Reabrir análisis",
  str0220: "Busque por número o sobrenombre de la cotización",
  str0221: "¡Opción del menú aún en construcción!",
  str0222: "Encontramos ítems no válidos",
  str0223: "Mensaje de error",
  str0224: "No será posible iniciar una cotización",
  str0225: "Ítems no válidos no se considerarán en la generación de la cotización.",
  str0226: "¿Desea continuar esta cotización sin los ítems descritos anteriormente?",
  str0227: "Se anuló el proceso de cotización.",
  str0228: "Anular cotización",
  str0229: "¡Este proveedor ya fue seleccionado!",
  str0230: "Identificamos un problema para iniciar esta cotización",
  str0231: "No se podrá generar esta cotización",
  str0232: "Editar cotización",
  str0233: "Detalles",
  str0234: "Parcial",
  str0235: "Borrar",
  str0236: "Análisis de la cotización",
  str0237: "Total flete",
  str0238: "Total ítems",
  str0239: "Valor por pagar",
  str0240: "Plazo de entrega",
  str0241: "Formalizar compra",
  str0242: "Detalles de la cotización",
  str0243: "Total de la cotización",
  str0244: "Ítems pendientes",
  str0245: "Volver",
  str0246: "Descuentos",
  str0247: "Impuestos",
  str0248: "Flete",
  str0249: "Forma de pago",
  str0250: "Condición de pago",
  str0251: "Descalificar proveedor",
  str0252: "Enviar e-mail",
  str0253: "Observación",
  str0254: "Entrega",
  str0255: "Productos solicitados",
  str0256: "Cantidad solicitada",
  str0257: "Cantidad disponible",
  str0258: "Valor unitario",
  str0259: "Valor total",
  str0260: "Criterio más importante",
  str0261: "Valor total",
  str0262: "Valor unitario",
  str0263: "Mejor precio",
  str0264: "Menor plazo",
  str0265: "Mejor nota",
  str0266: "Filtros",
  str0267: "Descuentos",
  str0268: "Impuestos",
  str0269: "Valor total de la cotización",
  str0270: "Descuentos aplicados",
  str0271: "Tipo de flete",
  str0272: "Cond. Pago",
  str0273: "CIF",
  str0274: "FOB",
  str0275: "Por terceros",
  str0276: "Por remitente",
  str0277: "Destinatario",
  str0278: "Sin flete",
  str0279: "Detalles de pago",
  str0280: "Flete ítem",
  str0281: "Total ítem",
  str0282: "Fecha de entrega",
  str0283: "Dirección de entrega",
  str0284: "Considerar ítem",
  str0285: "Descuento",
  str0286: "Grabar",
  str0287: "Cotizaciones",
  str0288: "Cargando",
  str0289: "Descalificar",
  str0290: "Actualizando cotización",
  str0291: "Finalizada",
  str0292: "Grabar adjunto(s)",
  str0293: "Enviar e-mail(s)",
  str0294: "Ciudad",
  str0295: "Tipo flete",
  str0300: "Días para entrega",
  str0301: "Visualizar",
  str0302: "Calificar",
  str0303: "Descalificar",
  str0304: "Otras acciones",
  str0305: "Datos de la cotización:",
  str0306: "Visualizando cotización:",
  str0307: "Considera ítem",
  str0308: "Es obligatorio la cumplimentación de la condición de pago.",
  str0309: "La cantidad de días para entrega debe ser mayor que cero.",
  str0310: "La cantidad disponible incluida es mayor que la cantidad solicitada.",
  str0311: "Hay ítem sin definición de situación.",
  str0312: "Texto de observación que se enviará al proveedor.",
  str0313: "Agregar e-mail",
  str0314: "Para:",
  str0315: "Completa",
  str0316: "Parcial",
  str0317: "Pendiente",
  str0318: "Rechazada",
  str0319: "Descalificado",
  str0320: "Completa (E-mail)",
  str0321: "Parcial (E-mail)",
  str0322: "¿Desea descalificar a este proveedor?",
  str0323: "¿Desea calificar a este proveedor?",
  str0324: "E-mail incluido en duplicidad",
  str0325: "Tamaño máximo del campo es de",
  str0326: "caracteres",
  str0327: "Tamaño máximo del campo",
  str0328: "excedido.",
  str0329: "Adjunto(s)",
  str0330: "E-mail no válido",
  str0331: "Nuevo adjunto",
  str0332: "Archivo",
  str0333: "Por propuesta completa",
  str0334: "Por ítem",
  str0335: "Média total",
  str0336: "Mostrar proveedores",
  str0337: "Ocultar proveedores",
  str0338: "Ítens no respondidos",
  str0339: "Atención",
  str0340: "Existen ítems que no fueron respondidos por ningún proveedor",
  str0341: "Uno o más ítems no fueron respondidos por ningún proveedor",
  str0342: "Si desea continuar retire el(os) ítem(s) de la cotización",
  str0343: "Al hacer clic en No Retirar solamente la visualización por ítems estará disponible",
  str0344: "Inferior al promedio",
  str0345: "Superior al promedio",
  str0346: "Unidad",
  str0347: "Analizar por ítem",
  str0348: "Retirar ítems",
  str0349: "Al hacer clic en Sí, solamente el análisis por ítem estará disponible",
  str0350: "Al hacer clic en Sí, los ítems seleccionados (ítems no atendidos) se retirarán de la cotización actual",
  str0351: "Ítem",
  str0352: "Fecha estimada",
  str0353: "Promedio unidad",
  str0354: "Cant. disponible",
  str0355: "Moneda",
  str0356: "Tasa de la moneda",
  str0357: "No hay proveedores aptos para esta modalidad de análisis. Utilice la opción por ítem",
  str0358: "Atención está acción es irreversible.",
  str0359: "Existen ítems que no fueron respondidos por ningún proveedor.",
  str0360: "No hay propuestas completas para análisis, ¿desea continuar el análisis solo por ítem o eliminar ítem no cotizados?",
  str0361: "Borrar cotización",
  str0362: "¿Está seguro que desea borrar la cotización?",
  str0363: "El descuento incluido es mayor que el valor total del ítem.",
  str0364: "Generando documentos",
  str0365: "No se seleccionó ningún ítem vencedor.",
  str0366: "En el promedio",
  str0367: "¿Desea confirmar el análisis y generar documentos con los vencedores seleccionados?",
  str0368: "Formalizar la cotización",
  str0369: "Ordenar por:",
  str0370: "Días",
  str0371: "Precio con impuestos",
  str0372: "Impuestos",
  str0373: "Valor IPI",
  str0374: "Valor ICMS",
  str0375: "Valor ISS",
  str0376: "ICMS Comple.",
  str0377: "Valor ICMS solidario",
  str0378: "Valor DIFAL",
  str0379: "Alícuota IPI",
  str0380: "Alícuota ICMS",
  str0381: "Alícuota ISS",
  str0382: "Alíc. Comp.",
  str0383: "Valores por ítem",
  str0384: "Porcentaje por ítem",
  str0385: "Impuestos por ítem",
  str0386: "Calcular impuestos",
  str0387: "Impuestos totales",
  str0388: "Valores totales",
  str0389: "Valor bruto",
  str0390: "Valor neto",
  str0391: "No es posible aplicar descuento a un producto sin valor",
  str0392: "Base difal destino",
  str0393: "Difal Origen",
  str0394: "Difal Destino",
  str0395: "Valores base",
  str0396: "Base IPI",
  str0397: "Base ICMS",
  str0398: "Valor total de los ítems",
  str0399: "Base ICMS solidario",
  str0400: "Impuestos calculados con éxito.",
  str0401: "Valor total del ítem",
  str0402: "No se respondió ningún ítem de la cotización.",
  str0403: "La cotización no tiene el 100% de los ítems atendidos (cantidad disponible menor que la solicitada o valor unitario en cero).",
  str0404: "La cotización tiene el 100% de los ítems atendidos.",
  str0405: "La cotización fue rechazada por el proveedor.",
  str0406: "La cotización del proveedor fue descalificada.",
  str0407: "Cotización respondida por e-mail y no tiene el 100% de los ítems atendidos (cantidad disponible menor que la solicitada o valor unitario en cero).",
  str0408: "Cotización respondida por e-mail y tiene el 100% de los ítems atendidos.",
  str0409: "Esta cotización no tiene adjuntos.",
  str0410: "Se enviarán solo adjuntos informados al generar la cotización, no se pueden adjuntar nuevos archivos al e-mail.",
  str0411: "Observación PC/CT",
  str0412: "¡Este ítem ya fue seleccionado!",
  str0413: "Encontramos un problema al enviar e-mail. ¡Verifique las configuraciones del Workflow!.",
  str0414: "Es necesario informar la justificación para los ítems.",
  str0415: "El(os) ítem(s) a continuación no tienen el menor valor, por lo tanto, es necesario justificar la selección.",
  str0416: "Importante",
  str0417: "La propuesta elegida no tiene el menor valor, por lo tanto, es necesario justificar la selección.",
  str0418: "Es necesario incluir una justificación.",
  str0419: "Agregar observación",
  str0420: "Justificación",
  str0421: "Todos",
  str0422: "Tipo de estatus",
  str0423: "Propuesta",
  str0424: "Campos personalizados",
  str0425: "Impuestos por ítem",
  str0426: "Otros campos",
  str0427: "Nueva propuesta workflow",
  str0428: "Se generará una nueva propuesta, todos los valores ya informados quedarán en cero",
  str0429: "Cant. productos",
  str0430: "Cant. proveedores",
  str0431: "Mostrar historial de propuestas",
  str0432: "Ocultar historial de propuestas",
  str0433: "Nueva propuesta manual",
  str0434: "Se generará una nueva propuesta, al hacer clic en confirmar usted será reorientado a la siguiente pantalla.",
  str0435: "Fecha de emisión",
  str0436: "Inconsistencia en el campo E-mail del proveedor",
  str0437: "Gastos",
  str0438: "Seguro",
  str0439: "Código grupo de producto",
  str0440: "Descripción grupo de producto",
  str0441: "Incluya el grupo de producto",
  str0442: "Incluya la descripción del grupo de producto",
  str0443: "Grupos de producto diferentes",
  str0444: "¿Validar grupo de producto?",
  str0445: "Permitir seleccionar solamente ítems del mismo grupo de producto (Considera solamente el grupo del primer ítem seleccionado).",
  str0446: "Solo se permite seleccionar ítems sin grupo de producto.",
  str0447: "Solo se permite seleccionar ítems del grupo de producto",
  str0448: "Mapa de la cotización",
  str0449: "Otros valores",
  str0450: "Tipo de entrada",
  str0451: "Código",
  str0452: "Código fiscal",
  str0453: "Genera Fact. Créd.",
  str0454: "Actualiza stock",
  str0455: "Tex Estánd",
  str0456: "Borrar Prop.",
  str0457: "La propuesta actual se borrará, ¿desea continuar?",
  str0458: "Valores promedios",
  str0459: "Promedio Uni.",
  str0460: "El porcentaje de descuento máximo permitido es de",
  str0461: "El descuento incluido es mayor que el valor total de la cotización.",
  str0462: "Saldo",
  str0463: "Proveedor ya existe",
  str0464: "Para incluir un participante en esta solicitud de cotización, complete los siguientes datos.",
  str0465: "Si se realiza un pedido de compras con este participante, es necesario realizar el registro completo.",
  str0466: "Incluir proveedor",
  str0467: "Proveedor no registrado",
  str0468: "La cotización tuvo como ganador a un participante no registrado. ¿Desea registrarlo ahora?",
  str0469: "Si no desea registrar, esta cotización no se formalizará.",
  str0470: "Sesión expirada. Acceda nuevamente.",
  str0471: "Cadastro de fornecedor",
  str0472: "Otros",
  str0473: "Código Municipio",
  str0474: "DDI",
  str0475: "Agencia",
  str0476: "Cuenta",
  str0477: "Condición",
  str0478: "Tipo",
  str0479: "Se debe modificar el RCPJ informado.",
  str0480: "Se debe modificar el RCPF informado.",
  str0481: "RCPJ ya registrado",
  str0482: "RCPF ya registrado",
  str0483: "Cant. Respuestas",
  str0484: "Generar documentos",
  str0485: "Formalización",
  str0486: "Prop. respondidas",
  str0487: "Prop. descartadas",
  str0488: "Formalización de la propuesta",
  str0489: "Tipo de documento",
  str0490: "Contrato",
  str0491: "Pedido Compra",
  str0492: "Contrato único",
  str0493: "Contratos separados",
  str0494: "Próximo contrato",
  str0495: "Grabar contrato",
  str0496: "Grabar contratos",
  str0497: "Agregar contrato",
  str0498: "¿Desea generar un único contrato para todos los proveedores, separándolos en planillas, o contratos separados para cada proveedor?",
  str0499: "Formalización de las propuestas",
  str0500: "Producto",
  str0501: "Preferencia",
  str0502: "El proveedor '{0}' no tiene saldo para este producto.",
  str0503: "El ítem '{0}' no tiene saldo.",
  str0504: "La selección del proveedor con mejor valor, considera la cantidad disponible y composición de los siguientes valores:",
  str0505: "Información",
  str0506: "Cálculos",
  str0507: "El proveedor no tiene stock o no vende los ítems cotizados.",
  str0508: "Productos",
  str0509: "El código del proveedor o razón social ya se utilizaron en esta cotización.",
  str0510: "Agregar proveedor",
  str0511: "Medición eventual",
  str0512: "Contrato fijo",
  str0513: "Contacto",
  str0514: "Nuevo participante",
  str0515: "No se encontró proveedor para la tienda informada.",
  str0516: "Informe el campo Código Proveedor o Razón social.",
  str0517: "Formato del campo e-mail no válido.",
  str0518: "No se puede agregar un nuevo participante, porque la cotización está fuera del plazo de recepción.",
  str0519: "¿Enviar cotización por e-mail al nuevo participante?",
  str0520: "Generar",
  str0521: "Campos obligatorios.",
  str0522: "Cotización pendiente.",
  str0523: "Cotización finalizada.",
  str0524: "Adjunto grabado con éxito.",
  str0525: "Error al grabar el archivo adjunto.",
  str0526: "Es obligatorio informar la moneda.",
  str0527: "Solo proveedores con propuestas completas, es decir, que responderán todos los ítems, se listarán a continuación.",
  str0528: "Parámetro y archivos",
  str0529: "Informe el directorio de trabajo del Workflow",
  str0530: "Haga el siguiente download y extraigaa los archivos en la carpeta informada anteriormente.",
  str0531: "Documentación oficial para consulta",
  str0532: "Nuevo flujo de compras",
  str0533: "Datos del servidor",
  str0534: "Informe la dirección (IP) público del servidor de workflow",
  str0535: "Informe el puerto del servidor de workflow",
  str0536: "Nombre del entorno donde el workflow se ejecuta",
  str0537: "Nombre/IP y puerto del servidor de workflow",
  str0538: "Puerto multiprotocolo",
  str0539: "Finalización",
  str0540: "Al confirmar, los datos se grabarán en la base y el sistema creará un archivo de modelo de Appserver.ini, para que se copie en su Appserver.ini oficial, de acuerdo con las reglas de su entorno.",
  str0541: "Éxito",
  str0542: "El archivo AppServer.ini con los datos informados a continuación, solo es una sugerencia. Adapte al .ini del entorno real, de acuerdo con su necesidad.",
  str0543: "Recuerde: Incluya los archivos .html bajados en el lugar informado en el campo directorio de trabajo del Workflow",
  str0544: "Parámetros de e-mail - compradores",
  str0545: "Informe la dirección del servidor SMTP:",
  str0546: "Informe el puerto del servidor SMTP:",
  str0547: "Informe la dirección del servidor POP 3 / IMAP:",
  str0548: "Informe el puerto del servidor POP 3 / IMAP:",
  str0549: "Copiar texto",
  str0550: "Modelo de AppServer.ini",
  str0551: "Configuración - e-mail del comprador",
  str0552: "Protocolo de seguridad utilizado en el servidor",
  str0553: "Informe el nombre/IP del servidor SMTP de e-mail utilizado. Si estuviera en blanco o no tuviera información, consulte al departamento técnico de la empresa.",
  str0554: "Informe el puerto del servidor SMTP de e-mail utilizado. Si estuviera en blanco o no tuviera información, consulte al departamento técnico de la empresa.",
  str0555: "Informe el nombre/IP del servidor POP3/IMAP de e-mail utilizado. Si estuviera en blanco o no tuviera información, consulte al departamento técnico de la empresa.",
  str0556: "Informe el puerto del servidor POP3/IMAP de e-mail utilizado. Si estuviera en blanco o no tuviera información, consulte al departamento técnico de la empresa.",
  str0557: "Tipo de protocolo de autenticación del servidor de e-mail: SSL, TLS o Ninguna",
  str0558: "Caja de e-mail creada.",
  str0559: "Cuenta de e-mail comprador",
  str0560: "E-mail del comprador",
  str0561: "Nombre del comprador que constará en el e-mail",
  str0562: "E-mail del comprador que constará en el e-mail - campo 'De:'",
  str0563: "Autenticación",
  str0564: "Autenticación del usuario (cuenta de e-mail)",
  str0565: "Contraseña de autenticación (contraseña del e-mail)",
  str0566: "Servidores SMTP y POP 3/IMAP",
  str0567: "Dirección del servidor SMTP",
  str0568: "Puerto del servidor SMTP",
  str0569: "Dirección del servidor POP 3/IMAP",
  str0570: "Puerto del servidor POP 3/IMAP",
  str0571: "A continuación, se creará la nueva caja de e-mail con la información incluida.",
  str0572: "El puerto multiprotocolo (MultiProtocolPort) existe en el INI y está activo.",
  str0573: "El puerto multiprotocolo (MultiProtocolPort) no existe en el INI o está inactivo (valor 0).",
  str0574: "Ocurrió un error en la creación de la caja de e-mail:",
  str0575: "Informe el directorio donde se grabarán los archivos generados por el workflow. Por ejemplo, si el camino fuera directo a la carpeta Workflow, informe únicamente \\workflow.",
  str0576: "Si tiene el alias virtual o subdirectorio para el workflow, como la carpeta ABC, dentro de la carpeta Workflow, informe \\workflow\\abc.",
  str0577: "Código SC",
  str0578: "Solicitudes de compras relacionadas",
  str0579: "Configuración Workflow",
  str0580: "Se consideran los impuestos IPI e ICMS Solidario como criterio para el mejor precio.",
  str0581: "Total sin impuestos",
  str0582: "Total con impuestos",
  str0583: "Mejor precio sin impuestos",
  str0584: "Mejor precio con impuestos",
  str0585: "Pedidos de compras generados",
  str0586: "Contratos generados",
  str0587: "Detalles del presupuesto",
  str0588: "Número PC",
  str0589: "Número del contrato",
  str0590: "Tipo Contrato",
  str0591: "Valor",
  str0592: "Fecha inicial",
  str0593: "Vigencia",
  str0594: "Mostrar proveedores vinculados al producto",
  str0595: "Ocultar proveedores vinculados al producto",
  str0596: "Selección de Proveedores vs. Producto",
  str0597: "Selección de proveedores",
  str0598: "RCPJ/RCPF",
  str0599: "Los archuivos anteriores también puden encontrarse en la sección Workflow de la documentación oficial.",
  str0600: "Haga clic aquí",
  str0601: "Download de archivos",
  str0602: "Observación de la solicitud",
  str0603: "Sobre el Wizard",
  str0604: "Este wizard busca facilitar la creación del Workflow, utilizado en el NFC, para envío de las propuestas por e-mail.",
  str0605: "En cada etapa del wizard, complete con las informaciones solicitadas, para que al final del wizard, las informaciones se graben en el sistema y se muestre un archivo",
  str0606: "AppServer.ini de modelo, para poder comparar y hacer los ajustes necesarios en el archivo AppServer.ini del entorno en que el workflow se implementará.",
  str0607: "Además, se puede incluir como estándar las direcciones de los servidores SMTP y POP3/IMAP utilizados en la organización, para que cada comprador que utilice el NFC, pueda configurar su e-mail de manera autónoma,",
  str0608: "A través del wizard de configuración de e-mail del comprador.",
  str0609: "Datos grabados y modelo de AppServer.ini generado.",
  str0610: "Caja de e-mail modificada.",
  str0611: "Se encontraron los siguientes problemas:",
  str0612: "Este wizard busca facilitar la creacióni de la caja de e-mail del comprador, utilizado en el Workflow del NFC, para envío de las propuestas por e-mail, si el parámetro MV_PGCWF está activo.",
  str0613: "En cada etapa del wizard, complete con las informaciones solicitadas. Si tiene dudas sobre la etapa de la dirección de los servidores SMTP y POP3/IMAPs, consulte el departamento técnico de su organización.",
  str0614: "Al final, si las informaciones están correctas, el sistema criará la caja de e-mail del comprador en el Workflow, disparando las propuestas por medio de esta caja de correo.",
  str0615: "Observaciones adicionadas a la solicitud de compras. Esta información no se enviará a los proveedores.",
  str0616: "Digite las observaciones que se enviarán a los proveedores (Opcional).",
  str0617: "¿Mantener valores de la propuesta actual?",
  str0618: "Código comprador",
  str0619: "Nombre comprador",
  str0620: "Busqueda por comprador",
  str0621: "Cantidad que se adquirirá:",
  str0622: "El valor informado",
  str0623: "es mayor que el saldo disponible",
  str0624: "El campo Cantidad se informará con el valor del saldo disponible del ítem.",
  str0625: "La cantidad debe ser mayor que cero.",
  str0626: "El Pedido de compra y/o Contrato relacionado con la cotización se borraron del sistema.",
  str0627: "Atendido parcialmente",
  str0628: "From A - Z",
  str0629: "From Z - A",
  str0630: "Cantidad en el Proveedor",
  str0631: "Cantidad disponible Proveedor",
  str0632: "Próxima etapa",
  str0633: "Etapa anterior",
  str0634: "Error al obtener condiciones de pago:",
  str0635: "¿Seleccionar condiciones de pago?",
  str0636: "Si desea optar por no seleccionar condiciones de pago, todas las condiciones habilitadas para envío en el WF serán automáticamente enviadas.",
  str0637: "Compra centralizada",
  str0638: "Sucursal centralizadora",
  str0639: "Sucursal y RCPJ",
  str0640: "Ítems de las sucursales que se centralizarán",
  str0641: "Ítems que se centralizarán",
  str0642: "Observación PC",
  str0643: "En esta tabla, quedan los ítems seleccionados que se migrarán a la sucursal centralizadora, es decir, la sucursal conectada.",
  str0644: "Aquí, se muestran todas las solicitudes de compras, independiente de la sucursal. Es decir, se muestran las solicitudes de todas las sucursales del sistema.",
  str0645: "Seleccione la sucursal Centralizadora",
  str0646: "Centralizar",
  str0647: "Intente nuevamente",
  str0648: "Nombre de la caja de e-mail:",
  str0649: "Informe el nombre de la caja de e-mail. Solo utilice letras, números y subrayado.",
  str0650: "Como estándar, el nombre será el e-mail del usuario - constando solamente letras, números y subrayado (_) - ocultando otros caracteres.",
  str0651: "El nombre de la caja de e-mail solo se graba en la inclusión. Si ya existe, se ignora cualquier modificación en este campo.",
  str0652: "Está seguro que desea retirar el ítem:",
  str0653: "Filtro del comprador",
  str0654: "Ítems de la cotización",
  str0655: "Expandir visualización - Productos solicitados",
  str0656: "Sustitución de aprobador",
  str0657: "Aprobador",
  str0658: "Sustituto",
  str0659: "Sustituir y transferir permanentemente",
  str0660: "Seleccione el aprobador:",
  str0661: "Transferencia temporal",
  str0662: "Período de ausencia",
  str0663: "Todos los documentos y grupos de aprobación se transferirán al aprobador sustituto permanentemente.",
  str0664: "imagen pantalla home",
  str0665: "Imagen transferencia de documentos",
  str0666: "Nº Documentos",
  str0667: "Busque por número de documento",
  str0668: "Seleccione el aprobador sustituto:",
  str0669: "Autorización de entrega",
  str0670: "Contacto asociación",
  str0671: "Documento garantía",
  str0672: "Medición",
  str0673: "Revisión",
  str0674: "Documento de entrada",
  str0675: "Solicitud al almacén",
  str0676: "Solicitud de transferencia",
  str0677: "Documento Agro",
  str0678: "Transferencia de documentos",
  str0679: "¿Desea transferir los documentos seleccionados para el aprobador sustituto?",
  str0680: "Documentos",
  str0681: "Transfiere los documentos dirigidos al aprobador para el sustituto por determinado tiempo de ausencia, que debe ser previamente establecido.",
  str0682: "Transfiere todos los documentos del aprobador, que están esperando la liberación, incluyendo los grupos de aprobación, al aprobador sustituto.",
  str0683: "Migrar cotizaciones",
  str0684: "El objetivo de esta rutina es realizar la migración de cotizaciones generadas por la rutina de 'Genera cotizaciones, Actualiza cotización y Analiza cotización' al",
  str0685: "Nuevo flujo de compras.",
  str0686: "Informe la fecha inicial y final de la emisión para filtrar las cotizaciones que migrarán.",
  str0687: "Atención:",
  str0688: "Esta acción no podrá revertirse. El procesamiento se realizará en segundo plano.",
  str0689: "Fecha inicial",
  str0690: "Fecha final",
  str0691: "¿Recibir notificación al final del procesamiento?",
  str0692: "Sí",
  str0693: "No",
  str0694: "Es necesario tener un e-mail configurado previamente en el entorno.",
  str0695: "¿Desea transferir permanentemente todos los documentos del aprobador al aprobador sustituto?",
  str0696: "Después de confirmar, los documentos transferidos podrán consultarse en la lista del aprobador sustituto.",
  str0697: "Ninguno",
  str0698: "Esta caja de e-mail ya se registró.",
  str0699: "Envío de e-mails y documentos adjuntos",
  str0700: "¿Recibir notificación de la respuesta?",
  str0701: "¿Notifica resumen para el proveedor?",
  str0702: "Cuando el proveedor responda a la propuesta, usted recibirá un e-mail informando que la respuesta se envió.",
  str0703: "Al responder a la propuesta, el proveedor recibirá un e-mail con un resumen de los ítems respondidos por este.",
  str0704: "La Justificación debe contener solamente",
  str0705: "caracteres.",
  str0706: "Transferencia puntual",
  str0707: "Transfiere los documentos seleccionados de forma puntual para el aprobador sustituto.",
  str0708: "Compradores",
  str0709: "Defina los tipos de documentos para el grupo de aprobación:",
  str0710: "Grupo",
  str0711: "Aprobadores",
  str0712: "Flujo de aprobación",
  str0713: "Prueba de flujo",
  str0714: "Finalizado con éxito",
  str0715: "Entes contables",
  str0716: "El ítem",
  str0717: "no se completó.",
  str0718: "Los ítems",
  str0719: "no se informaron.",
  str0720: "No se permiten ítems con información repetida. Verifique los ítems.",
  str0721: "Informe los campos Grupo y Descripción.",
  str0722: "y",
  str0723: "Defina el grupo de aprobación:",
  str0724: "¿Este grupo utilizará entes contables?",
  str0725: "Habilita entes contables",
  str0726: "Defina los entes contables para el grupo de aprobación:",
  str0727: "Agregar",
  str0728: "Grupos de aprobación",
  str0729: "Busque por el nombre o código del grupo",
  str0730: "Nº Pedido de compra",
  str0731: "Existen ítems que necesitan de intervención manual, porque no fue posible seleccionar automáticamente el mejor valor, porque algunos de los proveedores están ofreciendo una cantidad menor que la solicitada.",
  str0732: "Los ítems que necesitan de atención se listan a continuación:"
};
