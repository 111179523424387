import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AttachDocumentsComponent } from './shared/components/attach-documents/attach-documents.component';
import { HandleStringWithRegex } from './../../shared/utils/handleStringWithRegex';
import { RouterModule } from '@angular/router';
import { DateHandler } from 'src/app/shared/helper/date-handler';
import { QuoteDataComponent } from './quote-request/quote-data/quote-data.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { SearchComponent } from './shared/components/search/search.component';

import { PoPageDynamicSearchModule } from '@po-ui/ng-templates';
import { PoLoadingModule, PoButtonModule, PoWidgetModule, PoAccordionModule } from '@po-ui/ng-components';

import { PurchaseRequestsRoutingModule } from './purchase-requests-routing.module';
import { PurchaseRequestsComponent } from './purchase-requests.component';
import { PurchaseRequestsListComponent } from './purchase-requests-list/purchase-requests-list.component';

import { PurchaseListService } from './shared/services/purchase-list.service';
import { QuoteRequestComponent } from './quote-request/quote-request.component';
import { RequestedProductsComponent } from './requested-products/requested-products.component';
import { SupplierSelectionComponent } from './supplier-selection/supplier-selection.component';
import { ProtheusLibCoreModule } from '@totvs/protheus-lib-core';
import { Utils } from 'src/app/shared/helper/utils';
import { HttpInterceptorReq } from 'src/app/shared/interceptors/http.interceptor';
import { SupplierBindingComponent } from './supplier-selection/supplier-binding/supplier-binding.component';
import { CentralizedPurchaseComponent } from './purchase-requests-list/centralized-purchase/centralized-purchase.component';
import { SupplierEmailComponent } from './supplier-selection/supplier-email/supplier-email.component';

@NgModule({
  declarations: [
    PurchaseRequestsComponent,
    PurchaseRequestsListComponent,
    SearchComponent,
    QuoteDataComponent,
    QuoteRequestComponent,
    RequestedProductsComponent,
    SupplierSelectionComponent,
    AttachDocumentsComponent,
    SupplierBindingComponent,
    CentralizedPurchaseComponent,
    SupplierEmailComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    PurchaseRequestsRoutingModule,
    SharedModule,
    PoPageDynamicSearchModule,
    PoWidgetModule,
    PoLoadingModule,
    PoButtonModule,
    PoAccordionModule,
    ProtheusLibCoreModule
  ],
  exports:[
    AttachDocumentsComponent,
    SupplierBindingComponent,
    SupplierEmailComponent
  ],
  providers: [
    PurchaseListService,
    DateHandler,
    HandleStringWithRegex,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorReq, multi: true }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PurchaseRequestsModule { }
