// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  margin-top: 24px;
  margin-left: 16px;
}

.buttons {
  display: flex;
  margin-top: 50px;
}

.buttons > po-button {
  margin-right: 8px;
}


/* .fixed-btn {
  float: right;
  display: block;
  position: fixed;
  top: 40%;
  right: 5%;
} */

.wrapper{
  height: 40px;
  margin-top: 4x;
  padding: 4px;
  margin-bottom: 1rem;
}

.cart {
  cursor: pointer;
}

.btn-quote {
  float: right;
  margin: 5px 5px 0 0;
}

.warning {
  color: #be3e37;
}

.alertForm{
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/purchase-requests-list/purchase-requests-list.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;;;;;;GAMG;;AAEH;EACE,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".search {\n  margin-top: 24px;\n  margin-left: 16px;\n}\n\n.buttons {\n  display: flex;\n  margin-top: 50px;\n}\n\n.buttons > po-button {\n  margin-right: 8px;\n}\n\n\n/* .fixed-btn {\n  float: right;\n  display: block;\n  position: fixed;\n  top: 40%;\n  right: 5%;\n} */\n\n.wrapper{\n  height: 40px;\n  margin-top: 4x;\n  padding: 4px;\n  margin-bottom: 1rem;\n}\n\n.cart {\n  cursor: pointer;\n}\n\n.btn-quote {\n  float: right;\n  margin: 5px 5px 0 0;\n}\n\n.warning {\n  color: #be3e37;\n}\n\n.alertForm{\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
