import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PoLookupFilter, PoLookupFilteredItemsParams, PoLookupResponseApi } from '@po-ui/ng-components';
import { map, from, of, tap, Observable, BehaviorSubject } from 'rxjs';
import { LoginService } from './login.service';
import { MingleService } from '@totvs/mingle';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
import { PgcLookupHelper } from '../helper/pgc-lookup.helper';
import { Utils } from '../helper/utils';
import { PgcLookupServiceParams } from '../models/pgc-lookup.model';

@Injectable({
  providedIn: 'root'
})
export class PgcLookupService implements PoLookupFilter{

  serviceParams: PgcLookupServiceParams = {
    endpointURL: '',
    endpointURLParams: '',
    fieldToFilter: '',
    valueReadOnly: '',
    isReadOnly: false,
    isFramework: false,
    isFrameworkBranches: false,
    executeRequestByValue: false,
    columns: [],
    fieldsToCustomString: []
  };

  behaviorLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
    private loginService: LoginService,
    private mingleService: MingleService,
    private pgcLookUpHelper: PgcLookupHelper,
    private utils: Utils) {}

  //-- Retorna itens filtrados
  getFilteredItems({filter, page, pageSize}: PoLookupFilteredItemsParams): Observable<PoLookupResponseApi> {
    let fullURL: string;
    let observable: any;

    if (!this.loginService.isProtheusLogged()) {
      fullURL = this.getBaseURL(filter, page, pageSize, true, true);
      observable = this.mingleService.gateway.get(fullURL);
      observable = from(observable).pipe(
        map((response: { items: Array<any>; hasNext: boolean }) => ({
          items: response.items,
          hasNext: response.hasNext
        })),
        tap(response => this.fieldsToCustomString(response.items))
      );
    } else {
      fullURL = this.getBaseURL(filter, page, pageSize, false, true);
      observable = this.http.get(fullURL).pipe(
        map((response: { items: Array<any>; hasNext: boolean }) => ({
          items: response.items,
          hasNext: response.hasNext
        })),
        tap(response => this.fieldsToCustomString(response.items))
      );
    }

    return observable;
  }

  //-- Retorna valor do campo filtrado quando digitado no input do lookup
  getObjectByValue(value: string) {
    const fieldToFilter: string = this.serviceParams.fieldToFilter;
    let fullURL: string;
    let observable: any;

    value = value.trimRight(); //-- remove espaçamento a direita

    if (!this.serviceParams.isReadOnly) {
      if (this.serviceParams.executeRequestByValue) { //-- Executa requisição com o valor digitado (caso esteja incorreto o input é limpo automáticamente)
        this.loading(true); //-- Ativa loading de tela

        if (!this.loginService.isProtheusLogged()) {
          fullURL = this.getBaseURL(value, 1, 1, true, false);
          observable = this.mingleService.gateway.get(fullURL);
          observable = from(observable).pipe( //-- Tratativa para retornar apenas o item filtrado
            map((response: { items: Array<any> }) => response.items[0])
          );
        } else {
          fullURL = this.getBaseURL(value, 1, 1, false, false);
          observable = this.http.get(fullURL).pipe( //-- Tratativa para retornar apenas o item filtrado
            map((response: { items: Array<any> }) => response.items[0])
          );
        }
      } else { //-- Retorna apenas o valor digitado
        observable = of({
          [fieldToFilter]: value
        });
      }
    } else {
      observable = of({
        [fieldToFilter]: this.serviceParams.valueReadOnly
      });
    }

    return observable;
  }

  //-- Obtém URL base para requisição
  getBaseURL(value: string, page: number, pageSize: number, isMingle: boolean, isFilteredItems: boolean): string {
    const endpointURL: string = this.serviceParams.endpointURL;
    const fieldToFilter: string = this.serviceParams.fieldToFilter
    const isFramework: boolean = this.serviceParams.isFramework;
    const isFrameworkBranches: boolean = this.serviceParams.isFrameworkBranches;
    const queryString: string = this.pgcLookUpHelper.convertColumnsToQueryString(this.serviceParams.columns, value); //-- Transforma campos da tabela em uma query string
    let endpointURLParams: string = this.serviceParams.endpointURLParams;
    let baseURL: string = ''
    let fullURL: string = ''
    let params = new HttpParams();

    params = params.append('page', page.toString());
    params = params.append('pageSize', pageSize.toString());

    if (!isFilteredItems && !isFrameworkBranches) {
      params = params.append(fieldToFilter, value);
    }

    if (queryString && !isFrameworkBranches) { //-- Adiciona filtro
      params = params.append('FILTER', queryString);
    }

    if (isMingle) {
      if (isFramework) {
        baseURL = environment.urlFrame;
      } else if (isFrameworkBranches) {
        baseURL = 'api/framework';
        endpointURLParams = `${fieldToFilter}=${value.toUpperCase()}&${endpointURLParams}`;
      } else {
        baseURL = environment.url;
      }

      const defaultHeaders = this.utils.getHeaders(isFrameworkBranches);
      this.mingleService.setDefaultHeaders(defaultHeaders);
    } else {
      if (isFramework) {
        baseURL = environmentProtheus.urlFrame;
      } else if (isFrameworkBranches) {
        baseURL = '/api/framework';
        endpointURLParams = `${fieldToFilter}=${value.toUpperCase()}&${endpointURLParams}`
      } else {
        baseURL = environmentProtheus.url;
      }
    }

    fullURL = `${baseURL}/${endpointURL}?${params}&${endpointURLParams}`;

    return fullURL
  }

  //-- Converte valores dos campos para uma string customizada
  fieldsToCustomString(items: Array<any>) {
    this.serviceParams.fieldsToCustomString.forEach(field => { //-- Percorre campos a terem os valores customizados
      items.forEach(item => { //-- Percorre itens da requisição
        let originalValue: string = item[field.field];

        if (originalValue === field.originalValue) {
          item[field.field] = field.customValue;
        }
      });
    });
  }

  //-- Emite evento de loading de tela
  loading(value: boolean) {
    this.behaviorLoading.next(value);
  }
}
