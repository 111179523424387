// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-bottom {
  margin: -16px 0 0 16px;
}

.text-area {
  margin-top: -40px !important;
}

.exclamantion {
  margin-top: 6px
}`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/requested-products/requested-products.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;AACF","sourcesContent":[".disclaimer-bottom {\n  margin: -16px 0 0 16px;\n}\n\n.text-area {\n  margin-top: -40px !important;\n}\n\n.exclamantion {\n  margin-top: 6px\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
