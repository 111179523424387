import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCep' })
export class CepFormat implements PipeTransform {
  transform(cep: string): string {
    cep = cep?.replace(/\D/g, '');
    if (cep?.length){
        return cep.replace(/([0-9]{5})([\d]{3})/, '$1-$2' );
    }
    return cep
    }
  }




