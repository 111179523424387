import { Injectable } from "@angular/core";
import { PgcProfileItem } from "../models/pgc-profile.model";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class TemporaryStorageService {
  constructor() { }

  private fieldsPropertiesSC1: Object = null;
  private fieldsPropertiesDHU: Object = null;
  private structSA2: Object = null;
  private structSC1: Object = null;
  private structSC7: Object = null;
  private structSC8: Object = null;
  private structDHU: Object = null;
  private structWF7: Object = null;
  private profile: Array<PgcProfileItem> = null;
  private currency: Array<PgcProfileItem> = null;
  private payment: Array<PgcProfileItem> = null;
  private situations: Array<PgcProfileItem> = null;
  private quotationItems: Array<any> = null;
  private formRequestedProducts: Object = null;
  private formQuoteData: FormGroup = null;
  private quotationCode: string = null;
  private quoteStatus: string = null;
  private proposals: Object = null;
  private hasAttachment: boolean = false;
  private formalizationItems: Array<any> = null;
  private formalizationProposal: Object = null;
  private bodyFormalizationProposal: Object = null;

  // Método para setar o valor
  setValue(property: string, value: any): void {
    this[property] = value;
  }

  // Método para obter o valor
  getValue(property: string) {
    return this[property];
  }

  clearValue(property: string) {
    if (typeof this[property] === 'boolean') {
      this[property] = false;
    } else {
      this[property] = null;
    }
  }
}
