import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PoTableColumn } from '@po-ui/ng-components';

import { LiteralsWords } from './../../../../i18n/literals.model';
import { LiteralsService } from './../../../../shared/services/literals-service';
import { MingleService } from '@totvs/mingle';
import { LoginService } from 'src/app/shared/services/login.service';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
import { Utils } from 'src/app/shared/helper/utils';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

const branch = sessionStorage.getItem('ProBranch') ? JSON.parse(sessionStorage.getItem('ProBranch')).Code : sessionStorage.getItem('branch');
const group = sessionStorage.getItem('ProBranch') ? JSON.parse(sessionStorage.getItem('ProBranch')).CompanyCode : sessionStorage.getItem('group');

@Injectable({ providedIn: 'root' })
export class PurchaseRequestsService {
  minFraction: string;
  maxFraction: string;
  poNotification: any;
  literals: LiteralsWords = this.literalsService.getLiterals();

  behaviorAppendModal = new BehaviorSubject<any>(undefined);
  behaviorDownloadAppend = new BehaviorSubject<any>(undefined);
  behaviorDisable = new BehaviorSubject<any>(undefined);
  behaviorRemoveSelectedItem = new BehaviorSubject<any>(undefined);
  behaviorSelectedItemLength = new BehaviorSubject<number>(undefined);
  behaviorOpenSelectedItemsModal = new BehaviorSubject<any>(undefined);
  behaviorChangeBranch = new BehaviorSubject<any>(undefined);
  behaviorIsQuoteDataFormValid = new BehaviorSubject<any>(undefined);

  requestsNumber: number = 0;
  columnsTable: Array<PoTableColumn>;
  structBrowserSC1: any = [];

  constructor(
    private http: HttpClient,
    private literalsService: LiteralsService,
    private mingleService: MingleService,
    private loginService: LoginService,
    private utils: Utils,
    private temporaryStorageService: TemporaryStorageService
  ) {
    this.literals = this.literalsService.getLiterals();
    this.getFields();
  }

  returnColumnsTable(): Array<PoTableColumn> {
    this.getFields();
    this.columnsTable = [];
    this.structBrowserSC1 = this.utils.getBrowserFields('structSC1', 'SC1');

    this.columnsTable = [
      {
        property: 'c1_num', //'requestcode'
        type: 'string',
        label: this.literals?.str0015,
        visible: true,
      },
      {
        property: 'c1_item', //item
        type: 'string',
        label: this.literals?.str0016,
        visible: true,
      },
      {
        property: 'c1_produto', //'productcode',
        type: 'string',
        label: this.literals?.str0017,
        visible: true,
      },
      {
        property: 'c1_descri', //productdescription
        type: 'string',
        label: this.literals?.str0018,
        visible: true,
      },
      {
        property: 'b1_grupo', //productgroup
        type: 'string',
        label: this.literals?.str0439,
        visible: false,
      },
      {
        property: 'bm_desc', //productgroupdescription
        type: 'string',
        label: this.literals?.str0440,
        visible: true,
      },
      {
        property: 'c1_datprf', //needdate
        type: 'date',
        format: 'dd/MM/yyyy',
        label: this.literals?.str0019,
        visible: true,
      },
      {
        property: 'c1_um', //unity
        type: 'string',
        label: this.literals?.str0020,
        visible: true,
      },
      {
        property: 'c1_quant', //'quantity',
        type: 'number',
        format: `1.${this.minFraction}-${this.maxFraction}`,
        label: this.literals?.str0021,
        visible: true,
      },
    ];

    if (
      this.structBrowserSC1 != undefined &&
      this.structBrowserSC1.length > 0
    ) {
      this.structBrowserSC1.forEach((field) => {
        let existField = this.columnsTable.find(
          (element) => element.property == field.field.toLowerCase()
        );

        if (!existField && field.type !== 'M') {
          if (field.type == 'N') {
            this.columnsTable.push({
              property: field.field.toLowerCase(),
              type: 'number',
              format: `1.${field.decimal.toString()}-${field.size.toString()}`,
              label: field.title,
              visible: false,
            });
          } else {
            this.columnsTable.push({
              property: field.field.toLowerCase(),
              type: 'string',
              label: field.title,
              visible: false,
            });
          }
        }
      });
    }

    return this.columnsTable;
  }

  returnColumsFromInvalidItemsTable(): Array<PoTableColumn> {
    return [
      {
        property: 'errormessage',
        label: this.literals?.str0223,
        width: '250px',
      },
      {
        property: 'c1_num',
        label: this.literals?.str0015,
        width: '160px',
      },
      {
        property: 'c1_item',
        label: this.literals?.str0016,
        width: '150px',
      },
      {
        property: 'c1_produto',
        label: this.literals?.str0017,
        width: '200px',
      },
      {
        property: 'c1_descri',
        label: this.literals?.str0018,
        width: '150px',
      },
      {
        property: 'c1_um',
        label: this.literals?.str0020,
        width: '150px',
      },
    ];
  }

  returnAppendColumnsTable(): Array<PoTableColumn> {
    return [
      {
        property: 'id',
        type: 'string',
        label: 'ID',
        visible: false,
      },
      {
        property: 'name',
        type: 'string',
        label: this.literals?.str0047,
        visible: true,
      },
      {
        property: 'description',
        type: 'string',
        label: this.literals?.str0048,
        visible: true,
      },
    ];
  }

  returnItemsSelectedColumnsTable(): Array<PoTableColumn> {
    return [
      {
        property: 'remove',
        width: '100px',
        type: 'icon',
        label: this.literals.str0144,
        visible: true,
        icons: [
          {
            action: (value) => {
              this.removeSelectedItem(value);
            },
            color: 'color-01',
            icon: 'po-icon-delete',
            tooltip: this.literals.str0150,
            value: 'true',
          },
        ],
      },
      {
        property: 'c1_num',
        width: '100px',
        type: 'string',
        label: this.literals?.str0015,
        visible: true,
      },
      {
        property: 'c1_item',
        width: '110px',
        type: 'string',
        label: this.literals?.str0016,
        visible: true,
      },
      {
        property: 'c1_produto',
        width: '110px',
        type: 'string',
        label: this.literals?.str0017,
        visible: true,
      },
      {
        property: 'c1_descri',
        width: '110px',
        type: 'string',
        label: this.literals?.str0018,
        visible: true,
      },
      {
        property: 'c1_datprf',
        width: '110px',
        type: 'date',
        format: 'dd/MM/yyyy',
        label: this.literals?.str0019,
        visible: true,
      },
      {
        property: 'c1_um',
        width: '100px',
        type: 'string',
        label: this.literals?.str0020,
        visible: true,
      },
      {
        property: 'c1_quant',
        width: '100px',
        type: 'number',
        format: `1.${this.minFraction}-${this.maxFraction}`,
        label: this.literals?.str0021,
        visible: true,
      },

      {
        property: 'c1_obs',
        width: '100px',
        type: 'string',
        label: this.literals?.str0022,
        visible: false,
      },
      {
        property: 'c1_solicit',
        width: '100px',
        type: 'string',
        label: this.literals?.str0024,
        visible: false,
      },
      {
        property: 'c1_filent',
        width: '100px',
        type: 'string',
        label: this.literals?.str0025,
        visible: false,
      },
      {
        property: 'c1_filial',
        width: '100px',
        type: 'string',
        label: this.literals?.str0026,
        visible: false,
      },
      {
        property: 'c1_cc',
        width: '110px',
        type: 'string',
        label: this.literals?.str0027,
        visible: false,
      },
      {
        property: 'c1_conta',
        width: '110px',
        type: 'string',
        label: this.literals?.str0028,
        visible: false,
      },
      {
        property: 'c1_itemcta',
        width: '110px',
        type: 'string',
        label: this.literals?.str0029,
        visible: false,
      },
      {
        property: 'c1_clvl',
        width: '110px',
        type: 'string',
        label: this.literals?.str0030,
        visible: false,
      },
    ];
  }

  //Colunas da tabela seleção de fornecedores
  columnsSuppliers(): Array<PoTableColumn> {
    return [
      {
        property: 'suppliercode',
        label: this.literals?.str0113,
        visible: true,
      },
      {
        property: 'store',
        label: this.literals?.str0114,
        visible: true,
      },
      {
        property: 'companyname',
        label:  this.literals?.str0152,
        visible: true,
      },
      {
        property: 'cnpj',
        label: this.literals?.str0598,
        visible: true,
      },
      {
        property: 'email',
        label: this.literals?.str0115,
        visible: true,
      },
      {
        property: 'areacode',
        label: this.literals?.str0175,
        visible: true,
      },
      {
        property: 'telephone',
        label: this.literals?.str0116,
        type: 'number',
        visible: true,
      },
      {
        property: 'fantasyname', 
        label: this.literals?.str0156, //NOME FANTASIA
        visible: false,
      },
      {
        property: 'address', 
        label: this.literals?.str0153, //endereço
        visible: false,
      },
      {
        property: 'district', 
        label: this.literals?.str0154, //bairro
        visible: false,
      },
      {
        property: 'zipCode', 
        label: this.literals?.str0155, //CEP
        visible: false,
      },
      {
        property: 'county', 
        label: this.literals?.str0157, //cidades
        visible: false,
      },
      {
        property: 'state', 
        label: this.literals?.str0158, //Estado
        visible: false,
      },
    ];
  }

  getFields(): void {
    let res = this.temporaryStorageService.getValue('fieldsPropertiesSC1');
    if (res) {
      this.minFraction = res.items[0].decimal.toString();
      this.maxFraction = res.items[0].length.toString();
    }
  }

  getDisableForm(value: boolean): void {
    this.behaviorDisable.next(value);
  }

  removeSelectedItem(item: PoTableColumn): void {
    if (item !== undefined) {
      this.behaviorRemoveSelectedItem.next(item);
    }
  }

  handleNotificationsNumber(number: number): void {
    this.behaviorSelectedItemLength?.next(number);
  }

  handleOpenSelectedItemsModal(status): void {
    this.behaviorOpenSelectedItemsModal.next(true);
  }

  changeBranch(branch): void {
    if (branch !== undefined) this.behaviorChangeBranch.next(branch);
  }

  isQuoteDataFormValid(status: boolean): void {
    this.behaviorIsQuoteDataFormValid.next(status);
  }

  alreadyExistsQuotation(items): Observable<any> {
    let reponse;

    if (!this.loginService.isProtheusLogged()) {
      reponse = this.mingleService.gateway.post(
        `${environment.url}/pgcSyncPurchaseRequest/checkOpen`,
        { body: items }
      );
      return reponse;
    } else {
      return this.http.post(
        `${environmentProtheus.url}/pgcSyncPurchaseRequest/checkOpen`,
        items
      );
    }
  }

  generateQuotation(quotation: any): Observable<any> {
    let reponse;

    if (!this.loginService.isProtheusLogged()) {
      reponse = this.mingleService.gateway.post(
        `${environment.url}/pgcGenerateQuotation/generateQuotation`,
        { body: quotation }
      );
      return reponse;
    } else {
      return this.http.post(
        `${environmentProtheus.url}/pgcGenerateQuotation/generateQuotation`,
        quotation
      );
    }
  }

  sendEmail(quotationWorkflow: any): Observable<any> {
    let reponse;
    if (!this.loginService.isProtheusLogged()) {
      reponse = this.mingleService.gateway.post(
        `${environment.url}/pgcWorkflow/workflow/{000000}`,
        { body: quotationWorkflow }
      );
      return reponse;
    } else {
      return this.http.post(
        `${environmentProtheus.url}/pgcWorkflow/workflow/{000000}`,
        quotationWorkflow
      );
    }
  }

  agglutinateQuotations(selectedItems: any): Observable<any> {
    let reponse;

    if (!this.loginService.isProtheusLogged()) {
      reponse = this.mingleService.gateway.post(
        `${environment.url}/pgcGenerateQuotation/agglutinateItems`,
        { body: { items: selectedItems } }
      );
      return reponse;
    } else {
      return this.http.post(
        `${environmentProtheus.url}/pgcGenerateQuotation/agglutinateItems`,
        { items: selectedItems }
      );
    }
  }

  clearQuotationFromLocalStorage(): void {
    this.temporaryStorageService.clearValue('formRequestedProducts');
    this.temporaryStorageService.clearValue('quotationItems');
    this.temporaryStorageService.clearValue('proposals');
    this.temporaryStorageService.clearValue('formQuoteData');
  }

  //Retorna as colunas da tabela de selecção de itens dinâmica, que fica a direita.
  returnColumnsTableCentralized(): Array<PoTableColumn> {
    this.getFields();
    this.columnsTable = [];
    this.structBrowserSC1 = this.utils.getBrowserFields('structSC1', 'SC1');

    this.columnsTable = [
      {
        property: 'c1_filial', //'requestcode'
        type: 'string',
        label: this.literals?.str0135,
        visible: true,
      },
      {
        property: 'c1_num', //'requestcode'
        type: 'string',
        label: this.literals?.str0015,
        visible: true,
      },
      {
        property: 'c1_produto', //'productcode',
        type: 'string',
        label: this.literals?.str0017,
        visible: true,
      },
      {
        property: 'c1_descri', //productdescription
        type: 'string',
        label: this.literals?.str0018,
        visible: true,
      },
      {
        property: 'c1_datprf', //needdate
        type: 'date',
        format: 'dd/MM/yyyy',
        label: this.literals?.str0019,
        visible: true,
      },
      {
        property: 'c1_quant', //'quantity',
        type: 'number',
        format: `1.${this.minFraction}-${this.maxFraction}`,
        label: this.literals?.str0021,
        visible: true,
      },
      {
        property: 'c1_filent', //'delivery branch'
        type: 'string',
        label: this.literals?.str0026,
        visible: true,
      },
    ];

    if (
      this.structBrowserSC1 != undefined &&
      this.structBrowserSC1.length > 0
    ) {
      this.structBrowserSC1.forEach((field) => {
        let existField = this.columnsTable.find(
          (element) => element.property == field.field.toLowerCase()
        );

        if (!existField && field.type !== 'M') {
          if (field.type == 'N') {
            this.columnsTable.push({
              property: field.field.toLowerCase(),
              type: 'number',
              format: `1.${field.decimal.toString()}-${field.size.toString()}`,
              label: field.title,
              visible: false,
            });
          } else {
            this.columnsTable.push({
              property: field.field.toLowerCase(),
              type: 'string',
              label: field.title,
              visible: false,
            });
          }
        }
      });
    }

    return this.columnsTable;
  }


  //Função para executar os passos de migração e criação de nova SC, conforme remote, na função MAPComCent
  centralizedRequestMigration(selectedItems: any): Observable<any> {
    let response;
    if (!this.loginService.isProtheusLogged()) {
      response = this.mingleService.gateway.post(
        `${environment.url}/pgcGenerateQuotation/centralizedRequestMigration`,
        { body: selectedItems }
      );
      return response;
    } else {
      return this.http.post(
        `${environmentProtheus.url}/pgcGenerateQuotation/centralizedRequestMigration`,
        selectedItems
      );
    }
  }




}
