import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PoLookupColumn, PoLookupComponent, PoNotificationService } from '@po-ui/ng-components';
import { PgcLookupService } from '../../services/pgc-lookup.service';
import { PgcLookupHelper } from '../../helper/pgc-lookup.helper';
import { FormGroup, Validators } from '@angular/forms';
import { PgcLookupFieldsToCustomString } from '../../models/pgc-lookup.model';
import { Utils } from '../../helper/utils';

@Component({
  selector: 'pgc-lookup',
  templateUrl: './pgc-lookup.component.html',
  styleUrls: ['./pgc-lookup.component.css'],
  providers: [PgcLookupService, PgcLookupHelper]
})
export class PgcLookupComponent implements OnInit, OnChanges {
  @Input () value: string; //-- Valor a ser carregado no input do lookup
  @Input () form: FormGroup; //-- Formulário do componente pai
  @Input () formField: string = '' //-- Campo do formulário
  @Input () fieldToFilter: string = '' //-- Campo a ser filtrado depois de selecionar um item no grid ou preencher input do lookup
  @Input () label: string = '' //-- Título do lookup
  @Input () columns: Array<PoLookupColumn> = []; //-- Colunas a serem exibidas no lookup
  @Input () fieldsToCustomString: Array<PgcLookupFieldsToCustomString> = [];  //-- Converte um campo para uma string customizada ex: f4_tipo igual a "E" é alterado para "Entrada"
  @Input () endpointURL: string = '' //-- URL do endpoint
  @Input () endpointURLParams: string = '' //-- Parâmetros da URL ex: bm_grupo=0001
  @Input () disabled: boolean = false; //-- Desativa lookup
  @Input () isReadOnly: boolean = false; //-- Indica se é apenas vizualização (permite abrir lookup, mas não permite alterar seu valor)
  @Input () isRequired: boolean = false; //-- Indica se o lookup é obrigatório no formulário
  @Input () isFramework: boolean = false; //-- API utilizada é do frame?
  @Input () isFrameworkBranches: boolean = false; //-- API utilizada é de filiais do frame?
  @Input () executeRequestByValue: boolean = false; //-- Se deve executar requisição quando preenchido o input do lookup
  @Input () modelChange: boolean = false; //-- Indica se model foi alterado
  @Input () cleanModel: boolean = false; //-- Indica se deve limpar o ngModel
  @Input () cleanField: string = ''; //-- Indica o campo a ser limpo do formulário
  @Output() searchedValue: EventEmitter<any> = new EventEmitter(); //-- Retorna campo filtrado e valor para o componente pai
  @Output() loading: EventEmitter<boolean> = new EventEmitter(); //-- Evento de loading de tela quando é executada uma requisição no lookup
  @ViewChild('lookup', { static: true }) lookup: PoLookupComponent;

  public valueModel: string = '';

  constructor(
    public pgcLookupService: PgcLookupService,
    private utils: Utils,
    private poNotification: PoNotificationService
  ) { }

  ngOnInit(): void {
    this.initializeServiceProperties();
    this.setRequired();
    this.valueModel = this.value;
    this.pgcLookupService.behaviorLoading.subscribe((value: boolean) => this.loading.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isModelChange: boolean = changes?.modelChange && this.modelChange;
    const isCleanModel: boolean = changes?.cleanModel && this.cleanModel;
    const isCleanField: boolean = !!(changes?.cleanField && this.cleanField);

    if (isModelChange || isCleanModel || isCleanField) {
      if (isModelChange && this.valueModel) { //-- Altera valor do model para conseguir aplicar filtro novamente sem alterar manualmente
        this.valueModel = this.valueModel.trimRight() + ' ';
      } else if (this.form?.value) {
        if (isModelChange && !this.valueModel && this.form?.value) { //-- Altera valor do lookup conforme o formulário
          const value = this.form.get(this.formField).value;
          this.valueModel = value ? this.form.get(this.formField).value : '';
        } else if (isCleanModel) { //-- Limpa valor do model
          this.valueModel = '';
          this.form.get(this.formField).setValue('');
        } else if (this.formField === this.cleanField) { //-- Limpa apenas um campo específico do formulário
          this.valueModel = '';
          this.form.get(this.formField).setValue('');
        }
      } else if (isCleanModel) { //-- Limpa o model caso não tenha formulário.
        this.valueModel = '';
        this.onChangeLookupValue(null, '');
      }
    }

    if (changes?.endpointURLParams) { //-- Altera parâmetros do endpoint caso o input endpointURLParams seja alterado
      this.pgcLookupService.serviceParams.endpointURLParams = changes.endpointURLParams.currentValue;
    }
  }

  //-- Inicializa objeto do serviço PgcLookupService
  initializeServiceProperties () {
    this.pgcLookupService.serviceParams = {
      endpointURL: this.endpointURL,
      endpointURLParams: this.endpointURLParams,
      fieldToFilter: this.fieldToFilter,
      valueReadOnly: this.value,
      isReadOnly: this.isReadOnly,
      isFramework: this.isFramework,
      isFrameworkBranches: this.isFrameworkBranches,
      executeRequestByValue: this.executeRequestByValue,
      columns: this.columns,
      fieldsToCustomString: this.fieldsToCustomString
    }
  }

  //-- Método executado ao alterar o valor do model
  onChangeModel(value): void {
    //-- Altera model ao receber um valor diferente do valor de visualização
    //-- Ao realizar este comando, o valor do model é restaurado
    if (this.isReadOnly && value && value?.trim() !== this.value?.trim()) {
      this.valueModel = this.value + ' ';
    } else if (value === undefined || value === '') { //-- Se o valor do model estiver undefined deve atribuir uma string vazia
      this.valueModel = '';
      if (this.form) {
        this.form.get(this.formField).setValue('');
      }
      this.onChangeLookupValue(null, '');
    }
   }

  //-- Evento que envia os dados para o componente pai
  onSelected(item: any, value: string) {
    if (this.executeRequestByValue || this.form || Object.keys(item).length > 1)  {
      this.onChangeLookupValue(item, value);
    }
  }

  //-- Emite eventos/atualiza formulário quando ovalor do lookup for alterado
  onChangeLookupValue(item: any, value: string): void {
    value = item ? item?.[this.fieldToFilter] : value;

    if (this.form && value !== undefined) { //-- Retorna dados pelo formulário
      this.form.get(this.formField).setValue(value);
    } else { //-- Retorna dados pelo evento searchedValue
      if (this.isReadOnly && value && value?.trim() !== this.value?.trim()) {
        value = this.value;
      }
    }
    
    const object: {field: string, value: string, selectedItems: any} = {
      field: this.fieldToFilter,
      value: value,
      selectedItems: this.lookup['selectedOptions']
    };

    if (this.executeRequestByValue) { //-- Emite evento para desativar o loading da tela
      this.loading.emit(false);
    }

    this.searchedValue.emit(object); //-- Emite valor selecionado
  }

  //-- Evento disparado ao pressionar uma tecla
  onKeyUp(event: any) {
    if (!this.executeRequestByValue) {
      const key: string = event?.key;
      const value: any = event.target.value;

      if (key.length === 1 || key === 'Backspace' || key === 'Delete') {
        this.onChangeLookupValue(null, value);
      }
    }
  }

  //-- Exibe mensagem derro
  error(error: any) {
    this.poNotification.error(this.utils.getErrorMessage(error));
  }

  //-- Define se o Lookup deve ser obrigatório no formulário.
  setRequired() {
    if (this.form && this.isRequired) {
      const field = this.form.get(this.formField);
      field.setValidators(Validators.required); // Adiciona o validador obrigatório
      field.updateValueAndValidity();
    }
  }
}
