import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PoModule } from '@po-ui/ng-components';
import { PoNotificationModule } from '@po-ui/ng-components';
import { PoPageDynamicSearchModule } from '@po-ui/ng-templates';
import { PoPageDynamicTableModule } from '@po-ui/ng-templates';
import { PoDynamicModule } from '@po-ui/ng-components';

import { AttachFilesComponent } from './components/attach-files/attach-files.component';
import { FormMessageComponent } from './components/form-message/form-message.component';
import { DocumentsFormat } from './pipe/documents-format.pipe';
import { PhonePipe } from './pipe/phone.pipe';
import { CepFormat } from './pipe/formartCep.pipe';
import { PgcLookupComponent } from './components/pgc-lookup/pgc-lookup.component';
import { PgcLookupService } from './services/pgc-lookup.service';

@NgModule({
  declarations: [
    AttachFilesComponent,
    FormMessageComponent,
    DocumentsFormat,
    PhonePipe,
    CepFormat,
    PgcLookupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PoModule,
    PoNotificationModule,
    PoPageDynamicSearchModule,
    PoPageDynamicTableModule,
    PoDynamicModule
  ],
  exports: [
    PoModule,
    FormsModule,
    ReactiveFormsModule,
    PoNotificationModule,
    PoPageDynamicSearchModule,
    PoPageDynamicTableModule,
    PoDynamicModule,
    AttachFilesComponent,
    FormMessageComponent,
    DocumentsFormat,
    PhonePipe,
    CepFormat,
    PgcLookupComponent
  ],
  providers: [
    PgcLookupService
  ]
})
export class SharedModule {}
