// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-file-format {
    font-size: 24px;
    color: #B6BDBF
}

.icon-file-status {
    font-size: 24px;
    color: #097B98;
}

.container-attach {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.align-center {
    justify-content: center
}

.align-right {
    display: flex;
	justify-content: flex-end;
}

.align-left {
    display: flex;
    justify-content: flex-start;
}

.upload-box {
    cursor: pointer;
    border: 2px dashed #dadedf;
}

.upload-hover{
    border: 2px dashed #0C9ABE;
}

.upload-opacity {
    opacity: 0.5;
}

.error-upload .po-icon{
    color: #C64840;
}

.error-upload {
    color: #C64840;
}

.success-upload {
    color: #00B283
}

.success-upload .po-icon {
    color: #00B283
}

.information-font {
    color: #9da7a9;
    font-size: 12px
}

.big-upload-icon {
    color: #B6BDBF;
    font-size: 72px
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

`, "",{"version":3,"sources":["webpack://./src/app/features/purchase-requests/shared/components/attach-documents/attach-documents.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;CAChB,yBAAyB;AAC1B;;AAEA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,cAAc;IACd;AACJ;;AAEA;IACI,cAAc;IACd;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB;AACJ","sourcesContent":[".icon-file-format {\n    font-size: 24px;\n    color: #B6BDBF\n}\n\n.icon-file-status {\n    font-size: 24px;\n    color: #097B98;\n}\n\n.container-attach {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.align-center {\n    justify-content: center\n}\n\n.align-right {\n    display: flex;\n\tjustify-content: flex-end;\n}\n\n.align-left {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.upload-box {\n    cursor: pointer;\n    border: 2px dashed #dadedf;\n}\n\n.upload-hover{\n    border: 2px dashed #0C9ABE;\n}\n\n.upload-opacity {\n    opacity: 0.5;\n}\n\n.error-upload .po-icon{\n    color: #C64840;\n}\n\n.error-upload {\n    color: #C64840;\n}\n\n.success-upload {\n    color: #00B283\n}\n\n.success-upload .po-icon {\n    color: #00B283\n}\n\n.information-font {\n    color: #9da7a9;\n    font-size: 12px\n}\n\n.big-upload-icon {\n    color: #B6BDBF;\n    font-size: 72px\n}\n\n.container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
