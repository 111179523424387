// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'api/com/v1',
  // Mingle Início
  appIdentifier: '6171c79a0de6252b310e5c4d',
  environment: 'DEV',
  server: 'https://dev-mingle.totvs.com.br',
  web: true,
  usageMetrics: true,
  gateway: true,
  urlFrame: 'api/framework/v1'
  // Mingle Fim
};

