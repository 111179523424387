export class HandleStringWithRegex {

  cnpjRegex = RegExp(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);
  cpfRegex = RegExp(/^(\d{3})(\d{3})(\d{3})(\d{2})/);

  convertStringToCnpj(cnpj: string): string {
    return cnpj.replace(this.cnpjRegex, '$1.$2.$3/$4-$5');
  }

  convertStringToCpf(cpf: string): string {
    return cpf.replace(this.cpfRegex, '$1.$2.$3-$4');
  }

  convertStringToPhone(phone: string): string {
    switch (phone.length) {
      case 8:
        return phone.replace(/^(\d{4})(\d{4})/, '$1-$2');
      case 9:
        return phone.replace(/^(\d{5})(\d{4})/, '$1-$2');
      case 10:
        return phone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      case 11:
        return phone.replace(/^(\d{3})(\d{4})(\d{4})/, '($1) $2-$3');
      case 12:
        return phone.replace(/^(\d{3})(\d{5})(\d{4})/, '($1) $2-$3');
      case 13:
        return phone.replace(/^(\d{2})(\d{3})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
      case 14:
        return phone.replace(/^(\d{2})(\d{3})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
      default:
        return phone;
    }

  }

}
