import { Injectable } from '@angular/core';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from '../../../../shared/services/literals-service';
import { PoLookupColumn } from '@po-ui/ng-components';
import { PgcLookupFieldsToCustomString } from 'src/app/shared/models/pgc-lookup.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  literals: LiteralsWords;
  productDescription: Array<PoLookupColumn> = [];
  productGroupColumns: Array<PoLookupColumn> = [];
  requestCodeColumns: Array<PoLookupColumn> = [];
  requesterColumns: Array<PoLookupColumn> = [];
  branchColumns: Array<PoLookupColumn> = [];
  costCenterColumns: Array<PoLookupColumn> = [];
  ledgerAccountColumns: Array<PoLookupColumn> = [];
  accountingItemColumns: Array<PoLookupColumn> = [];
  valueClassColumns: Array<PoLookupColumn> = [];
  inputTypeColumns: Array<PoLookupColumn> = [];
  genericTablesColumns: Array<PoLookupColumn> = [];
  countyColumns: Array<PoLookupColumn> = [];
  countryCodeColumns: Array<PoLookupColumn> = [];
  countryColumns: Array<PoLookupColumn> = [];
  bankColumns: Array<PoLookupColumn> = [];
  paymentConditionColumns: Array<PoLookupColumn> = [];
  participantTypeColumns: Array<PoLookupColumn> = [];
  contractTypesColumns: Array<PoLookupColumn> = [];
  supplierColumns: Array<PoLookupColumn> = [];
  fieldsToCustomStringTES: Array<PgcLookupFieldsToCustomString> = [];
  fieldsToCustomStringContractTypes: Array<PgcLookupFieldsToCustomString> = [];
  eventFilterSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private literalsService: LiteralsService) {
    this.literals = this?.literalsService?.getLiterals();

    this.productDescription = [
      { property: 'b1_cod', label: this.literals?.str0017 },
      { property: 'b1_desc', label: this.literals?.str0018 },
      { property: 'b1_um', label: this.literals?.str0020 },
    ];

    this.productGroupColumns = [
      { property: 'bm_grupo', label: this.literals?.str0439 },
      { property: 'bm_desc', label: this.literals?.str0048 }
    ];

    this.requestCodeColumns = [
      { property: 'c1_num', label: this.literals?.str0015, width: '110px' },
      { property: 'c1_item', label: this.literals?.str0351, width: '80px' },
      { property: 'c1_produto', label: this.literals?.str0017 },
      { property: 'c1_descri', label: this.literals?.str0018 }
    ];

    this.requesterColumns = [
      { property: 'c1_solicit', label: this.literals?.str0024 },
      { property: 'c1_num', label: this.literals?.str0015 },
      { property: 'c1_item', label: this.literals?.str0351 }
    ];

    this.branchColumns = [
      { property: 'Code', label: this.literals?.str0135 },
      { property: 'Description', label: this.literals?.str0048 }
    ];

    this.costCenterColumns = [
      { property: 'ctt_custo', label: this.literals?.str0027 },
      { property: 'ctt_desc01', label: this.literals?.str0048 }
    ];

    this.ledgerAccountColumns = [
      { property: 'ct1_conta', label: this.literals?.str0028 },
      { property: 'ct1_desc01', label: this.literals?.str0048 }
    ];

    this.accountingItemColumns = [
      { property: 'ctd_item', label: this.literals?.str0029 },
      { property: 'ctd_desc01', label: this.literals?.str0048 }
    ];

    this.valueClassColumns = [
      { property: 'cth_clvl', label: this.literals?.str0030 },
      { property: 'cth_desc01', label: this.literals?.str0048 }
    ];

    this.inputTypeColumns = [
      { property: 'f4_codigo' , label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'f4_cf'     , label: this.literals?.str0452, width: '130px' }, //-- Código fiscal
      { property: 'f4_duplic' , label: this.literals?.str0453 }, //-- Gera duplicata
      { property: 'f4_estoque', label: this.literals?.str0454 }, //-- Atualiza estoque
      { property: 'f4_texto'  , label: this.literals?.str0455 } //-- Texto padrão
    ];

    this.genericTablesColumns = this.getGenericTablesColumns();

    this.countyColumns = [
      { property: 'cc2_est'   , label: this.literals?.str0158, width: '90px' }, //-- Estado
      { property: 'cc2_mun'   , label: this.literals?.str0157 }, //-- Município
      { property: 'cc2_codmun', label: this.literals?.str0473, width: '200px' } //-- Código Município
    ]

    this.countryCodeColumns = [
      { property: 'acj_ddi' , label: this.literals?.str0474, width: '90px' }, //-- DDI
      { property: 'acj_pais', label: this.literals?.str0163, width: '200px' } //-- País
    ]

    this.countryColumns = [
      { property: 'ya_codgi', label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'ya_descr', label: this.literals?.str0048 } //-- Descrição
    ]

    this.bankColumns = [
      { property: 'a6_nome'   , label: this.literals?.str0047 }, //-- Nome
      { property: 'a6_agencia', label: this.literals?.str0475, width: '110px' }, //-- Agência
      { property: 'a6_numcon' , label: this.literals?.str0476, width: '150px' }, //-- Conta
      { property: 'a6_cod'    , label: this.literals?.str0451, width: '90px' } //-- Código
    ]

    this.paymentConditionColumns = [
      { property: 'e4_codigo', label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'e4_descri', label: this.literals?.str0477, width: '200px' }, //-- Condição
      { property: 'e4_tipo'  , label: this.literals?.str0478, width: '90px' } //-- Tipo
    ]

    this.participantTypeColumns = [
      { property: 'cc1_codigo', label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'cc1_descri', label: this.literals?.str0048 } //-- Descrição
    ]

    this.contractTypesColumns = [
      { property: 'cn1_codigo', label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'cn1_descri', label: this.literals?.str0048, width: '230px' }, //-- Descrição
      { property: 'cn1_medeve', label: this.literals?.str0511 }, //-- Medição Eventual
      { property: 'cn1_ctrfix', label: this.literals?.str0512 }, //-- Contrato Fixo
    ]

    this.supplierColumns = [
      { property: 'a2_cod', label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: 'a2_loja', label: this.literals?.str0114, width: '90px' }, //-- Loja
      { property: 'a2_nome', label: this.literals?.str0073 }, //-- Razão social
      { property: 'a2_email', label: this.literals?.str0115 }, //-- E-mail
    ]
  
    this.fieldsToCustomStringTES = [
      {
        field: 'f4_duplic',
        originalValue: 'S',
        customValue: this.literals.str0099 //-- Sim
      },
      {
        field: 'f4_duplic',
        originalValue: 'N',
        customValue: this.literals.str0100 //-- Não
      },
      {
        field: 'f4_estoque',
        originalValue: 'S',
        customValue: this.literals.str0099 //-- Sim
      },
      {
        field: 'f4_estoque',
        originalValue: 'N',
        customValue: this.literals.str0100 //-- Não
      }
    ]

    this.fieldsToCustomStringContractTypes = [
      {
        field: 'cn1_medeve',
        originalValue: '1',
        customValue: this.literals.str0099 //-- Sim
      },
      {
        field: 'cn1_medeve',
        originalValue: '2',
        customValue: this.literals.str0100 //-- Não
      },
      {
        field: 'cn1_ctrfix',
        originalValue: '1',
        customValue: this.literals.str0099 //-- Sim
      },
      {
        field: 'cn1_ctrfix',
        originalValue: '2',
        customValue: this.literals.str0100 //-- Não
      }
    ]
  }

  getLookupColumns(lookup: string): Array<PoLookupColumn> {
    switch(lookup) {
      case 'c1_descri':
        return this.productDescription;
      case 'b1_grupo':
        return this.productGroupColumns;
      case 'c1_num':
        return this.requestCodeColumns;
      case 'c1_solicit':
        return this.requesterColumns;
      case 'c1_filial':
        return this.branchColumns;
      case 'c1_cc':
        return this.costCenterColumns;
      case 'c1_conta':
        return this.ledgerAccountColumns;
      case 'c1_itemcta':
        return this.accountingItemColumns;
      case 'c1_clvl':
        return this.valueClassColumns;
      case 'tes':
        return this.inputTypeColumns;
      case 'gerenictables':
        return this.genericTablesColumns;
      case 'county':
        return this.countyColumns;
      case 'countrycode':
        return this.countryCodeColumns;
      case 'country':
        return this.countryColumns;
      case 'bank':
        return this.bankColumns;
      case 'paymentcondition':
        return this.paymentConditionColumns;
      case 'participanttype':
        return this.participantTypeColumns;
      case 'contracttypes':
        return this.contractTypesColumns;
      case 'supplier':
        return this.supplierColumns;
      default:
        return [];
    }
  }

  getFieldsToCustomString(lookup: string) {
    switch(lookup) {
      case 'tes':
        return this.fieldsToCustomStringTES;
      case 'contracttypes':
        return this.fieldsToCustomStringContractTypes;
      default:
        return [];
    }
  }

  getGenericTablesColumns(): Array<PoLookupColumn> {
    let property: string = '';
    let columns: Array<PoLookupColumn> = [];
    const systemlanguage: string = localStorage.getItem('PO_DEFAULT_LANGUAGE');

    switch (systemlanguage) {
      case 'pt-BR':
        property = 'x5_descri';
        break;
      case 'en':
        property = 'x5_desceng';
        break;
      case 'es':
        property = 'x5_descspa';
        break;
      default:
        property = 'x5_desceng';
    }

    columns = [
      { property: 'x5_chave' , label: this.literals?.str0451, width: '90px' }, //-- Código
      { property: property, label: this.literals?.str0048, width: '130px' } //-- Descrição
    ];

    return columns;
  }

  //-- Emite evento de alteração do filtro
  emitFilterChange(isChanged: boolean): void {
    this.eventFilterSubject.next(isChanged);
  }
}
