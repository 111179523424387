import { Injectable } from '@angular/core';
import { MingleService, Configuration } from '@totvs/mingle';
import { environment } from 'src/environments/environment.prod';
@Injectable()
export class AppInitService {

 constructor(private mingleService: MingleService) { }

 mingleConfiguration(): Promise<any> {
   return new Promise((resolve, reject) => {
     const config = new Configuration();
     config.modules.web  = false;
     config.environment = environment.environment;
     config.modules.usage_metrics = true;
     config.modules.push_notification = true;
     config.server = environment.server;
     config.app_identifier = environment.appIdentifier;

     this.mingleService.setConfiguration(config);

     this.mingleService.init()
     .then( init => {
       resolve('Mingle Service Init');
     }).catch(error => {
       console.error("error" , error);
       reject(error);
     });
   });
 }}
