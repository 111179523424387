import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExecutionHashService {
  private executionHash: string;

  constructor() {
    const timestamp = new Date().getTime();
    this.executionHash = `exec-${timestamp}`;
  }

  //-- Obtém hash de execução da aplicação
  getExecutionHash(): string {
    return this.executionHash;
  }
}