import { Component } from '@angular/core';

import { LiteralsWords } from './../../i18n/literals.model';
import { LiteralsService } from './../../shared/services/literals-service';
import { PoPageAction } from '@po-ui/ng-components';

@Component({
  selector: 'tgc-purchase-requests',
  templateUrl: './purchase-requests.component.html',
  styleUrls: ['./purchase-requests.component.css']
})
export class PurchaseRequestsComponent {

  literals: LiteralsWords = this.literalsService.getLiterals();

  constructor(private literalsService: LiteralsService) {
    this.literals = this.literalsService.getLiterals();
  }

  
  //Ações da página ativa. No caso, vamos incluir o botão para abrir a tela de Compra Centralizada. 
  public readonly actions: Array<PoPageAction> = [
    { label: this.literals?.str0637, url: '/necessidade-de-compra/compra-centralizada'}
  ];

}
