import { SupplierSelectionComponent } from './supplier-selection/supplier-selection.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PurchaseRequestsComponent } from './purchase-requests.component';
import { QuoteRequestComponent } from './quote-request/quote-request.component';
import { CentralizedPurchaseComponent } from './purchase-requests-list/centralized-purchase/centralized-purchase.component';

const routes: Routes = [
  { path: '', component: PurchaseRequestsComponent },
  { path: 'solicitacao-de-cotacao', component: QuoteRequestComponent },
  { path: 'selecao-de-fornecedores', component: SupplierSelectionComponent },
  { path: 'compra-centralizada', component: CentralizedPurchaseComponent}
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseRequestsRoutingModule {}
