import { DateHandler } from 'src/app/shared/helper/date-handler';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { RequestedProductsModel } from './../shared/models/requested-products.model';
import { PoSelectOption, PoDisclaimer } from '@po-ui/ng-components';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
  selector: 'tgc-requested-products',
  templateUrl: './requested-products.component.html',
  styleUrls: ['./requested-products.component.css'],
})
export class RequestedProductsComponent implements OnInit {
  form: FormGroup;
  notes: Array<any> = [];
  literals: LiteralsWords = this.literalsService.getLiterals();
  order: Array<PoSelectOption> = [];
  disclaimers: Array<PoDisclaimer> = [];
  minFraction: string;
  maxFraction: string;

  @Input() formDataReceived: RequestedProductsModel;

  @Input() quotationItems: any;

  @Output() formDataSent = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private literalsService: LiteralsService,
    private dateHandler: DateHandler,
    private temporaryStorageService: TemporaryStorageService
  ) {
    this.form = this.formBuilder.group({
      notesupplier: [''],
    });

    this.order = [
      { label: this.literals.str0628, value: '0' },
      { label: this.literals.str0629, value: '1' },
    ];
  }

  ngOnInit(): void {
    this.quotationItems ? this.getDisclaimers() : null;
    this.form.patchValue(this.quotationItems);
    this.quotationItems ? this.setHideNoteForSupplier() : null;
  }

  getDisclaimers() {
    const array: Array<any> = Object.keys(this.quotationItems.items)
    .map((key) => {
      let item = this.quotationItems.items[key].purchaserequests;
      let localDisclaimers: Array<PoDisclaimer> = [];
      item.forEach((element, index) => {
            localDisclaimers.push({
              hideClose: true,
              label: `${element.c1_num}-${element.c1_item}`,
              value: `${element.c1_num}-${element.c1_item}`
            });
          });
          this.quotationItems.items[key].disclaimers = localDisclaimers;
        });
  }

  setHideNoteForSupplier(){
    this.quotationItems.items.forEach((item) => {
      item['shownotesupplier'] = false;
      item['shownoterequest'] = !!item.c1_obs
    });
  }

  changeNoteEvent(item: any, event: any): void {
    let newItem = this.quotationItems.items.find(
      (el) =>
      el.c1_produto === item.c1_produto &&
      el.c1_item === item.c1_item
      );

    if (newItem) {
      newItem.notesupplier = event;
    }
  }

  orderItems(event) {
    this.quotationItems.items.sort((a, b): any => {
      if (event == '0') {
        if (a.c1_produto < b.c1_produto) {
          return -1;
        }
      } else if (event == '1') {
        if (a.c1_produto > b.c1_produto) {
          return -1;
        }
      }
    });
  }

  formatDate(date: string): string {
    return this.dateHandler.fromStringToDateFormated(date);
  }

  toRound(): string {
    this.getFields();
    return  `1.${this.minFraction}-${this.maxFraction}`
  }

  getFields() {
    let res = this.temporaryStorageService.getValue('fieldsPropertiesSC1');
    if (res) {
      this.minFraction = res.items[0].decimal;
      this.maxFraction = res.items[0].length;
    }
  }

  //-- Mostra ou oculta a caixa de texto para observação da SC
  changeShowNoteRequest(event, index){
    this.quotationItems.items[index].shownoterequest = event;
  }

  //-- Mostra ou oculta a caixa de texto para observação do fornecedor
  changeShowNoteSupplier(event, index){
    this.quotationItems.items[index].shownotesupplier = event;
  }
}
