import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import { MingleService } from '@totvs/mingle';
import { Observable } from 'rxjs';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ProAppConfigService, ProtheusLibCoreModule } from '@totvs/protheus-lib-core';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  literals: LiteralsWords = this.literalsService.getLiterals();
  minFraction: string;
  maxFraction: string;

  constructor(
    private http: HttpClient,
    private literalsService: LiteralsService,
    private mingleService: MingleService,
    private loginService: LoginService,
    private configService: ProAppConfigService,
    private temporaryStorageService: TemporaryStorageService

  ) {
    this.getFields();
    this.literals = this.literalsService.getLiterals();
  }
  ngOnInit(): void{
    this.configService.loadAppConfig();
  }

  getSuppliers(page: number, pageSize: number, searchValue: string = ''): Observable<any> {
    let observable: any;
    const params = new HttpParams()
    .append('page', page.toString())
    .append('pageSize', pageSize.toString());
    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.get(`${environment.url}/pgcSupplier?${params}&FILTER=CONTAINS(companyname,'${encodeURIComponent(searchValue.toUpperCase())}') OR CONTAINS(cnpj,'${encodeURIComponent(searchValue.toUpperCase())}') OR CONTAINS(fantasyname,'${encodeURIComponent(searchValue.toUpperCase())}')`);
      return observable;
    } else {
      return this.http.get(`${environmentProtheus.url}/pgcSupplier?${params}&FILTER=CONTAINS(companyname,'${encodeURIComponent(searchValue.toUpperCase())}') OR CONTAINS(cnpj,'${encodeURIComponent(searchValue.toUpperCase())}')OR CONTAINS(fantasyname,'${encodeURIComponent(searchValue.toUpperCase())}')`);
    }
  }

  getSupplierById(supplierCode: string, store: string): Observable<any> {
    let observable: any;
    const params = new HttpParams()
    .append('page'    , 1)
    .append('pageSize', 1)
    .append('a2_cod'  , `'${supplierCode}'`)
    .append('a2_loja' , `'${store}'`);

    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.get(`${environment.urlFrame}/genericList?alias=SA2&${params}`);
      return observable;
    } else {
      return this.http.get(`${environmentProtheus.urlFrame}/genericList?alias=SA2&${params}`);
    }
  }

  getLastSuppliers(productCodeList:Array<any>, page: number, pageSize: number, searchValue: string = ''): Observable<any> {
    let observable: any;
    const params = new HttpParams()
    .append('page', page.toString())
    .append('pageSize', pageSize.toString());
    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.post(`${environment.url}/pgcSupplier/lastSuppliers?${params}&FILTER=CONTAINS(companyname,'${encodeURIComponent(searchValue.toUpperCase())}') OR CONTAINS(cnpj,'${searchValue.toUpperCase()}')`, {body:{'products':productCodeList,'quantity':100}});
      return observable;
    } else {
      return this.http.post(`${environmentProtheus.url}/pgcSupplier/lastSuppliers?${params}&FILTER=CONTAINS(companyname,'${encodeURIComponent(searchValue.toUpperCase())}') OR CONTAINS(cnpj,'${searchValue.toUpperCase()}')`, { "products":  productCodeList,  "quantity": 100 } );
    }
  }

  returnColumnsProductGroupsServedTable(): Array<PoTableColumn> {
    return [
      {
        property: 'groupcode',
        width: '60px',
        type: 'string',
        label: this.literals?.str0119,
        visible: true,
      },
      {
        property: 'groupdescription',
        width: '60px',
        type: 'string',
        label: this.literals?.str0120,
        visible: true,
      },
      {
        property: 'registrantproducts',
        width: '80px',
        type: 'string',
        label: this.literals?.str0121,
        visible: true,
      },
    ];
  }

  returnColumnsInvalidPurchaseRequestsTable(): Array<PoTableColumn> {
    return [
      {
        property: 'errormessage',
        label: this.literals?.str0223,
        width: '150px'
      },
      {
        property: 'c1_num',
        label: this.literals?.str0015,
        width: '80px'
      },
      {
        property: 'c1_item',
        label: this.literals?.str0016,
        width: '70px'
      },
      {
        property: 'c1_produto',
        label: this.literals?.str0017,
        width: '80px'
      },
      {
        property: 'c1_descri',
        label: this.literals?.str0018,
        width: '90px'
      },
      {
        property: 'c1_um',
        label: this.literals?.str0020,
        width: '150px',
      },
    ];
  }

  returnColumnsInvalidItemsTable(): Array<PoTableColumn> {
    return [
      {
        property: 'productcode',
        type: 'string',
        label: this.literals?.str0119,
      },
      {
        property: 'productdescription',
        type: 'string',
        label: this.literals?.str0120,
      },
      {
        property: 'needdate',
        type: 'string',
        label: this.literals?.str0019,
      }
    ];
  }

  updateContact(contact: any): Observable<any> {
    let observable: any;

    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.put(`${environment.url}/pgcSupplier/updateContacts`, { body: contact })
      return observable;
    } else {
      return this.http.put(`${environmentProtheus.url}/pgcSupplier/updateContacts`, contact);
    }
  }

  getFields(): void {
    let res = this.temporaryStorageService.getValue('fieldsPropertiesSC1');
    if (res) {
      this.minFraction = res.items[0].decimal.toString();
      this.maxFraction = res.items[0].length.toString();
    }
  }

}
