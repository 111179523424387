import { Injectable, OnInit } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { LiteralsWords } from 'src/app/i18n/literals.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MingleService } from '@totvs/mingle';
import { LoginService } from 'src/app/shared/services/login.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentConditionService{

  literals: LiteralsWords;

  behaviorLoading = new BehaviorSubject<boolean>(false);
  poModal: any;
  poTable: any;

  constructor(
    private literalsService: LiteralsService,
    private http: HttpClient,
    private mingleService: MingleService,
    private loginService: LoginService
    ) {

    this.literals = this.literalsService.getLiterals();

  }

  getPaymentCondition(page, pageSize): Observable<any> {
    let observable: any;

    const params = new HttpParams()
      .append('page', page.toString())
      .append('pageSize', pageSize.toString())

    if (!this.loginService.isProtheusLogged()) {
      observable  = this.mingleService.gateway.get((`api/framework/v1/genericList?alias=SE4&${params}`));
      
      return observable;
    } else {
      const params = new HttpParams()
        .append('page', page.toString())
        .append('pageSize', pageSize.toString());
      return this.http.get(`/api/framework/v1/genericList?alias=SE4&${params}`);
    }
  }

}