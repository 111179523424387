import { Router } from '@angular/router';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, BehaviorSubject, filter } from 'rxjs';
import { PoModalComponent, PoNotificationService, PoTableColumn, PoModalAction, PoDisclaimerGroupRemoveAction, PoI18nService, PoNotification, PoTableComponent } from '@po-ui/ng-components';
import { OnDestroyClass } from 'src/app/shared/utils/on-destroy-class';
import { PurchaseListService } from './../shared/services/purchase-list.service';
import { PurchaseRequestsService } from './../shared/services/purchase-requests.service';
import { LiteralsService } from './../../../shared/services/literals-service';
import { ItemAppend } from './../shared/models/item-append.model';
import { PurchaseRequest } from 'src/app/features/purchase-requests/shared/models/purchase-requests.model';
import { DateHandler } from 'src/app/shared/helper/date-handler';
import { LiteralsWords } from './../../../i18n/literals.model';
import { SearchFilter } from '../shared/models/search-filter.model';
import { MingleService } from '@totvs/mingle';
import { Utils } from 'src/app/shared/helper/utils';
import { PgcProfileItem } from 'src/app/shared/models/pgc-profile.model';
import { TemporaryStorageService } from 'src/app/shared/services/temporary-storage.service';

@Component({
  selector: 'tgc-purchase-requests-list',
  templateUrl: './purchase-requests-list.component.html',
  styleUrls: ['./purchase-requests-list.component.css'],
})
export class PurchaseRequestsListComponent
  extends OnDestroyClass
  implements OnInit, OnDestroyClass
{
  @Input() loadingSearch: boolean = true;

  @ViewChild('appendModal', { static: true }) appendModal: PoModalComponent;
  @ViewChild('itemsSelectedModal', { static: true }) itemsSelectedModal: PoModalComponent;
  @ViewChild('invalidItemsModal', { static: false }) invalidItemsModal: PoModalComponent;
  @ViewChild('OnlyInvalidItemsModal', { static: false }) OnlyInvalidItemsModal: PoModalComponent;
  @ViewChild('diffProductGroupsModal', { static: true }) diffProductGroupsModal: PoModalComponent;
  @ViewChildren('table') tables: QueryList<PoTableComponent>; ;  

  openTotal: number;
  partiallyTotal: number;
  attendedTotal: number;

  disableForm = new BehaviorSubject<boolean>(false);

  loading: boolean = false;
  loadingCart: boolean = false;

  searchOpenForm: FormGroup;
  searchPartiallyAttendedForm: FormGroup;
  searchAttendedForm: FormGroup;

  filterOpenForm: FormGroup;
  filterPartiallyAttendedForm: FormGroup;
  filterAttendedForm: FormGroup;

  advancedFilterOpenForm: FormGroup;
  advancedFilterPartiallyAttendedForm: FormGroup;
  advancedFilterAttendedForm: FormGroup;

  hasMoreOpenItems: boolean = false;
  hasMorePartiallyAttendedItems: boolean = false;
  hasMoreAttendedItems: boolean = false;
  hasMoreAppends: boolean = false;

  pageOpenItems: number = 1;
  pagePartiallyItems: number = 1;
  pageAttendedItems: number = 1;
  pageAppends: number = 1;

  pageSize: number = 20;
  debounce: Subject<string> = new Subject<string>();

  openItems: Array<any>;
  partiallyAttendedItems: Array<any>;
  attendedItems: Array<any>;

  openItemsInitial: Array<any>;
  partiallyAttendedItemsInitial: Array<any>;
  attendedItemsInitial: Array<any>;

  inputSearch: string = '';

  columns: Array<PoTableColumn>;
  requestStatus: number = 0;

  quotationItems: Array<any> = [];

  appendItem: PurchaseRequest;
  appendItems: ItemAppend;
  appendColumns: Array<PoTableColumn>;
  isLoading: boolean = false;
  isLoadingAppendTable: boolean = false;
  isLoadingModalAppends: boolean = false;
  downloadLoading: boolean = false;
  validateDiffProductGroups: boolean = false;
  literals: LiteralsWords = this.literalsService.getLiterals();

  openItemsStartDate: string = '';
  openItemsEndDate: string = '';
  openItemsSearch: string = '';
  openItemsAdvancedQueryFilter: string = '';

  partiallyAttendedItemsStartDate: string = '';
  partiallyAttendedItemsEndDate: string = '';
  partiallyAttendedItemsSearch: string = '';
  partiallyAttendedItemsAdvancedQueryFilter: string = '';

  attendedItemsStartDate: string = '';
  attendedItemsEndDate: string = '';
  attendedItemsSearch: string = '';
  attendedItemsAdvancedQueryFilter: string = '';

  messageDiffProductGroups: string = '';
  profile: PgcProfileItem;
  user;

  itemsSelectedColumns: Array<PoTableColumn>;
  itemsSelected: Array<any> = [];
  invalidItems: Array<any>;

  columnsInvalidItemsGrid: Array<PoTableColumn>;

  primaryDiffProductGroups: PoModalAction;

  firstSelectedItem: Object = {};

  constructor(
    private purchaseRequestsService: PurchaseRequestsService,
    private poNotification: PoNotificationService,
    private formBuilder: FormBuilder,
    private purchaseListService: PurchaseListService,
    private literalsService: LiteralsService,
    private router: Router,
    private mingleService: MingleService,
    private dateHandler: DateHandler,
    private utils: Utils,
    private temporaryStorageService: TemporaryStorageService
  ) {
    super();

    // const dateHandler = new DateHandler();

    this.searchOpenForm = this.formBuilder.group({
      date: [null],
      search: [''],
    });
    this.searchPartiallyAttendedForm = this.formBuilder.group({
      date: [null],
      search: [''],
    });
    this.searchAttendedForm = this.formBuilder.group({
      date: [null],
      search: [''],
    });

    this.advancedFilterOpenForm = this.formBuilder.group({
      c1_num: [null],
      c1_solicit: [null],
      c1_descri: [null],
      b1_grupo: [null],
      bm_desc: [null],
      c1_filent: [null],
      c1_filial: [null],
      c1_cc: [null],
      c1_conta: [null],
      c1_itemcta: [null],
      c1_clvl: [null],
    });
    this.advancedFilterPartiallyAttendedForm = this.formBuilder.group({
      c1_num: [null],
      c1_solicit: [null],
      c1_descri: [null],
      b1_grupo: [null],
      bm_desc: [null],
      c1_filent: [null],
      c1_filial: [null],
      c1_cc: [null],
      c1_conta: [null],
      c1_itemcta: [null],
      c1_clvl: [null],
    });
    this.advancedFilterAttendedForm = this.formBuilder.group({
      c1_num: [null],
      c1_solicit: [null],
      c1_descri: [null],
      b1_grupo: [null],
      bm_desc: [null],
      c1_filent: [null],
      c1_filial: [null],
      c1_cc: [null],
      c1_conta: [null],
      c1_itemcta: [null],
      c1_clvl: [null],
    });

    this.filterOpenForm = this.formBuilder.group({
      searchForm: this.searchOpenForm,
      advancedFilterForm: this.advancedFilterOpenForm,
    });
    this.filterPartiallyAttendedForm = this.formBuilder.group({
      searchForm: this.searchPartiallyAttendedForm,
      advancedFilterForm: this.advancedFilterPartiallyAttendedForm,
    });
    this.filterAttendedForm = this.formBuilder.group({
      searchForm: this.searchAttendedForm,
      advancedFilterForm: this.advancedFilterAttendedForm,
    });

    this.columnsInvalidItemsGrid = [
      {
        property: 'errormessage',
        label: this.literals?.str0223,
        width: '250px',
      },
      {
        property: 'c1_num',
        label: this.literals?.str0015,
        width: '160px',
      },
      {
        property: 'c1_item',
        label: this.literals?.str0016,
        width: '150px',
      },
      {
        property: 'c1_produto',
        label: this.literals?.str0017,
        width: '200px',
      },
      {
        property: 'c1_descri',
        label: this.literals?.str0018,
        width: '150px',
      },
      {
        property: 'c1_um',
        label: this.literals?.str0020,
        width: '150px',
      },
    ];

    this.primaryDiffProductGroups = {
      action: () => {
        this.diffProductGroupsModal.close();
        this.removeItemsDiffProductGroups();
      },
      label: this.literalsService.literals.str0092, //Fechar
    };
  }

  ngOnInit(): void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);
    this.requestColumns();
    this.utils.setProfileToColumns('columnsPurchase', this.columns);

    this.initialComponent();
    this.clearSessionStorage();

    this.purchaseRequestsService.behaviorRemoveSelectedItem.subscribe(
      (value) => {
        if (value !== undefined) {
          this.handleRemoveItem(value);
        }
      }
    );

    this.profile = this.utils.getProfile();
    this.validateDiffProductGroups = this.profile.productGroup;
    this.user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '{}';

    this.purchaseRequestsService.behaviorOpenSelectedItemsModal.subscribe(
      (status) => {
        if (status !== undefined) this.handleOpenModalItemsSelected();
      }
    );

    this.purchaseRequestsService.behaviorChangeBranch.subscribe((branch) => {
      if (branch !== undefined) this.initialComponent();
    });
  }

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  primaryActionAppendModal: PoModalAction = {
    action: () => {
      this.appendItems.items = [];
      this.appendModal.close();
    },
    label: this.literals.str0092,
  };

  primaryActionItemsSelectedModal: PoModalAction = {
    action: () => {
      this.itemsSelected = [];
      this.itemsSelectedModal.close();
    },
    label: this.literals.str0092,
  };

  changeStatus(tab: number) {
    this.requestStatus = tab;
    this.inputSearch = '';
  }

  clearSessionStorage(): void {
    this.temporaryStorageService.clearValue('quotationItems');
    this.temporaryStorageService.clearValue('formRequestedProducts');
    this.temporaryStorageService.clearValue('formQuoteData');
    this.temporaryStorageService.clearValue('quotationCode');
    this.temporaryStorageService.clearValue('proposals');
    this.temporaryStorageService.clearValue('hasAttachment');
  }

  initialComponent(inputSearch = '', advancedFilterForm = ''):void {
    const defaultHeaders = this.utils.getHeaders();
    this.mingleService.setDefaultHeaders(defaultHeaders);

    this.getOpenItems(inputSearch, advancedFilterForm);

    this.getPartiallyAttendedItems(inputSearch, advancedFilterForm);
    this.getAttendedItems(inputSearch, advancedFilterForm);
  }

  // Carregamento de colunas tabelas de necessidade de compras
  requestColumns() {
    this.columns = this.purchaseRequestsService.returnColumnsTable();
    
   // Adiciona coluna de anexo 
    const attachmentColumnExists = this.columns.some(column => column.property === 'ac9_codent');

      if (!attachmentColumnExists) {
        this.columns.push(
          {
            property: 'ac9_codent',//'ac9_codent',
            width: '150px',
            type: 'icon',
            label: this.literals?.str0023,
            visible: true,
            icons: [
              {
                action: (value) => {
                  this.openAttachList(value);
                },
                color: 'color-01',
                icon: 'po-icon-attach',
                tooltip: this.literals?.str0050,
                value: 'true',
              }],
          } );    
      }
    return this.columns;
  }

  //-- Abre o modal de listagem de anexos
  openAttachList(value){
    this.isLoadingAppendTable = true;
    this.appendColumns = this.purchaseRequestsService.returnAppendColumnsTable();
    this.appendColumns.push(
      {
        property: 'append',
        width: '80px',
        type: 'icon',
        label: this.literals?.str0049,
        visible: true,
        icons: [
          {
            action: (value) => {
              this.downloadAttach(value);
            },
            color: 'color-01',
            icon: 'po-icon-download',
            tooltip: this.literals?.str0149,
            value: 'true',
          },
        ],
      }
    )
    this.returnAttachList(value)
    this.appendModal.open()
  }

  getOpenItems(startDate: string = '', endDate: string = '', search: string = '', advancedFilterQuery: string = ''): void {
    this.loading = true;
    this.pageOpenItems = 1;
    this.purchaseListService
    .getOpenItems(
      this.pageOpenItems,
      this.pageSize,
      search,
      advancedFilterQuery,
      startDate,
      endDate
    )
    .subscribe({
      next: (value: any) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == ""
            ? (element.ac9_codent = 'false')
            : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value?.items);
        this.openItems = value?.items;
        this.hasMoreOpenItems = true ? value.hasNext : false;
        this.openTotal = value.remainingRecords + this.openItems.length;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getMoreOpenItems(): void {
    this.loading = true;
    if (this.hasMoreOpenItems) this.pageOpenItems++;
    this.purchaseListService
    .getOpenItems(
      this.pageOpenItems,
      this.pageSize,
      this.openItemsSearch,
      this.openItemsAdvancedQueryFilter,
      this.openItemsStartDate,
      this.openItemsEndDate
    )
    .subscribe({
      next: (value) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == ""
            ? (element.ac9_codent = 'false')
            : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.openItems = this.openItems.concat(value.items);
        this.disableForm.next(false);
        this.hasMoreOpenItems = true ? value.hasNext : false;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getPartiallyAttendedItems(
    startDate: string = '',
    endDate: string = '',
    search: string = '',
    advancedFilterQuery: string = ''
  ) {
    this.loading = true;
    this.pagePartiallyItems = 1;
    this.purchaseListService
    .getPartiallyAttended(
      this.pagePartiallyItems,
      this.pageSize,
      search,
      advancedFilterQuery,
      startDate,
      endDate
    )
    .subscribe({
      next: (value) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == ""
            ? (element.ac9_codent = 'false')
            : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.partiallyAttendedItems = value.items;
        this.hasMorePartiallyAttendedItems = true ? value.hasNext : false;
        this.partiallyTotal = value.remainingRecords + this.partiallyAttendedItems.length;
        if (this.hasMorePartiallyAttendedItems) this.pagePartiallyItems++;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getMorePartiallyAttendedItems(): void {
    this.loading = true;
    if (this.hasMorePartiallyAttendedItems) this.pagePartiallyItems++;
    this.purchaseListService
    .getPartiallyAttended(this.pagePartiallyItems, this.pageSize, this.partiallyAttendedItemsSearch, this.partiallyAttendedItemsAdvancedQueryFilter, this.partiallyAttendedItemsStartDate, this.partiallyAttendedItemsEndDate)
    .subscribe({
      next: (value) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == "" ? (element.ac9_codent = 'false') : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.partiallyAttendedItems = this.partiallyAttendedItems.concat(value.items);
        this.hasMorePartiallyAttendedItems = true ? value.hasNext : false;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getAttendedItems(startDate: string = '', endDate: string = '', search: string = '', advancedFilterQuery: string = '') {
    this.loading = true;
    this.pageAttendedItems = 1;
    this.purchaseListService
    .getAttended(this.pageAttendedItems, this.pageSize, search, advancedFilterQuery, startDate, endDate)
    .subscribe({
      next: (value: any) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == "" ? (element.ac9_codent = 'false') : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.attendedItems = value.items;
        this.hasMoreAttendedItems = true ? value.hasNext : false;
        this.attendedTotal = value.remainingRecords + this.attendedItems.length;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getMoreAttendedItems(startDate: string = '', endDate: string = '', search: string = '', advancedFilterQuery: string = ''): void {
    this.loading = true;
    if (this.hasMoreAttendedItems) this.pageAttendedItems++;
    this.purchaseListService
    .getAttended(this.pageAttendedItems, this.pageSize, search, advancedFilterQuery, startDate, endDate)
    .subscribe({
      next: (value) => {
        this.loading = false;
        value?.items?.forEach((element) => {
          element.ac9_codent.trim() == "" ? (element.ac9_codent = 'false') : (element.ac9_codent = 'true');
          element['remove'] = 'true';
        });
        this.handleDatesItemsReceived(value.items);
        this.attendedItems = this.attendedItems.concat(value.items);
        this.hasMoreAttendedItems = true ? value.hasNext : false;
      },
      error: (error) => {
        this.loading = false;
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })
  }

  getMoreAppends(): void {
    this.isLoadingAppendTable = true;
    this.pageAppends ++
    this.purchaseListService
      .getAppendItems(this.pageAppends, this.pageSize, this.appendItem.requestcode, this.appendItem.item)
      .subscribe((items: ItemAppend) => {
        this.appendItems.items = this.appendItems.items.concat(items.items);
        this.appendItems.items.forEach((element) => {
          element.append = 'true';
        });
        this.hasMoreAppends = items.hasNext;
        this.isLoadingAppendTable = false;
      },
      (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error));
      });
  }

  handleDatesItemsReceived(items: Array<any>) {
    let dateHandler = new DateHandler();
    items.map(
      (items) =>
        (items.c1_datprf = dateHandler.fromProtheusToPOUI(items.c1_datprf))
    );
    
    //Trata campos tipo data no browser.
    let structBrowserSC1 = this.utils.getBrowserFields('structSC1', 'SC1');
    const dateFields = structBrowserSC1.filter((field)=> field.type == 'D')
    if (items != undefined){
      if(dateFields != undefined && dateFields.length > 0){
        dateFields.forEach((field)=>{
          let fieldName = field.field.toLowerCase();
          if (fieldName != 'c1_datprf'){
            items?.map(value =>
              value[fieldName] = this.dateHandler.fromProtheusDateToStringPoUi(value[fieldName]));
          }
        });
      }
    }
    
  }

  convertToProtheusDate(startDate: string, endDate: string): Array<string> {
    let dateHandler = new DateHandler();
    const startDateProtheus = dateHandler.convertToProtheusDate(
      dateHandler.fromPOUIDatepickerToDate(startDate)
    );
    const endDateProtheus = dateHandler.convertToProtheusDate(
      dateHandler.fromPOUIDatepickerToDate(endDate)
    );
    return [startDateProtheus, endDateProtheus];
  }

  handleChangeInputDateFilter(searchForm: SearchFilter): void {
    this.clearHasMoreFilterParameters();
    switch (this.requestStatus) {
      case 0:
        this.filterOpenForm.controls.searchForm.setValue(searchForm);
        this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString());
        this.filterOpenForm.controls['searchForm'].value.date.start ? (this.filterOpenForm.controls['searchForm'].value.date.start = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[0]) : null;
        this.filterOpenForm.controls['searchForm'].value.date.end ? (this.filterOpenForm.controls['searchForm'].value.date.end = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[1]) : null;
        this.generateFilterQuery(this.filterOpenForm);
        break;
      case 1:
        this.filterPartiallyAttendedForm.controls.searchForm.setValue(searchForm);
        this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString());
        this.filterPartiallyAttendedForm.controls['searchForm'].value.date.start ? (this.filterPartiallyAttendedForm.controls['searchForm'].value.date.start = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[0]) : null;
        this.filterPartiallyAttendedForm.controls['searchForm'].value.date.end ? (this.filterPartiallyAttendedForm.controls['searchForm'].value.date.end = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[1]) : null;
        this.generateFilterQuery(this.filterPartiallyAttendedForm);
        break;
      case 2:
        this.filterAttendedForm.controls.searchForm.setValue(searchForm);
        this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString());
        this.filterAttendedForm.controls['searchForm'].value.date.start ? (this.filterAttendedForm.controls['searchForm'].value.date.start = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[0]) : null;
        this.filterAttendedForm.controls['searchForm'].value.date.end ? (this.filterAttendedForm.controls['searchForm'].value.date.end = this.convertToProtheusDate(searchForm.date.start.toString(), searchForm.date.end.toString())[1]) : null;
        this.generateFilterQuery(this.filterAttendedForm);
        break;
    }
  }

  handleInputSearchFilter(inputSearch: string) {
    this.clearHasMoreFilterParameters();
    switch (this.requestStatus) {
      case 0:
        this.filterOpenForm.controls['searchForm'].value.search = inputSearch;
        this.generateFilterQuery(this.filterOpenForm);
        break;
      case 1:
        this.filterPartiallyAttendedForm.controls['searchForm'].value.search =
          inputSearch;
        this.generateFilterQuery(this.filterPartiallyAttendedForm);
        break;
      case 2:
        this.filterAttendedForm.controls['searchForm'].value.search =
          inputSearch;
        this.generateFilterQuery(this.filterAttendedForm);
        break;
    }
  }

  handleAdvancedFilterForm(advancedFilterForm: any): void {
    this.clearHasMoreFilterParameters();
    switch (this.requestStatus) {
      case 0:
        this.advancedFilterOpenForm.patchValue(advancedFilterForm.value);
        this.generateFilterQuery(this.filterOpenForm);
        break;
      case 1:
        this.advancedFilterPartiallyAttendedForm.patchValue(
          advancedFilterForm.value
        );
        this.generateFilterQuery(this.filterPartiallyAttendedForm);
        break;
      case 2:
        this.advancedFilterAttendedForm.patchValue(advancedFilterForm.value);
        this.generateFilterQuery(this.filterAttendedForm);
        break;
    }
  }

  handleSwitchValidateProductGroup($event): void {
    this.validateDiffProductGroups = $event;
    this.openDiffProductGroupsModal();
    this.needUpdateProfile();
  }

  generateFilterQuery(filterForm: FormGroup): void {
    let advancedFilterQuery: string = '';
    let date = filterForm?.controls?.searchForm?.value?.date;
    let search = filterForm?.controls?.searchForm?.value?.search?.toUpperCase();
    const array: Array<any> = Object.keys(filterForm?.controls?.advancedFilterForm?.value).map((key) => [key, filterForm?.controls?.advancedFilterForm?.value[key]]);
    const newArray = array.filter((element) => element[1] !== null && element[1] !== '');

    newArray?.forEach((element) => {
      advancedFilterQuery =
        advancedFilterQuery +
        `(${element[0]}, '${
          element[0] !== 'c1_solicit' ? element[1].toUpperCase() : element[1]
        }') AND contains`;
    });

    advancedFilterQuery = advancedFilterQuery.substring(
      0,
      advancedFilterQuery.length - 12
    );

    switch (this.requestStatus) {
      case 0:
        this.generateHasMoreFilterParameters(
          date?.start,
          date?.end,
          search,
          advancedFilterQuery
        );
        this.getOpenItems(date?.start, date?.end, search, advancedFilterQuery);
        break;
      case 1:
        this.generateHasMoreFilterParameters(
          date?.start,
          date?.end,
          search,
          advancedFilterQuery
        );
        this.getPartiallyAttendedItems(
          date?.start,
          date?.end,
          search,
          advancedFilterQuery
        );
        break;
      case 2:
        this.generateHasMoreFilterParameters(
          date?.start,
          date?.end,
          search,
          advancedFilterQuery
        );
        this.getAttendedItems(
          date?.start,
          date?.end,
          search,
          advancedFilterQuery
        );
        break;
    }
  }

  generateHasMoreFilterParameters(
    startDate,
    endDate,
    search,
    advancedFilterQuery
  ): void {
    switch (this.requestStatus) {
      case 0:
        this.openItemsStartDate = startDate;
        this.openItemsEndDate = endDate;
        this.openItemsSearch = search;
        this.openItemsAdvancedQueryFilter = advancedFilterQuery;
        break;
      case 1:
        this.partiallyAttendedItemsStartDate = startDate;
        this.partiallyAttendedItemsEndDate = endDate;
        this.partiallyAttendedItemsSearch = search;
        this.partiallyAttendedItemsAdvancedQueryFilter = advancedFilterQuery;
        break;
      case 2:
        this.attendedItemsStartDate = startDate;
        this.attendedItemsEndDate = endDate;
        this.attendedItemsSearch = search;
        this.attendedItemsAdvancedQueryFilter = advancedFilterQuery;
        break;
    }
  }

  clearHasMoreFilterParameters(): void {
    switch (this.requestStatus) {
      case 0:
        this.openItemsStartDate = '';
        this.openItemsEndDate = '';
        this.openItemsSearch = '';
        this.openItemsAdvancedQueryFilter = '';
        break;
      case 1:
        this.partiallyAttendedItemsStartDate = '';
        this.partiallyAttendedItemsEndDate = '';
        this.partiallyAttendedItemsSearch = '';
        this.partiallyAttendedItemsAdvancedQueryFilter = '';
        break;
      case 2:
        this.attendedItemsStartDate = '';
        this.attendedItemsEndDate = '';
        this.attendedItemsSearch = '';
        this.attendedItemsAdvancedQueryFilter = '';
        break;
    }
  }

  handleChangeInputAdvancedFilter(event: PoDisclaimerGroupRemoveAction): void {
    switch (this.requestStatus) {
      case 0:
        this.advancedFilterOpenForm.controls[event?.removedDisclaimer?.property].reset();
        this.generateFilterQuery(this.filterOpenForm);
        break;
      case 1:
        this.advancedFilterPartiallyAttendedForm.controls[event?.removedDisclaimer?.property].reset();
        this.generateFilterQuery(this.filterPartiallyAttendedForm);
        break;
      case 2:
        this.advancedFilterAttendedForm.controls[event?.removedDisclaimer?.property].reset();
        this.generateFilterQuery(this.filterAttendedForm);
        break;
    }
  }

  handleSelectItem(item: any): void {
    let isDuplicated = this.quotationItems.some((element) => JSON.stringify(item) === JSON.stringify(element));
    if (!isDuplicated) {
      this.quotationItems.push(item);

      if (this.quotationItems.length === 1) { //-- Primeiro item selecionado
        this.firstSelectedItem = item;
      }
    } else {
      this.poNotification.warning(this.literals?.str0412);
      let index = this.openItems.indexOf(item);
      this.openItems.splice(index, 1);
    }
    this.openDiffProductGroupsModal();
  }

  handleRemoveItem(item: any): void {
    this.handleUnselectItem(item);
  }

  handleUnselectItem(item: any): void {
    this.loadingCart = true;
    let index: number = this.quotationItems.indexOf(item);
  
    if (index >= 0) {
      this.quotationItems.splice(index, 1);
      this.itemsSelected = [...this.quotationItems];
      if (this.openItems) {
        let key: string = '';
        index = this.openItems?.indexOf(item);
  
        if (index === -1) {
          key = item?.c1_filial + item?.c1_num + item?.item + item?.griditem;
          index = this.openItems?.findIndex(element => element?.c1_filial + element?.c1_num + element?.item + element?.griditem === key);
        }
  
        item.$selected = false;
        this.openItems[index] = item;
      }
    }
  
    if (this.quotationItems.length) { //-- Mantém o objeto firstSelectedItem sempre com o primeiro item selecionado
      this.firstSelectedItem = this.quotationItems[0];
    } else {
      this.firstSelectedItem = {};
    }
  
    // Adicionando um atraso antes de definir loadingCart como false
    setTimeout(() => {
      this.loadingCart = false;
    }, 200); 
  } 

  handleSelectAllItems(items): void {
    let selectedItems: Array<PoTableColumn> = [];
    this.quotationItems = [];
    items?.forEach((element) => {
      this.quotationItems.push(element?.$selected === false);
      selectedItems.push(element);
    });
    this.quotationItems = selectedItems;
    this.purchaseRequestsService.handleNotificationsNumber(this.quotationItems.length);

    this.openDiffProductGroupsModal();
  }

  handleUnselectAllItems(): void {
    this.quotationItems = [];
    this.firstSelectedItem = {};
    this.purchaseRequestsService.handleNotificationsNumber(this.quotationItems.length);
  }

  //-- Função que atualiza o profile
  needUpdateProfile(columns?: Array<PoTableColumn>): void {
    this.profile = this.utils.getProfile();
    this.profile.productGroup = this.validateDiffProductGroups;
    if (columns) {
      this.profile.columnsPurchase = this.utils.getTablePropertiesToProfile(columns);
    }
    this.utils.updateProfile(this.profile);
  }

  startQuote(): void {
    this.quotationItems.forEach((element) => {
      element.requestrecno = 0;
    });
    this.loading = true;
    this.purchaseRequestsService.agglutinateQuotations(this.quotationItems)
    .subscribe({
      next: (response) => {
        this.loading = false;
        this.temporaryStorageService.setValue('quotationItems', response);
        let quotationItems = response;
        if (response.items.length === 0 && response.invaliditems.length > 0) {
          this.invalidItems = quotationItems.invaliditems;
          this.invalidItems.forEach(element => {
            element.c1_datprf = this.dateHandler.convertStringToProtheusDate(element.c1_datprf);
          });
          this.showNotificationOnlyInvalid();
        }
        else if (response.invaliditems.length > 0 && response.items.length > 0 ) {
          this.invalidItems = quotationItems.invaliditems;
          this.invalidItems.forEach((element) => {
            element.c1_datprf = this.dateHandler.convertStringToProtheusDate(
              element.c1_datprf
            );
          });
          this.invalidItemsModal.open();
        } 
        else {
          this.router.navigate(
            ['/necessidade-de-compra/solicitacao-de-cotacao'],
            { state: { quotationItems } }
          );
        }
      },
      error: (error) => {
        this.poNotification.error(this.utils.getErrorMessage(error));
      }
    })  
  }

  //-- Valida se existe grupos de produtos diferentes na inicialização da cotação
  openDiffProductGroupsModal(): void {
    let hasDiffGroup: boolean = false;
    
    if (this.validateDiffProductGroups) {
      for (let i = 0; i < this.quotationItems.length - 1; i++) {
        const currentGroup = this.quotationItems[i]?.b1_grupo;
        const nextGroup = this.quotationItems[i+1]?.b1_grupo;

        if (currentGroup != nextGroup) {
          hasDiffGroup = true;
          break;
        }
      }

      if (hasDiffGroup) {
        let firstGroup: string;
        let firstGroupDescription: string;

        if (this.firstSelectedItem.hasOwnProperty('b1_grupo') && this.firstSelectedItem.hasOwnProperty('bm_desc')) {
          firstGroup = this.firstSelectedItem['b1_grupo'];
          firstGroupDescription= this.firstSelectedItem['bm_desc'];
        } else {
          firstGroup = this.quotationItems[0]?.b1_grupo;
          firstGroupDescription = this.quotationItems[0]?.bm_desc;
        }

        if (!firstGroup?.trim() && !firstGroupDescription?.trim()) {
          this.messageDiffProductGroups = this.literals?.str0446
          this.diffProductGroupsModal.open();
        } else {
          const groupKey: string = `${firstGroup?.trim()} - ${firstGroupDescription?.trim()}`
          this.messageDiffProductGroups = `${this.literals?.str0447} "${groupKey}".`;
          this.diffProductGroupsModal.open();
        }
      }
    }
  }

  //-- Remove itens de grupos de produtos diferentes do primeiro selecionado.
  removeItemsDiffProductGroups() : void {
    let firstGroup: string;

    if (this.firstSelectedItem.hasOwnProperty('b1_grupo')) {
      firstGroup = this.firstSelectedItem['b1_grupo'];
    } else {
      firstGroup = this.quotationItems[0]?.b1_grupo;
    }

    let foundItem: boolean = true;

    while (foundItem) { //-- Remove itens enquanto encontrar itens de grupos diferentes do primeiro selecionado.
      let item = this.quotationItems.find((element) => element?.b1_grupo !== firstGroup);

      if (item) {
        this.handleUnselectItem(item);
        foundItem = true;
      } else {
        foundItem = false;
      }
    }
  }

  showNotificationOnlyInvalid() {
    const poNotification: PoNotification = {
      message: this.literals.str0230,
      actionLabel: this.literals.str0167,
      action: () => this.OnlyInvalidItemsModal.open(),
    };
    this.poNotification.warning(poNotification);
  }

  downloadPDF(pdf): void {
    const linkSource = `data:application/pdf;base64,${pdf.file}`;
    const downloadLink = document.createElement('a');
    const fileName = `${pdf.name}`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  handleRemoveAllFilters(): void {
    switch (this.requestStatus) {
      case 0:
        this.getOpenItems('', '', '', '');
        break;
      case 1:
        this.getPartiallyAttendedItems('', '', '', '');
        break;
      case 2:
        this.getAttendedItems('', '', '', '');
        break;
    }
  }

  handleOpenModalItemsSelected(): void {
    this.loadingCart = true;
    this.itemsSelectedModal.open();
    this.itemsSelectedColumns = this.purchaseRequestsService.returnItemsSelectedColumnsTable();
    this.itemsSelectedColumns.push(
      {
        property: 'ac9_codent',//'ac9_codent',
        width: '80px',
        type: 'icon',
        label: this.literals?.str0023,
        visible: true,
        icons: [
          {
            action: (value) => {
              this.openAttachList(value);
            },
            color: 'color-01',
            icon: 'po-icon-attach',
            tooltip: this.literals?.str0050,
            value: 'true',
          },
        ],
      }
    )
    this.itemsSelected = [...this.quotationItems];
    this.loadingCart = false;
  }

  showInvalidItemsQuotation(): void {
    this.invalidItemsModal.open();
  }

  primaryActionInvalidItemsModal: PoModalAction = {
    action: () => {
      this.router.navigate(['/necessidade-de-compra/solicitacao-de-cotacao']);
    },
    label: this.literals?.str0099,
  };

  SecundaryActionInvalidItemsModal: PoModalAction = {
    action: () => {
      this.invalidItemsModal.close();
    },
    label: this.literals?.str0100,
  };

  primaryOnlyInvalidItemsModal: PoModalAction = {
    action: () => {
      this.OnlyInvalidItemsModal.close();
    },
    label: this.literals?.str0092,
  };


  //-- Serviço que busca a listagem de anexos de uma cotação.
  returnAttachList(value){

      this.appendItem = value;
      this.pageAppends = 1;
      if (this.appendItem !== undefined) {
       this.purchaseListService
        .getAppendItems(1, this.pageSize, value.c1_num, value.c1_item)
        .subscribe((items: ItemAppend) => {
          this.isLoadingAppendTable = false;
          this.appendItems = items;
          this.hasMoreAppends = items.hasNext;
          this.appendItems.items.forEach((element) => {
            element.append = 'true';
          });
          },
          (error) => {
            this.isLoadingAppendTable = false;
            this.poNotification.error(this.utils.getErrorMessage(error));
          });
      }
  }

  downloadAttach(value){
    this.downloadLoading = true;
    if (value !== undefined) {
      this.purchaseListService.getPDF(value.id)
      .subscribe({
        next: (value) => {
          this.downloadPDF(value);
          this.downloadLoading = false;
        },
        error: (error) => {
          this.downloadLoading = false;
          this.poNotification.error(this.utils.getErrorMessage(error));
        }
      })
    }
  }

  // Evento disparado ao alterar colunas
  handleColumnChange(currentTable: PoTableComponent | any): void {
    this.columns = currentTable['_columns'];
    this.needUpdateProfile(currentTable['_columns']);
    this.utils.tablesColumnsChange(this.tables, currentTable['_columns']);
  }

  //-- Evento chamado ao restaurar as colunas da tabela
  onRestoreColumns(): void {
    this.columns = this.requestColumns();
    this.utils.tablesColumnsChange(this.tables, this.columns);
  }
}
