import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MingleService } from '@totvs/mingle';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environments';

let headers = new HttpHeaders().append('EnterpriseGroup', 'T2');
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  authOk = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private mingleService: MingleService
  ) { }

  isLoggedIn(): boolean {
    if (sessionStorage.getItem('user') === '' || sessionStorage.getItem('user') === null) {
      return false;
    } else {
      this.authOk.next(true);
      return true;
    }
  }

  isProtheusLogged(): boolean {
    if (sessionStorage.getItem('remoteType') !== null) {
      return true;
    } else {
      return false;
    }
  }

  getAuth(): Observable<boolean> {
    return this.authOk.asObservable();
  }

  getUserInfos(login: string): Observable<any> {
    let response;
    response = this.mingleService.gateway.get((`${environment.url}/pgcUtils/userInfo/${login}`));
    return response
  }

  isUserAdmin(): boolean {
    if (sessionStorage.getItem('userAdmin') !== null && JSON.parse(sessionStorage.getItem('userAdmin')) ) {
      return true;
    } else {
      return false;
    }
  }

  isParamBuyerActive(): boolean {
    if (sessionStorage.getItem('paramWfBuyer') !== null && JSON.parse(sessionStorage.getItem('paramWfBuyer')) ) {
      return true;
    } else {
      return false;
    }
  }

}
