import { Injectable, QueryList } from "@angular/core";
import { PoDynamicFormField, PoTableColumn, PoTableComponent } from "@po-ui/ng-components";
import { HeaderOption } from "@totvs/mingle";
import { LiteralsWords } from "src/app/i18n/literals.model";
import { LiteralsService } from "../services/literals-service";
import { PgcProfileItem } from "../models/pgc-profile.model";
import { PreferencesProfileService } from "../services/preferences-profile.service";
import { LoginService } from "../services/login.service";
import { ProSessionInfoService } from "@totvs/protheus-lib-core";
import { BehaviorSubject } from "rxjs";
import { TemporaryStorageService } from "../services/temporary-storage.service";

@Injectable({
  providedIn: 'root'
})
export class Utils {
  literals: LiteralsWords;

  constructor(
    private literalsService: LiteralsService,
    private preferencesProfileService: PreferencesProfileService,
    private loginService: LoginService,
    private proSessionInfoService: ProSessionInfoService,
    private temporaryStorageService: TemporaryStorageService
  ) {
    this.literals = this.literalsService.getLiterals();
  }

  //-- Obtém empresa
  getEnterpriseGroup() {
    if (this.loginService.isProtheusLogged()) {
      return this.proSessionInfoService.getCompany()?.Code;
    } else {
      return sessionStorage.getItem('group');
    }
  }

  //-- Obtém filial
  getBranchCode() {
    if (this.loginService.isProtheusLogged()) {
      return this.proSessionInfoService.getBranch()?.Code;
    } else {
      return sessionStorage.getItem('branch');
    }
  }

  //-- Define headers
  getHeaders(noCharset: boolean = false, isInterceptor: boolean = false, url: string = ''): any{
    const group = this.getEnterpriseGroup();
    const branch = this.getBranchCode();
    const language = localStorage.getItem('PO_DEFAULT_LANGUAGE')
    const noCharsetInterceptor = url.includes('framework/environment') || url.includes('oauth2/v1/token'); //-- URLs do frame que não permitem charset

    if (isInterceptor) {
      let headers: any = {};

      headers['tenantId'] = group + ',' + branch
      headers['Accept-Language'] = language

      if(!noCharsetInterceptor) {
        headers['Accept'] = 'charset=utf8';
      }
      return headers;

    } else {

      let headerOption: Array<HeaderOption> = [];
      if (group && branch) {
        headerOption.push({name: 'tenantId', value: group + ',' + branch})
      }
      headerOption.push({name: 'Accept-Language', value: language})

      if(!noCharset) {
        headerOption.push({name: 'Accept', value: 'charset=utf8'})
      }
      return headerOption;
    }
  }

  //-- Retorna mensagem de erro da API
  getErrorMessage(error: any): string {
    let errorMessage: string;

    errorMessage = error?.error?.errorMessage;
    errorMessage = errorMessage ? errorMessage : error?.errorMessage;
    errorMessage = errorMessage ? errorMessage : error?.response?.data?.message;
    errorMessage = errorMessage ? errorMessage : error?.response?.data?.errorMessage;
    errorMessage = errorMessage ? errorMessage : error?.message

    return errorMessage;
  }

  //-- Retorna dados o objeto de erro da API
  getErrorData(error: any): string {
    let errorData: string;

    errorData = error?.error;
    errorData = errorData ? errorData : error?.response?.data;
    errorData = errorData ? errorData : error;

    return errorData;
  }

  //-- Resgata os campos customizados de uma tabela
  //-- Retorna em formato de dynamicForm
  //-- sessionStorageItem -> nome que foi gravado a estrutura da tabela no resolver(Ex: struct)
  //-- alias -> nome da tabela da estrutura (Ex: SC1)
  //-- readOnly -> apenas leitura
  //-- optionFields -> retorna os campos desejados - 1=Todos; 2=Apenas do sistema; 3=Apenas de usuário; 4=Apenas obrigatórios
  getAllFields(sessionStorageItem: string = '', alias: string = '', readOnly: boolean = false, optionFields: number = 1): Array<PoDynamicFormField>{
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);
    let fields: Array<PoDynamicFormField> = [];
    let fieldMask = ''
    let comboOptions = [];
    let canChange = true;

    if(struct != undefined){
      let fieldsStruct = struct[alias]?.['fields'];

      if (optionFields > 1) {
        if (optionFields === 4) {
          fieldsStruct = fieldsStruct.filter((element)=>element.userField === false && element.required == true);
        } else {
          const isUserFields: boolean = optionFields === 3;
          fieldsStruct = fieldsStruct.filter((element)=>element.userField === isUserFields);
        }
      }

      if(fieldsStruct != undefined && fieldsStruct.length > 0){
        fieldsStruct.forEach((field)=>{

            //--Campos numéricos
          if(field.type == 'N'){
            fieldMask = field.picture.replace('@E','')
            canChange = field.canChange

            fields.push({
              label: field.required ? `${field.title}*` : field.title,
              property: field.field,
              required: field.required,
              minLength: 0,
              maxLength: field.decimal ? field.size + field.decimal: field.size + 1,
              decimalsLength: field.decimal,
              type: 'currency',
              //mask: fieldMask,
              gridColumns: 3,
              gridSmColumns: 3,
              readonly: !canChange,
              disabled: readOnly
            });

            //--Campos data
          }else if(field.type == 'D'){

            canChange = field.canChange

            fields.push({
              label: field.required ? `${field.title}*` : field.title,
              property: field.field,
              required: field.required,
              type: 'date',
              format: 'dd/mm/yyyy',
              gridColumns: 3,
              gridSmColumns: 3,
              readonly: !canChange,
              disabled: readOnly
            });

            //--Campos lógicos
          }else if(field.type == 'L'){
            canChange = field.canChange;
            
            fields.push(
              {
                property: field.field,
                label: field.required ? `${field.title}*` : field.title,
                gridColumns: 3,
                gridSmColumns: 3,
                type: 'boolean',
                booleanTrue: this.literals.str0099, //-- Sim
                booleanFalse: this.literals.str0100, //-- Não
                disabled: readOnly || !canChange
              });

            //--Campos combo box
          }else if(field.type == 'C' && field.combobox.length > 0){
            let option;
            let fieldCombo = field.combobox;
            canChange = field.canChange

            fieldCombo.forEach((item)=>{
              option = item.split('=')
              if(option.length == 2){
                comboOptions.push({
                  value: option[0],
                  label: option[1]
                });
              }
            });

            if(comboOptions.length > 0){
              fields.push(
                {
                  type: 'po-combo',
                  property: field.field,
                  label: field.required ? `${field.title}*` : field.title,
                  gridColumns: 3,
                  gridSmColumns: 3,
                  options: comboOptions,
                  fieldLabel: 'label',
                  fieldValue: 'value',
                  required: field.required,
                  disabled: readOnly || !canChange
                },
              );
            }

            comboOptions = [];

            //--Campos caracter
          }else{

            let gridColumnsSize = field.size > 60 ? 12 : 3
            canChange = field.canChange

            fields.push({
              type: 'input',
              label: field.required ? `${field.title}*` : field.title,
              property: field.field,
              required: field.required,
              minLength: 0,
              maxLength: field.size,
              gridColumns: gridColumnsSize,
              gridSmColumns: gridColumnsSize,
              readonly: !canChange,
              disabled: readOnly
            });

            if (field.type === 'M') {
              fields[fields.length-1].maxLength = null;
              fields[fields.length-1].gridColumns = 12;
              fields[fields.length-1].rows = 3;
              fields[fields.length-1].type = 'textarea';
            }
          }

          fields[fields.length-1]['folder'] = field.folder;

        })
      }

    }

    return fields;
  }

  //-- Resgata os campos de browser de uma tabela
  //-- sessionStorageItem -> nome que foi gravado a estrutura da tabela no resolver(Ex: structSC1)
  //-- alias -> nome da tabela da estrutura (Ex: SC1)
  getBrowserFields(sessionStorageItem: string = '', alias: string = ''){
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);

    let browserFields = [];

    if(struct != undefined){
      browserFields = struct[alias]['fields'].filter((element)=>element.browse == true && element.virtual == false);
    }

    return browserFields
  
  }

   //-- Obtém picture de um campo de acordo com a estrutura da tabela
   getFieldPicture(sessionStorageItem: string = '', alias: string = '', field: string): string {
    let picture: string = '';
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);
    const fieldStruct = struct[alias]?.['fields']?.find((element) => element?.field == field.toUpperCase());
    
    if (fieldStruct !== undefined && fieldStruct?.picture) {
      picture = fieldStruct.picture;
      picture = picture.replace('@E ', ''); //-- Remove '@E ' de campos numéricos
    }

    return picture;
  }

  getFolders(sessionStorageItem: string = '', alias: string = ''): Array<any> {
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);
    let folders: Array<any> = [];

    if(struct != undefined){
      folders = struct[alias]['folders'];
      folders.push({id: '' , title: 'Outros'});
    }

    return folders;
  }

  getFieldsFromFolder(sessionStorageItem: string = '', alias: string = '', folder: string, optionFields: number = 1, readOnly: boolean = false) {
    const fields: Array<any> = this.getAllFields(sessionStorageItem, alias, readOnly, optionFields);
    let fieldsStruct: Array<any> = [];

    fields.forEach(field => {
      if (field.folder.trim() === folder.trim()) {
        fieldsStruct.push(field);
      }
    });
    return fieldsStruct;
  }

  //-- Obtém o nome do campo
  getFieldName(sessionStorageItem: string = '', alias: string = '', field: string): string {
    let name: string = '';
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);
    const fieldStruct = struct[alias]?.['fields']?.find((element) => element?.field == field.toUpperCase());
    
    if (fieldStruct !== undefined && fieldStruct?.title) {
      name = fieldStruct.title;
    }

    return name;
  }

  //-- Obtém tamanho do campo
  getFieldSize(sessionStorageItem: string = '', alias: string = '', field: string): number {
    let size: number = 0;
    let struct = this.temporaryStorageService.getValue(sessionStorageItem);

    if (struct) {
      const fieldStruct = struct[alias]?.['fields']?.find((element) => element?.field == field.toUpperCase());
      
      if (fieldStruct !== undefined && fieldStruct?.size) {
        size = fieldStruct.size;
      }
    }

    return size;
  }

  //-- Valida os e-mails informados em um campo, eles podem ser separados por ponto e vírgula ";"
  validateEmail(emails: string): Object {
    let errorMessage: string = '';
    let isInvalid: boolean = false;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailList = emails.split(';');
  
    for (const email of emailList) {
      if (!emailPattern.test(email.trim()) && emails) {
        isInvalid = true;
        errorMessage = this.literals?.str0517; //-- Formato do campo e-mail inválido.
      }
    }
    return {invalid: isInvalid, error: errorMessage};
  }

  //-- Limpa localStorage
  cleanLocalStorage(): void {
    localStorage.clear();
  }

  // Função para remover caracteres inválidos de todos os campos de string de um objeto
  removeInvalidCharactersFromObject(object: any): any {
    const cleanedObject: any = {};

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (typeof object[key] === 'string') {
          cleanedObject[key] = object[key].replace(/\uFFFD/g, '');
        } else {
          cleanedObject[key] = object[key];
        }
      }
    }

    return cleanedObject;
  }

  // Método para converter um json do localStorage para um objeto
  parseLocalStorageItem(key: string, isSessionStorage: boolean = false): any {
    const item = isSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        console.error(`Error parsing ${key} from localStorage:`, e);
        return null;
      }
    }
    return null;
  }

  //-- Atribui dados do profile nas colunas de uma tabela.
  setProfileToColumns(sessionStoragegeItem: string, columns: Array<PoTableColumn>): void {
    let columnsProfile: Array<PoTableColumn> = this.getProfile()[sessionStoragegeItem];

    if (Array.isArray(columnsProfile) && columnsProfile.length > 0) {
      columns.forEach((column: PoTableColumn, index) => {
        const columnProfile: PoTableColumn = columnsProfile.find((element: PoTableColumn) => element.property == column.property);
        
        if (columnProfile) {
          column.visible = columnProfile.visible;
          column.fixed = columnProfile.fixed;
          column['order'] = columnProfile['order'] || columnProfile['order'] === 0 ? columnProfile['order'] : index;
        }
      });

      columns.sort((column1, column2) => column1['order'] - column2['order']); //-- Ordena colunas de acordo com o profile
    }
  }

  //-- Obtém profile do sessionStorage
  getProfile(): PgcProfileItem {
    let profileItems: Array<PgcProfileItem> = this.temporaryStorageService.getValue('profile');
    let validProfile: boolean = false;

    if (profileItems) {
      validProfile = (profileItems && profileItems.length > 0 && Object.keys(profileItems[0]).length > 0)
    }

    if (validProfile) { //-- Garante que todas propriedades existirão
      profileItems[0].productGroup          = !!profileItems[0].productGroup
      profileItems[0].sendEmail             = !!profileItems[0].sendEmail
      profileItems[0].sendConditions        = !!profileItems[0].sendConditions
      profileItems[0].columnsPurchase       = Array.isArray(profileItems[0].columnsPurchase      ) ? profileItems[0].columnsPurchase       : [];
      profileItems[0].columnsQuotes         = Array.isArray(profileItems[0].columnsQuotes        ) ? profileItems[0].columnsQuotes         : [];
      profileItems[0].columnsQuoteItems     = Array.isArray(profileItems[0].columnsQuoteItems    ) ? profileItems[0].columnsQuoteItems     : [];
      profileItems[0].columnsQuoteProposals = Array.isArray(profileItems[0].columnsQuoteProposals) ? profileItems[0].columnsQuoteProposals : [];
    } else {
      profileItems = this.getProfileDefaultItems();
    }

    return profileItems[0];
  }

  //-- Realiza atualização do profile
  updateProfile(profile: PgcProfileItem): void {
    this.temporaryStorageService.setValue('profile', [profile])
    this.preferencesProfileService.updateProfilePreferences(profile).subscribe();
  }

  //-- Retoruna o objeto padrão do profile
  getProfileDefaultItems(): Array<PgcProfileItem> {
    const profileDefaultItems: Array<PgcProfileItem> = [{
      productGroup: false,
      sendEmail: false,
      sendConditions: false,
      columnsPurchase: [],
      columnsQuotes: [],
      columnsQuoteItems : [],
      columnsQuoteProposals: []
    }];

    return profileDefaultItems;
  }

  //-- Pega as colunas e converte em um objeto com as propriedades necessárias para o profile
  getTablePropertiesToProfile(columns: Array<PoTableColumn>): Array<Object> {
    const properties: Array<Object> = [];

    columns.forEach((column: PoTableColumn, index: number) => {
      properties.push({property: column.property, visible: column.visible, fixed: column.fixed, order: index});
    });

    return properties;
  }

  //-- Atualiza a ordem e colunas visíveis em todas as tabelas
  tablesColumnsChange(tables: QueryList<PoTableComponent>, columns: Array<PoTableColumn>): void {
    if (tables && tables.length > 0) {
      tables.forEach((table: PoTableComponent) => { 
        table.onVisibleColumnsChange(columns);
        table.newOrderColumns = columns;
      });
    }
  }
}
