import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Utils } from '../helper/utils';
import { LoginService } from '../services/login.service';

@Injectable()

export class HttpInterceptorReq implements HttpInterceptor {
  constructor(private utils: Utils, private loginService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.loginService.isProtheusLogged()) {
      const headers = this.utils.getHeaders(false, true, req.url);

      req = req.clone({ setHeaders: headers });
    }

    return next.handle(req);

  }

}
