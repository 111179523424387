// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-home{
    background-color: #E5E5E5;
}

img{
    /* float: left; */
    /* justify-content: center; */
    margin-top: 7%;
    width: 400px;
    height: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.box{
  height: 80px;
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items: flex-end;   
}
`, "",{"version":3,"sources":["webpack://./src/app/core/home/home.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;IAC7B,cAAc;IACd,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB;AACJ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB","sourcesContent":[".container-home{\n    background-color: #E5E5E5;\n}\n\nimg{\n    /* float: left; */\n    /* justify-content: center; */\n    margin-top: 7%;\n    width: 400px;\n    height: 400px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto\n}\n\n.box{\n  height: 80px;\n}\n\n.wrapper{\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;   \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
